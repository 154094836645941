.react-modal-backdrop {
    position: fixed;
    backface-visibility: hidden;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000000;
    @apply bg-primary-900;
    opacity: 0.6;
}

.react-modal {
    position: fixed;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000001;
    height: 100%;
    width: 100%;
    font-family: $lato;
    overflow: auto; // make scrollable

    &-content {
        position: absolute;
        z-index: 10001;
        top: 60px;
        margin-bottom: 60px;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        @apply p-8
               rounded
               min-w-[480px]
               md:min-w-[580px]
               w-fit 
               bg-white;
    }

    &-header {
        position: relative;
        width: 100%;

        &-title {
            font-size: 12pt;
            font-weight: bold;
        }

        &-title-one {
            font-size: 12pt;
            width: 77%;
            font-weight: bold;

            &-create {
                float: left;
                overflow: hidden;
                @apply text-green-400;
                text-decoration: underline;
            }
        }

        &-close {
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 999;
            &:hover {
                color: $color-error;
            }
        }
    }

    &-body {
        margin-top: $default-margin;
    }

    &-buttons {
        margin-top: $default-margin * 0.5;
        padding-top: $default-padding * 0.5;
        button {
            margin: 0 $default-margin * 0.5;
        }
    }

    .react-modal-back {
        position: absolute;
        left: $default-padding;
    }
}

.react-modal .large {
    min-width: 480px;
    max-width: 80%;
    position: absolute;
    z-index: 10001;
    top: 40%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    @apply p-2;
    overflow-x: auto;
}

.react-modal .medium {
    min-width: 350px;
    max-width: 0%;
    position: absolute;
    z-index: 10001;
    top: 40%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    @apply bg-white p-2 rounded;
    overflow-x: auto;
}

.react-modal .xl {
    top: $default-padding * 3;
    width: 800px;
    left: 50%;
    bottom: $default-padding * 5;
    transform: none;
    margin-left: -400px;
}

.react-modal .full-screen {
    top: $default-padding * 3;
    left: $default-padding * 3;
    right: $default-padding * 3;
    bottom: $default-padding * 3;
    transform: none;
    max-width: auto;
    width: auto;
    margin: auto;
    min-width: auto;

    .react-modal-body {
        position: absolute;
        top: 30px;
        overflow: auto;
        padding: 0;
        margin: 0;
        bottom: 60px;
        left: 0;
        width: 100%;
        right: 0;
    }

    .react-modal-buttons {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        @apply p-2;
    }
}

// Modal V2
.react-modal-v2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000001;
    height: 100%;
    width: 100%;
    @include OpenSans-Regular();
    overflow: auto; // make scrollable

    // Fix subpixel positioning
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;

    .close {
        cursor: pointer;
        position: absolute;
        top: $default-margin;
        right: $default-margin * 1.5;
    }

    // w-fit added for forwards compatibility
    &-container {
        @apply p-8
            rounded
            block
            relative
            w-auto
            w-fit
            mx-auto
            my-6
          bg-white;
    }

    &-content {
        // Center vertically
        @apply p-2
                rounded
                pb-8
                inline-block
                relative
                w-auto
                mx-auto
                my-4
              bg-white;
    }

    .footer-content {
        .checkbox {
            margin-top: $default-margin * 0.5 !important;
        }
    }
}
