.react-modal-content.casefile-template-selector {
    * {
        -webkit-font-smoothing: antialiased;
        @extend .prevent-selection;
    }
    width: 700px;

    .react-modal-header-close {
        cursor: pointer;
        top: math.div($default-margin, 1.5);
        right: math.div($default-margin, 1.5);
    }
}

.template-selector-modal {
    padding: $default-padding;
    width: 100%;
    text-align: left;

    .search-input {
        margin: $default-margin 0;
    }
}

.template-modal-title {
    font-size: 1.3em;
    margin: 0;
    .fa {
        padding-right: $default-margin * 0.5;
    }
}

.template-list {
    max-height: 600px;
    min-height: 500px;
    overflow-y: auto;
    padding: $default-padding * 1;
    background: lighten($gray-light, 2);
    border: $default-border;
    border-radius: $default-radius;
    transform: translateZ(0);

    .template-row {
        &.deleting {
            opacity: 0.4;
            pointer-events: none;
        }

        position: relative;
        @include transition(0.2s);
        background: $color-white;
        cursor: pointer;
        text-align: left;
        width: 100%;
        padding: $default-padding;
        margin-bottom: $default-margin;
        border-radius: $default-radius;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        -webkit-backface-visibility: hidden;

        h2 {
            margin-bottom: $default-margin * 0.5;
            position: relative;
            font-weight: normal;
            font-weight: bold;

            &:before {
                content: '';
                height: 5px;
                width: 5px;
                display: block;
                left: -9px;
                border-radius: 50%;
                top: 6px;
                position: absolute;
                background: $color-info;
            }
        }

        &:hover {
            transform: translateY(-2px);
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
        }

        .title {
            font-weight: bold;
            display: block;
        }

        .counters {
            margin: 0;
            margin-top: $default-margin * 0.5;
            li {
                display: inline-block;
                font-weight: bold;
                margin-right: $default-margin * 0.5;
                padding-right: $default-margin * 0.5;
                border-right: 1px solid $text-light;
                &:last-of-type {
                    border-right: none;
                }
            }
        }

        .template-footer {
            position: relative;
        }

        .details-link {
            position: absolute;
            right: 0;
            bottom: 0;
        }

        .right-block {
            position: absolute;
            top: $default-margin;
            right: $default-margin;
        }

        .details-link {
            margin-right: $default-margin * 0.5;
            border-bottom: 1px dashed $gray-dark;
            color: $gray-dark;
        }

        .dashed-line {
            position: relative;
            margin: $default-margin 0;
            height: 1px;
            background-image: linear-gradient(
                to right,
                rgba(0, 0, 0, 0.25) 70%,
                rgba(255, 255, 255, 0) 0%
            );
            background-position: top;
            background-size: 15px 1px;
            background-repeat: repeat-x;

            &:before {
                position: absolute;
                top: -9px;
                left: -$default-padding;
                margin-left: -9px;
                content: '';
                background: lighten($gray-light, 2);
                display: block;
                height: 18px;
                width: 18px;
                border-radius: 50%;
                box-shadow: inset 0px 2px 1px -1px rgba(0, 0, 0, 0.3);
                transform: rotate(90deg);
            }

            &:after {
                position: absolute;
                top: -9px;
                right: -$default-padding;
                margin-right: -9px;
                content: '';
                background: lighten($gray-light, 2);
                display: block;
                height: 18px;
                width: 18px;
                border-radius: 50%;
                box-shadow: inset 0px 2px 1px -1px rgba(0, 0, 0, 0.3);
                transform: rotate(-90deg);
            }
        }

        .column {
            display: inline-block;
            vertical-align: top;
            h2 {
                font-size: 1em;
            }

            ul,
            p {
                margin-top: 0;
            }
        }

        .detail-column {
            width: 65%;
            padding-left: 0.5em;

            .name {
                font-weight: bold;
                margin-right: $default-margin * 0.5;
            }

            ul .fa {
                padding: 0 $default-padding * 0.125;
            }
        }

        .custom-message {
            padding-left: 0.5em;
            h2 {
                font-size: 1em;
            }

            p {
                margin-top: 0;
                font-size: 0.92em;
            }
        }

        .settings-column {
            width: 35%;
        }

        .signer-list,
        .documents-list {
            > li {
                margin-bottom: $default-margin;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .signer-details {
            text-align: left;
            > span {
                display: block;
                margin: $default-margin * 0.5;
            }
        }

        .settings-list {
            > div {
                margin-bottom: $default-margin * 0.25;

                .fa {
                    width: 12px;
                    text-align: center;
                }

                .fa-group {
                    font-size: 0.9em;
                }
            }
        }

        .tags {
            > li {
                font-size: 0.9em;
                margin-top: $default-margin * 0.25;
                margin-right: math.div($default-margin, 3);
                display: inline-block;
                border-radius: $default-radius;
                background: $gray-light;
                padding: math.div($default-padding, 6) $default-padding * 0.5;
            }
        }

        .signer-icons {
            display: inline-block;
            border-left: 1px solid $gray-border;
            padding-left: $default-padding * 0.5;

            li {
                margin-right: $default-margin * 0.25;
                border-radius: $default-radius;
                display: inline-block;
            }
        }

        .template-details-footer {
            position: relative;
            margin-top: $default-margin;
            text-align: right;
        }
    }
}
