.member-list {
    position: relative;

    .member-list-area {
        border-radius: $default-radius;
        border: $default-border;
        height: 320px;
        overflow: scroll;
    }

    .member-list-area-empty {
        text-align: center;
        position: relative;
        padding-top: $default-padding * 7;

        .member-list-area-empty-graphic {
            display: inline-block;
            color: $cf-blue;
            position: relative;

            i:first-child {
                font-size: 28pt;
            }

            i:last-child {
                position: absolute;
                top: 0%;
                left: 115%;
                font-size: 14pt;
            }

            h2 {
                @include OpenSans-Regular();
                font-size: 14pt;
                color: #9b9b9b;
                text-align: center;
                font-weight: normal;
            }
        }
    }
}

.member-list-actions {
    .search-bar {
        text-align: left;
    }

    .add-members {
        // text-align: right;
    }

    // text-align: right;
    padding-bottom: $default-padding;
}
