.tabs {
    padding-top: 0;

    .tabs-list {
        text-align: center;
        margin: 0;
    }

    .tab-content {
        padding: $default-padding;
        display: block;
    }
}

.tab-list-item {
    display: inline-block;
    list-style: none;
    background-color: $gray-lighter;
    border-right: $default-border;
    border-top: $default-border;
    border-bottom: $default-border;
    padding: math.div($default-padding, 1.5);
    color: $text-dark;

    p {
        @include OpenSans-SemiBold();
        margin: 0;
    }

    &-label {
        display: inline-block;
    }

    &-icon {
        display: inline-block;
    }

    &:last-child {
        border-right: none;
    }

    &:hover {
        cursor: pointer;
    }
}

.tab-list-active {
    background-color: $cf-blue;
    border-color: $cf-blue;
    color: $color-white;
}
