.action-bar {
    position: relative;
    text-align: center;
    width: 100%;
    text-align: left;
    background: $gray-light;
    padding: $default-padding * 1.2;
    box-sizing: border-box;
    text-align: center;

    .title {
        color: $text-medium;
        font-weight: normal;
    }

    .action {
        display: none;
        position: absolute;
        right: $default-padding;
        color: $text-medium;
    }
}
