.color-picker {
    margin-bottom: $default-margin;
    /* chrome-picker width */
    max-width: 225px;

    .color-picker-cover {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .color-picker-button {
        cursor: pointer;
        margin-bottom: 0;
        width: 100%;
    }

    .color-picker-preview {
        cursor: pointer;
        position: absolute;
        height: $default-padding * 2;
        width: $default-padding * 2;
        bottom: math.div($default-padding, 3);
        right: $default-padding * 0.5;
        border: $default-border;
        border-radius: 50%;
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
    }
}

.color-picker-container {
    position: absolute;
    z-index: 2;

    /* Hides advanced controllers in Chrome Color Picker from react-color */
    .chrome-picker {
        div.flexbox-fix:last-child {
            width: 180px;
            /* Disable HEX/HSL switcher */
            > div:last-of-type {
                display: none;
            }

            /* Disable HEX label */
            input + span {
                display: none !important;
            }
        }
    }

    .color-picker-footer {
        position: absolute;
        width: 225px;
        background: $color-white;
        text-align: center;
        padding-bottom: math.div($default-padding, 1.5);

        /* Display same shadow as color-picker */
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.3), 0 4px 8px rgba(0, 0, 0, 0.3);

        /* Hide top-edge of shadow */
        &:before {
            content: '';
            display: block;
            position: absolute;
            background: $color-white;
            height: 4px;
            width: 100%;
            top: -2px;
            z-index: 2;
        }

        > span {
            display: inline-block;
            width: 50%;
        }
    }
}
