@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.submit-kyc-page-container {
    padding: 2em 1em;
}

.submit-kyc-page-content {
    width: 100% !important;
    max-width: 680px;
}

.submit-kyc-page-title {
    color: $text-dark;
}

.files-upload-field {
    margin-bottom: $default-margin * 0.5;

    &:last-child {
        margin-bottom: 0;
    }
}
