.manage-credentials-page {
    position: relative;

    .manage-credentials-container {
        position: relative;
        width: 480px;
        min-height: 500px;
        margin: 0 auto;
        padding: $default-padding * 2 0;
        box-sizing: border-box;
        background: $color-white;
        margin-top: $default-margin * 2;
        border-radius: $default-radius * 2;
        box-shadow: $card-shadow;
    }
}
