@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.signing-header {
    padding: math.div($default-padding, 2);
    height: 50px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2;
    background-color: rgb(21, 48, 71); //this is not the penneo-blue color

    &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        max-width: 1170px;
        margin: 0 auto;
        @media only screen and (min-width: 980px) {
            padding: 0 $default-padding * 2;
        }
    }

    &-logo,
    &-logo-link {
        height: 100%;
    }

    &-links {
        display: flex;
        align-items: center;

        a {
            display: inline-block;
            text-align: center;
            margin-left: $default-padding * 2;
            color: $color-white;
            transition: opacity 500ms;

            &:hover {
                opacity: 0.8;
                text-decoration: none;
            }
        }

        span {
            display: none;
            @media only screen and (min-width: 980px) {
                font-size: $small-font-size;
                display: inline;
            }
        }

        i {
            display: block;
            font-size: 120%;
        }

        .language-dropdown {
            display: inline-block;
            font-size: $small-font-size;
            margin-left: $default-padding * 2;
            text-align: center;
            width: 100px;
            background-color: transparent;
            color: white;
            border: 1px solid white;
            border-radius: 0.25rem;

            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8"><path d="M1,1 L6,6 L11,1" fill="none" stroke="white" stroke-width="1"/></svg>');
            background-repeat: no-repeat;
            background-position: right 0.5rem center;

            > option {
                color: initial;
            }
        }

        .language-dropdown.dark {
            background-color: transparent;
            color: black;
            border: 1px solid black;
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8"><path d="M1,1 L6,6 L11,1" fill="none" stroke="black" stroke-width="1"/></svg>');

            > option {
                color: initial;
            }
        }
    }
}

.signing-header-leadgen {
    background-color: $color-menu-bg;
}
