@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.box {
    color: $gray-darker;
    padding: $default-padding;

    * {
        -webkit-font-smoothing: antialiased;
    }

    position: relative;
    border-radius: $default-radius * 2.75;
    background: $color-white;
    box-shadow: $card-shadow;
    height: 100%;
}
