.number-input {
    @extend .input;
    margin-bottom: 0 !important;
    padding: 0.3em 1.5em !important;
}

input.number-input {
    background: $color-white !important;
    @include OpenSans-SemiBold();
}

.react-numeric-input {
    margin: 0 $default-margin * 0.25;
    > b {
        cursor: pointer !important;
        top: 3px !important;
        bottom: 3px !important;

        &:first-of-type {
            right: 3px !important;
            background: $color-white !important;
            border-color: transparent !important;
            i {
                background: $cf-blue !important;
            }

            &:hover {
                background: $cf-blue !important;
                i {
                    background: $color-white !important;
                }
            }
        }

        &:last-of-type {
            left: 3px !important;
            background: $color-white !important;
            border-color: transparent !important;
            i {
                background: $cf-blue !important;
            }

            &:hover {
                background: $cf-blue !important;
                i {
                    background: $color-white !important;
                }
            }
        }
    }
}
