@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.user-email-templates {
    .actions {
        align-items: center;
        border-bottom: 1px solid $gray-lighter;
        display: flex;
        padding: $default-padding;

        .actions-buttons {
            flex-shrink: 0;
        }

        .actions-link {
            margin-left: auto;
        }
    }

    .loading {
        padding: $default-padding * 1.5;
    }

    .merge-docs {
        max-height: 0;
        background-color: $gray-lighter;
        transition: max-height 0.5s ease;
        overflow: hidden;
        padding: 0 $default-padding;
        border-radius: $default-radius;

        &.active {
            max-height: 500px;
            margin-bottom: $default-margin;
        }
    }

    .panels-wrapper {
        display: flex;

        .panel-left {
            flex: 0 0 300px;
            margin-right: -1px;
        }

        .panel-right {
            flex: 1 1 auto;
        }
    }

    .panel-left {
        background-color: $gray-lightest;

        li {
            border-style: solid;
            border-color: $gray-lighter;
            border-width: 0 1px 1px 0;
            color: $text-medium;
            position: relative;

            &:hover {
                background-color: transparentize(
                    $color: $cf-blue,
                    $amount: 0.95
                );
            }

            &.active {
                background-color: $color-white;
                border-right-width: 0;
                color: $text-dark;
                font-weight: bold;

                .email-title {
                    border-left: 3px solid $cf-blue;
                }
            }

            .email-title {
                cursor: pointer;
                padding: $default-padding * 1.4 $default-padding;

                .new-temp {
                    color: $color-error;
                    font-size: 1.2em;
                }
            }
        }
    }

    .panel-left-list {
        margin: 0;
        overflow: hidden;
        overflow-y: auto;
        max-height: 550px;
    }

    .panel-right {
        border-left: 1px solid $gray-lighter;
        padding: $default-padding;
        background-color: $color-white;
        overflow-y: auto;

        .half-content-wrapper {
            display: flex;
            margin-bottom: $default-margin;
        }

        .half-content {
            &.title {
                flex-basis: 30%;
            }

            &.subject {
                flex-basis: 70%;
            }

            &:first-child {
                margin-right: $default-padding;
            }
        }

        .form-content {
            textarea {
                resize: none;
                width: 100%;
                height: 250px;
            }

            button {
                margin-top: $default-margin;
            }
        }
    }
}
