/* Selectable Lists */
%list-style {
    ul {
        background: $color-white;
        padding: 0 $default-padding;
        li {
            padding: $default-padding;
            border-bottom: 1px solid $gray-medium;
            &:last-child {
                border-bottom: none;
            }
        }
    }
}

%state-hover {
    &:hover {
        color: $gray-medium;
    }
}

%state-active {
    &.active {
        background: $color-blue-highlight;
        color: $color-white;
    }
}

/// Text Color Variations based on state.
.text {
    &-success {
        color: $color-success !important;
    }
    &-warning {
        color: $color-warning !important;
    }
    &-error {
        color: $color-error !important;
    }
}

.hover {
    @include transition(0.2s);
    &-success:hover {
        color: $color-success !important;
    }
    &-warning:hover {
        color: $color-warning !important;
    }
    &-error:hover {
        color: $color-error !important;
    }
}

.fadeIn-appear {
    opacity: 0.01;
}

.fadeIn-appear.fadeIn-appear-active {
    opacity: 1;
    transition: all 0.5s ease;
}

.fadeIn-leave {
    opacity: 1;
}

.fadeIn-leave.fadeIn-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

// CONTENT

.fadeModalContent-appear,
.fadeModalContent-enter {
    opacity: 0.01;
    transition: all 100ms cubic-bezier(0.6, 0, 0.4, 1);
    transform: scale3d(0.8, 0.8, 1);
}

.fadeModalContent-appear.fadeModalContent-appear-active,
.fadeModalContent-enter.fadeModalContent-enter-active {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    transition: all 100ms cubic-bezier(0.6, 0, 0.4, 1);
}

.fadeModalContent-leave {
    opacity: 1;
    transition: all 100ms cubic-bezier(0.6, 0, 0.4, 1);
    transform: scale3d(1, 1, 1);
}

.fadeModalContent-leave.fadeModalContent-leave-active {
    opacity: 0.01;
    transform: scale3d(0.9, 0.9, 1);
    transition: all 100ms ease-out;
}

// BACKDROP

.fadeModalBackdrop-appear,
.fadeModalBackdrop-enter {
    opacity: 0.01;
}

.fadeModalBackdrop-appear.fadeModalBackdrop-appear-active,
.fadeModalBackdrop-enter.fadeModalBackdrop-enter-active {
    opacity: 0.6;
    transition: opacity 100ms ease-out;
}

.fadeModalBackdrop-leave {
    opacity: 0.6;
}

.fadeModalBackdrop-leave.fadeModalBackdrop-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-out;
}
