@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.profile-select-page-container {
    margin: 0 auto;
    @apply pt-[128px]
            pb-2
            px-0;
    max-width: 576px;
    width: 90vw;
}

.profile-select-penneo-logo {
    text-align: center;
    @apply mb-9;
}

.profile-select-label {
    @include OpenSans-Bold();
    margin-top: 2rem !important;
    text-align: center;
    @apply text-neutral-600;
}

.profile-select-links-container {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0.5rem;
}

.profile-select-link {
    @include OpenSans-SemiBold();
    text-decoration: underline;

    @apply ml-4 text-secondary-400;

    &:hover {
        @apply text-secondary-600;
    }

    &.active {
        @apply text-secondary-600 border-b border-secondary-600;
    }
}

.profile-selector-linked-list {
    @apply my-4
            rounded-sm
            overflow-hidden
            block;
}
