@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.user-integrations-container {
    min-height: 250px;
    max-height: 600px;
    overflow: auto;

    border: $border-light;
    padding: $default-padding;

    .user-integration {
        display: inline-block;
        width: 100%;

        &-logo {
            display: inline-block;
            max-width: 5%;
            text-align: center;

            img {
                vertical-align: middle;
                max-width: 100%;
            }
        }

        &-details {
            display: inline-block;
            vertical-align: middle;
            padding-left: $default-padding;
            width: 45%;

            .name {
                margin-top: 0;
                margin-bottom: -$default-margin * 0.5;
                font-weight: bold;
                padding-bottom: $default-padding * 0.5;
            }

            .integration-status {
                &-active {
                    i {
                        color: $color-success;
                    }
                }

                &-inactive {
                    i {
                        color: $color-error;
                    }
                }

                &-unknown {
                    i {
                        color: $gray-darker;
                    }
                }
            }
        }

        &-actions {
            display: inline-block;
            vertical-align: middle;
            text-align: right;
            width: 50%;

            .hostname-switcher {
                display: inline-block;
                margin-right: $default-margin;
                width: 65%;

                input {
                    margin-right: $default-margin * 0.5;
                    width: 145px;
                }
            }

            .confirmation-question {
                text-align: right;
            }

            button {
                margin-right: $default-margin * 0.5;
            }
        }
    }
}
