/**********************************************************

	- global.scss (partial)

	Styles that override the global styling of
	elements from the Browser's default Style or from
	normalize.css.

**********************************************************/

ul {
    list-style: none;
    padding: 0;
}

a {
    text-decoration: none;
    cursor: pointer;

    &[target='_blank'] {
        @apply underline;
    }
}

body {
    &.no-scroll {
        overflow: hidden;
    }
}

%main-scrollable {
    position: absolute;
    overflow: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

/// Width to 100% of the parent's width
/// Parent needs to have a set position
%match-parent,
.match-parent {
    position: relative;
    width: 100%;
}

.bold {
    font-weight: 700;
}

.preserve-newlines {
    white-space: pre-line;
}

.loader {
    position: relative;
    display: block;
    height: 100%;
    margin-top: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    background-color: #55ca92;
    border-radius: 50%;

    &:after {
        content: '';
        position: absolute;
        display: block;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        height: 0%;
        width: 0%;
        border: 0px solid $color-white;
        transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        animation: loading 700ms ease-out forwards infinite;
        -moz-animation: loading-m 700ms ease-out forwards infinite;
    }
}

@keyframes loading {
    0% {
        border: 0px solid $color-white;
    }

    20% {
        border: 4px solid $color-white;
        width: 0%;
        height: 0%;
    }

    100% {
        border: 8px solid $color-white;
        width: 130%;
        height: 130%;
    }
}

@-moz-keyframes loading-m {
    0% {
        border: 0px solid $color-white;
    }

    20% {
        border: 8px solid $color-white;
        width: 0%;
        height: 0%;
    }

    100% {
        border: 8px solid $color-white;
        width: 100%;
        height: 100%;
    }
}

._horizontal-list {
    .item {
        display: inline-block;
        vertical-align: top;
        margin-right: $default-margin;
        &:last-child {
            margin-right: 0;
        }
    }
}

.text {
    &-center {
        text-align: center;
    }
    &-left {
        text-align: left;
    }

    &-right {
        text-align: right;
    }

    &-blue {
        color: $cf-blue;
    }

    &-green {
        color: $penneo-green;
    }

    &-red {
        color: $color-error;
    }

    &-orange {
        color: $color-warning;
    }

    &-yellow {
        color: $penneo-orange;
    }
}

.center-block {
    margin: $default-margin auto;
}

.text-capitalize {
    text-transform: capitalize;
}

.batch-overview-list {
    @include white-container();
    @include transition(0.3s);

    overflow: hidden;
    ul {
        @include transition(0.3s);
        position: relative;
        padding-top: 0;
        margin-top: 0;

        h4 {
            background: $gray-light;
            padding: $default-padding * 0.5;
            border-radius: $default-radius;
            &:first-child {
                margin-top: 0;
            }
        }

        li {
            position: relative;
            border: $default-border;
            margin: $default-margin * 0.5 0;
            padding: $default-padding;
            border-radius: $default-radius;
            cursor: pointer;
            @include transition(0.3s);
            .selection-indicator {
                @include transition(0.3s);
                position: absolute;
                height: 100%;
                left: 0;
                top: 0;
                width: 6px;
                background: $color-blue-highlight;
                opacity: 0;
                border-radius: $default-radius 0 0 $default-radius;
            }
            &:hover {
                border-left-color: transparent;
                .downloads .link {
                    color: $color-blue-highlight;
                    text-decoration: underline;
                }
            }
            &:hover .selection-indicator {
                opacity: 1;
            }
        }
    }

    .downloads {
        position: absolute;
        top: 50%;
        margin-top: -8px;
        right: 10px;

        .link {
            color: $gray-dark;
            font-weight: bold;
            &:hover {
                color: $color-blue-highlight;
                text-decoration: underline;
            }
        }
    }
}

.workflow-list {
    @include white-container();
    @include transition(0.3s);
    position: relative;
    overflow: hidden;
}

.workflow-selector {
    background: $gray-light;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 260px;
    height: 100%;
    overflow-y: scroll;

    ul {
        margin: 0;
    }

    li {
        @include transition(0.2s);
        position: relative;
        cursor: pointer;
        border-top: $default-border;
        padding: $default-padding;
        p,
        h4 {
            margin: 0;
        }
        &:last-child {
            border-bottom: $default-border;
        }
        &.selected {
            background: $color-white;
            &:hover {
                background: $color-white;
            }
        }
        &:hover {
            background: $color-white;
            opacity: 0.9;
        }
    }

    .selected-indicator {
        @include transition(0.3s);
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        width: 4px;
        background: $color-blue-highlight;
    }
    .selector-title {
        // background: $gray-border;
        padding: $default-padding;
        font-weight: bold;
    }
}
.workflow-sidebar-layout {
    margin-top: $default-margin;
    min-height: 650px;
    position: relative;
    border: $default-border;

    border-radius: $default-radius;
}

.tab-container {
    position: relative;
    height: 100%;
}

.workflow-details {
    box-sizing: border-box;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding-left: 260px;
    .details-content {
        position: relative;
        height: 100%;
    }
    padding-right: 250px;
}

.comment-log-sidebar {
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    width: 250px;
    border-left: $default-border;
    .tabs {
        li {
            cursor: pointer;
        }
    }
}

.comment-log-sidebar-content {
    position: absolute;
    width: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    top: 60px;
    bottom: 0px;
}

.comment-log-sidebar-header {
    border-bottom: $default-border;
    ul {
        margin: 0;
        padding: 0;
    }

    li {
        margin: 0;
        display: inline-block;
        box-sizing: border-box;
        text-align: center;
        width: 50%;
        span {
            opacity: 0.6;
        }
        div {
            position: relative;
            font-weight: bold;
            padding: $default-padding $default-padding * 0.5;
        }
        &:last-child {
            div {
                border-left: $default-border;
            }
        }
        &.selected {
            span {
                opacity: 1;
            }
            div:after {
                display: block;
                content: '';
                position: absolute;
                z-index: 2;
                bottom: -1px;
                left: -1px;
                right: -1px;
                // width: 100%;
                height: 4px;
                background: $color-blue-highlight;
            }
        }
    }
}

.details-header {
    border-bottom: $default-border;
    ul {
        margin: 0;
        padding: 0;
    }

    li {
        cursor: pointer;
        position: relative;
        margin: 0;
        font-weight: bold;
        display: inline-block;
        box-sizing: border-box;
        padding: $default-margin;
        opacity: 0.6;
        &.selected {
            opacity: 1;
            &:after {
                display: block;
                content: '';
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100%;
                height: 4px;
                background: $color-blue-highlight;
            }
        }
    }
}

.workflow-details-content {
    padding: $default-padding * 1.5;
    position: absolute;
    bottom: 0;
    top: $default-margin * 3;
    left: 0;
    right: 0;
    overflow-y: scroll;
    .details-content-title {
        margin-top: 0;
    }
}

.horizontal-list {
    padding: 0;
    margin: 0;
    h4 {
        margin: 0;
    }
    li {
        padding-right: $default-padding * 2;
        display: inline-block;
        vertical-align: top;
    }
}

.event-log {
    position: relative;
    height: 100%;
    padding: $default-padding;
    box-sizing: border-box;
}

.no-events-message {
    text-align: center;
}

.timeline {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    padding-top: $default-padding;
    .point {
        position: relative;
        width: 100%;
        margin: $default-margin 0;
        padding-left: 10%;
        box-sizing: border-box;
        margin-bottom: $default-margin * 2;
        &:first-child {
            margin-top: 0;
        }
        &.success {
            &:before {
                background: $color-highlight;
            }
        }

        &.info {
            &:before {
                background: $color-info;
            }
        }

        &.warning {
            &:before {
                background: $color-warning;
            }
        }

        &.error,
        &.failure {
            &:before {
                background: $color-error;
            }
        }

        &:before {
            content: '';
            width: 18px;
            height: 18px;
            display: block;
            position: absolute;
            border-radius: 50%;
            left: 0;
            top: 10px;
            z-index: 2;
            left: -2px;
            border: 3px solid $color-white;
        }
        &:last-child {
            &:after {
                content: '';
                background: $gray-medium;
                width: 4px;
                height: 1000000%;
                position: absolute;
                z-index: 1;
                left: 5px;
                bottom: 20px;
                display: block;
            }
        }
    }
}

.point-content {
    .entry-date {
        font-size: 0.9em;
    }
    h4 {
        margin: math.div($default-margin, 3) 0;
    }
    p {
        margin: 0;
        opacity: 0.9;
        font-size: 1em;
    }
    // border: $default-border;
}

.text-bold {
    font-weight: bold;
}

.text-semibold {
    @include OpenSans-SemiBold();
}

// @todo: temporary fixed width for attachment column in WorkflowList.jsx
.attachment-column {
    width: 200px;
}

.prevent-selection {
    // Prevent Text Selection on Toolbar
    // @see: http://stackoverflow.com/questions/880512/prevent-text-selection-after-double-click
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
}

.text {
    &-success {
        color: $color-success;
    }
    &-info {
        color: $color-info;
    }
    &-warning {
        color: $color-warning;
    }
    &-error {
        color: $color-error;
    }
    &-dark {
        color: $text-dark;
    }
    &-medium {
        color: $text-medium;
    }
    &-light {
        color: $text-light;
    }
    &-penneo-blue {
        color: $penneo-blue;
    }
    &-penneo-dark-blue {
        color: $penneo-dark-blue;
    }
    &-penneo-yellow {
        color: $penneo-yellow;
    }
    &-penneo-cyan {
        color: $penneo-cyan;
    }
    &-penneo-green {
        color: $penneo-green;
    }
}

.info-message {
    position: relative;
    padding: $default-padding * 1.5;
    text-align: center;
    margin: $default-margin * 0.5 0;
    border-radius: $default-radius;
    background: rgba($gray-medium, 0.3);
    border: 1px solid $gray-dark;
    color: mix($gray-dark, #000, 80%);
    margin-bottom: $default-margin * 2;

    p {
        margin: 0;
        padding: 0;
    }

    .dismiss {
        cursor: pointer;
        position: absolute;
        opacity: 0.8;
        top: $default-padding * 0.25;
        right: $default-padding * 0.25;
    }

    &.success {
        background: rgba($color-success, 0.3);
        border: 1px solid $color-success;
        color: mix($color-success, #000, 80%);
    }
    &.info {
        background: rgba($color-info, 0.3);
        border: 1px solid $color-info;
        color: mix($color-info, #000, 80%);
    }
    &.warning {
        background: rgba($color-warning, 0.3);
        border: 1px solid $color-warning;
        color: mix($color-warning, #000, 80%);
    }
    &.error {
        background: rgba($color-error, 0.3);
        border: 1px solid $color-error;
        color: mix($color-error, #000, 80%);
    }

    &.compact {
        margin-bottom: $default-margin;
        padding: math.div($default-padding, 1.5);

        h3 {
            font-size: 95%;
        }
    }
}

$form-input-max-width: 480px;
.form {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    text-align: left;

    .highlight-form-group {
        border-radius: $default-radius;
        padding: 1em;
        width: 100%;
        max-width: 480px;
        @include card();
        padding: $default-padding;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: $cf-blue 4px solid;
    }

    .box-gray {
        max-width: $form-input-max-width;
    }

    .input-disabled {
        opacity: 0.5;
        max-width: inherit;
        cursor: not-allowed;
        > * {
            pointer-events: none;
        }
    }

    > h3:first-child {
        margin-top: 0;
    }

    .p {
        margin-top: 0;
        line-height: 1.6em;
    }

    &.small {
        max-width: $form-input-max-width;
    }

    &.match-parent {
        width: 100%;
    }

    p:first-child {
        margin-top: 0;
    }

    label {
        color: $text-medium;
        display: block;
        margin-bottom: $default-margin * 0.25;
        &:first-child {
            margin-top: 0;
        }
    }

    .checkbox {
        @extend .prevent-selection;
        margin-bottom: 0;
        font-weight: normal;
        color: $text-dark;
        input[type='checkbox'] {
            position: relative;
            margin-left: 0;
            display: inline-block;
        }
        span {
            margin-left: $default-margin * 0.5;
            display: inline-block;
        }
    }

    .checkbox-group {
        @extend .prevent-selection;
        margin-top: -$default-margin * 0.5;
        .checkbox,
        .custom-checkbox {
            margin-top: $default-margin * 0.5 !important;
            background: $gray-light;
            display: inline-block;
            margin-right: $default-margin * 0.5;
            padding: math.div($default-padding, 1.5);
            border: $default-border;
            border-radius: $default-radius;
            color: $text-dark !important;
            opacity: 0.9;
            font-size: 0.95em;

            &:not(#exclude-old-browsers) > .check {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                opacity: 1;
                border-color: $color-info;
            }
        }
    }

    .checkbox-list {
        .checkbox,
        label.custom-checkbox:not(#exclude-old-browsers) {
            display: block;
        }

        &.columns-2 {
            max-width: $form-input-max-width;
            .checkbox,
            label.custom-checkbox:not(#exclude-old-browsers) {
                width: 50%;
                display: inline-block;
            }
        }
    }

    input[type='text'],
    input[type='password'],
    input[type='email'],
    textarea,
    select,
    .input {
        width: 100%;
        max-width: $form-input-max-width;
        box-sizing: border-box;
        display: block;
        margin-bottom: $default-margin;
    }

    textarea {
        display: block;
    }

    .validation,
    .input-validation {
        input[type='text'],
        input[type='password'],
        input[type='email'],
        textarea,
        select,
        .input {
            margin-bottom: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .input-validation {
        margin-bottom: $default-margin;

        > .input-validation-message {
            max-width: $form-input-max-width;
        }
    }
}

.empty-table-message {
    color: #ccc;
    text-align: center;

    &:hover {
        opacity: 1;
    }

    a {
        text-decoration: underline;
        color: $color-success;
        opacity: 0.7;
        &:hover {
            opacity: 1;
            color: $color-success;
        }
    }
    .empty-table-image {
        opacity: 0.4;
    }
}

.table-minimal {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    font-family: $lato;
    border-collapse: initial;
    border-top: 1px solid $gray-medium;
    border-bottom: 1px solid $gray-medium;
    border-radius: $default-radius;
    thead {
        color: $gray-darker;
        th {
            border-bottom: 1px solid $gray-medium;
            padding: $default-padding * 1.5 0;
            padding-left: $default-padding;
            text-transform: uppercase;
            text-align: left;
            cursor: pointer;
            &:last-child {
                padding-right: $default-padding;
            }
        }
    }

    tbody {
        color: $gray-dark;
        td {
            border-bottom: 1px solid $gray-medium;
            padding: $default-padding;
        }
        tr:last-child {
            td {
                border-bottom: none;
            }
        }
    }
}

.error-message {
    color: $color-white;
    margin: 0;
    position: relative;
    height: 100%;
    overflow: hidden;
    .message-content {
        background: $color-error;
        padding: math.div($default-padding, 1.5);
        & > * {
            margin: 0;
            font-weight: normal;
            text-align: center;
        }
    }
}

.spinner {
    margin: 0 auto;
    padding: 100px 0;
    width: 80px;
    text-align: center;
    &.inline {
        padding: $default-padding 0;
    }
}

.spinner > div {
    width: 15px;
    height: 15px;
    margin-right: 6px;
    background-color: $gray-dark;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.visible-small {
    visibility: collapse;
    @media #{$breakpoint-small} {
        visibility: visible;
    }
}

.badge {
    display: inline-block;
    font-size: $small-font-size;
    margin: 0 $default-margin;
    position: relative;
    top: -1px;
    background: bisque;
    color: mix(bisque, $text-dark, 30%);
    padding: $default-padding * 0.25 $default-padding * 0.5;
}

.link-form-input {
    position: relative;
    &:before {
        @include transition(0.2s);
        content: '';
        border-radius: 2px;
        height: 2.5em;
        width: 0.5em;
        top: 50%;
        margin-top: -0.5em;
        left: -0.7em;
        display: block;
        position: absolute;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border: 2px solid $gray-border;
        border-right: none;
    }

    &.enabled {
        &:before {
            border-color: $color-blue-highlight;
        }
    }
}

.color-scheme {
    margin-top: 0;
    height: 40px;
    border: $default-border;
    display: inline-block;

    li {
        display: inline-block;
        width: 40px;
        height: 100%;
    }
}

ul.list {
    padding: 0;
    padding-left: $default-padding;

    li {
        position: relative;
        &:before {
            position: absolute;
            top: 6px;
            left: -12px;
            content: '';
            display: block;
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background: $color-blue-main;
        }

        margin-bottom: $default-margin * 0.5;
    }
}

/// Base styling for widget containers.
%_container {
    box-sizing: border-box;
    border: none;
    border-radius: $default-radius;
    padding: 0;
    > .title {
        width: 100%;
        border-bottom: $default-border;
        padding: $default-padding;
        margin: 0;
    }

    .content {
        padding: $default-padding * 1.5;
        overflow-x: auto;
    }
}

.container-title {
    width: 100%;
    border-bottom: $default-border;
    padding: $default-padding;
    margin: 0;
}

/// Base container for transparent widgets.
%clear-container {
    @extend %_container;
    background: none;
    color: $gray-darker;
}

%info-box {
    @include white-container();
    @extend .center-block;
    width: $default-info-width;
}

%widget-container {
    @extend %_container;
    background: $gray-light;
    border: $default-border;
}

.text-small {
    font-size: $small-font-size;
}

.no-wrap {
    white-space: nowrap;
}

.pointer-events-none {
    pointer-events: none;
}

.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99999999999;

    background: $color-blue-main;
    text-align: center;

    color: $color-white;

    h1 {
        font-size: 22pt;
        i {
            font-size: 36pt;
            margin-bottom: 20px;
        }
    }
}

.unshare-error-meta-details {
    font-size: $small-font-size;
}

/* Screen reader only content */
.sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

/* Skip links for screen readers */
.skip-link {
    left: 50%;
    position: absolute;
    transform: translateY(-100%);
    padding: $default-padding * 0.5;
    transition: transform 0.3s;
    background: $cf-blue;
    color: $color-white;
}

.skip-link:focus {
    transform: translateY(0%);
    clip: auto;
    height: auto;
    width: auto;
    border-radius: 0 0 8px 8px;
    z-index: 9999;
}
