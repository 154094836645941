@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.tree-selection {
    display: block;

    &.child {
        margin-top: math.div($default-margin, 3);
        padding-left: $default-padding * 1.5;
    }

    .tree-selection:last-child {
        padding-bottom: $default-padding * 0.5;
    }

    .tree-item {
        cursor: pointer;
        border: $default-border;
        border-radius: $default-radius;
        font-size: $default-font-size;
        color: $gray-darkest;
        background-color: $color-white;
        margin-bottom: $default-margin * 0.5;

        &:hover {
            border-radius: $default-radius;
            border-color: $color-blue-highlight;
            background-color: $color-blue-highlight;
        }

        &:hover > .title-button,
        &:hover > .icon-button {
            background-color: $color-blue-highlight;
            color: $color-white;
            span {
                color: $color-white;
            }
        }

        .icon-button {
            display: inline-block;
            width: 10%;
            background-color: $color-white;
            text-align: center;
            padding: $default-padding * 0.5;
            color: $color-blue-highlight;

            i:not(:only-child) {
                padding-right: $default-padding * 0.5;
            }
        }

        .title-button {
            display: inline-block;
            width: 90%;
            background-color: $color-white;
            padding: $default-padding * 0.5;
            padding-left: 0;

            span {
                padding-left: $default-padding;
                color: $color-blue-highlight;
            }
        }
    }

    .selected {
        border-color: $color-blue-highlight;
        border-radius: $default-radius;
        color: $color-blue-highlight;

        &:hover > .title-button,
        &:hover > .icon-button {
            color: $color-white;
        }
    }
}
