$loader-size: 60px;

.app-loader {
    margin: $loader-size auto;
    font-size: 6px;
    position: relative;
    text-indent: -9999em;
    border-top: 8px solid rgba(255, 255, 255, 0.7);
    border-right: 8px solid rgba(255, 255, 255, 0.7);
    border-bottom: 8px solid rgba(255, 255, 255, 0.7);
    border-left: 8px solid $color-highlight;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: loader-spin 0.8s infinite linear;
    animation: loader-spin 0.8s infinite linear;
}

.app-loader,
.app-loader:after {
    border-radius: 50%;
    width: $loader-size;
    height: $loader-size;
}

@-webkit-keyframes loader-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes loader-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
