$modal-content-width: 980px;
$modal-header-height: 140px;

.full-screen-modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background: transparentize($color-blue-main, 0.5);
    padding: $default-padding * 2 $default-padding * 4;
    z-index: 1000;
}

.full-screen-modal-backdrop {
    position: relative;
    border-radius: $default-radius * 2;
    width: 100%;
    height: 100%;
    background: $color-white;
}

.full-screen-modal-content {
    height: 100%;
    margin: 0 auto;
    position: relative;
    padding: $default-padding * 2 0;
    width: 80%;
    max-width: $modal-content-width;
    display: flex;
    flex-direction: column;
}

.full-screen-modal-actions {
    cursor: pointer;
    position: absolute;
    top: $default-margin * 2;
    right: $default-margin * 2;
    z-index: 10;
    text-align: center;

    i {
        @include transition(0.2s);
        display: inline-block;
        text-align: center;
        padding: 10px 16px;
        border-radius: 50%;
        color: $gray-dark;
        position: relative;
        font-size: 36px;
    }

    &-shortcut {
        position: relative;
        top: 6px;
        display: block;
        font-size: 80%;
        color: $gray-dark;

        > span {
            font-weight: bold;
            border-radius: $default-radius;
            padding: 2px 8px;
            display: inline-block;
            background: $gray-light;
            border: 1px solid $gray-dark;
        }
    }

    &:hover {
        i {
            background: $gray-darker;
            color: $color-white;
            transform: rotate(-90deg);
        }
    }
}

.full-screen-modal-header {
    padding-top: $default-padding;
    padding-left: $default-padding * 0.5;
    padding-right: $default-padding * 0.5;
}

.full-screen-modal-body {
    position: relative;
    height: 100%;
    flex-grow: 2;
    overflow: auto;
    padding-left: $default-padding * 0.5;
    padding-right: $default-padding * 0.5;
}

.full-screen-modal-footer {
    padding-left: $default-padding * 0.5;
    padding-right: $default-padding * 0.5;
}

h1.full-screen-modal-title {
    width: 100%;
    margin-top: 0;
    font-size: 2.2em;
    color: $gray-darkest;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
