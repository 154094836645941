@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.folder-tree {
    height: 400px;
    border: $default-border;
    border-radius: $default-radius;
    overflow-y: auto;
    padding: math.div($default-padding, 3) 0;
    padding-right: $default-padding * 0.5;

    * {
        @include prevent-selection();
    }
}
