// Header
$header-height: 55px;

// Sidebar
$navigation-sidebar-width: 230px;

$sidebar-color: #313443;
$main-color: #ebecf2;

.penneo-dashboard-header-mobile {
    @include prevent-selection();
    background: $color-white;
    position: absolute;
    height: $header-height;
    top: 0;
    left: 0;
    right: 0;

    .toggle-menu {
        color: $penneo-blue;
        font-size: 16pt;
        z-index: 999;
        position: absolute;
        top: 0.6em;
        left: 0.8em;
        cursor: pointer;
        &:hover {
            color: $text-dark;
        }
    }

    .dashboard-header-sidebar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-right: none;
        border-bottom: $default-border;
        padding: math.div($default-padding, 3);
        .logo {
            position: relative;
            height: 100%;
            width: 100%;
            text-align: center;
            padding: $default-padding * 0.25 0;
            img {
                position: relative;
                height: 100%;
            }
        }
    }

    .dashboard-header-content {
        position: absolute;
        top: 0;
        left: $navigation-sidebar-width;
        bottom: 0;
        border-bottom: $default-border;
        padding: math.div($default-padding, 1.5) $default-padding;
        right: 0;
    }
}
