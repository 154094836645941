.status-page-message {
    background: transparentize($penneo-orange, 0.9);
    border-radius: $default-radius;
    border: 1px solid $penneo-orange;
    padding: $default-padding;
    margin-bottom: $default-margin;
    color: $text-dark;
    text-align: left;

    i {
        color: $penneo-orange;
    }

    p {
        margin: $default-margin * 0.5 0;
    }

    .text-small {
        color: $text-medium;
    }

    a {
        color: $text-dark;
        padding-bottom: 2px;
        border-bottom: $text-dark 1px solid;

        @include transition(0.3s);
        i {
            @include transition(0.3s);
            color: $text-dark;
        }

        &:hover {
            i {
                color: $cf-blue;
            }
            color: $cf-blue;
            border-color: $cf-blue;
        }
    }
}
