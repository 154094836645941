$widget-width: 100%;
$max-widget-width: 500px;

.touch-container,
.insecure-methods-container {
    width: 100%;

    .wrapper {
        margin: 0 auto;
        width: $widget-width;
        max-width: $max-widget-width;
        background: $color-white;
        text-align: center;
        border-radius: $default-radius;
    }

    .title {
        text-align: left;
        font-weight: bold;
    }

    .subtitle {
        font-size: $small-font-size;
        font-weight: normal;
    }

    .widget-container {
        height: $widget-width;
        line-height: $widget-width;
        border: $default-border;
        min-height: 400px;
        overflow: hidden;

        .widget-content {
            line-height: normal;

            .controls {
                margin: $default-margin;
                margin-top: 0;
            }
        }

        .signing-successful {
            h2 {
                @include OpenSans-Regular();
                font-size: 14pt;
                font-weight: normal;
                color: $color-success;
            }
        }
    }

    .sign-text {
        padding: $default-padding;
        margin-bottom: $default-margin * 0.5;
        height: 280px;
        overflow: scroll;
        text-align: left;

        &.no-scroll {
            height: auto;
            overflow: hidden;
        }
    }

    .insecure-method {
        padding: $default-padding;
    }

    .error-message {
        margin-top: 40%;
        display: inline-block;

        > div {
            display: inline-block;
            color: $text-dark;
            line-height: normal;
        }
    }

    // @todo: make another loader type that works for loading in white backgrounds
    // instead of making this hardcoded styles
    .app-loader {
        margin-top: 40%;
        display: inline-block;
        border-top: 8px solid rgba(150, 150, 150, 0.1);
        border-right: 8px solid rgba(150, 150, 150, 0.1);
        border-bottom: 8px solid rgba(150, 150, 150, 0.1);
    }
}
