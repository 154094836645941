@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
/**********************************************************

	- main.scss

	Point of entry for the SASS compiler. This file must
	reference all other files required in the application

	Common Application-wide styles inherited from partials
	should be applied to elements in this file.

	Specific Component Styling should go into their
	respective file within ./components directory

	- Index - (Seach for 'n:' to navigate to section)

	1. - Imports
	2. - Default Styling

**********************************************************/

/** 1: Imports
=========================================================*/

@import './normalize';
@import './variables';
@import './fonts';
@import './mixins';
@import './layouts';
@import './global';
@import './common/all';
@import './components/app-loader';
@import './inputs';
@import './states';
@import './vendor';
@import './emoji/ap-16-emoji';
@import './loaders';
@import './fallback';

@import '../../Casefiles/styles/casefiles_v2';

@import '../components/Loading/loading.scss';

@import '../components/FullScreenModal/full-screen-modal.scss';

// Import Modules
@import '../../Auth/styles/main';
@import '../../Teams/styles/main';
@import '../../Forms/styles/main';
@import '../../Casefiles/styles/main';

@import '../../News/styles/main';

@import '../../Common/components/BaseTabs/tabs.scss';
// Importing inside class creates a namespace for component
@import './components/all';
@import './vendor/all';

/** 2: Default Styling
=========================================================*/

html {
    font-size: 16px;
}

body {
    font-size: $default-font-size;
}

html,
body {
    position: relative;
    height: 100%;
    width: 100%;
    font-family: $lato;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    // min-width: 700px; // Temporary Minimum width while mobile versions are not supported.
    // overflow-x: scroll;
    background: $background-color;
    /// @todo set a proper a.links style.
    a {
        &.inherit-color {
            color: inherit;

            &:hover {
                color: inherit;
            }
        }

        @include transition(0.2s);
        color: $cf-blue;
        &:hover {
            text-decoration: underline;
            &.no-underline {
                text-decoration: none;
            }
        }
    }

    * {
        -webkit-font-smoothing: antialiased;
    }
}

// Compatibility with Fontawesome 5
.fa {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
}

// Font sizes

h1 {
    font-size: $title-font-size;
    font-weight: bold;
}

h2 {
    font-size: $subtitle-font-size;
    font-weight: bold;
}

h3 {
    font-size: $heading-font-size;
    font-weight: bold;
}

h4 {
    font-size: $default-font-size;
}

p {
    font-size: $default-font-size;
}

p.small {
    font-size: $small-font-size;
}

.content-wrapper.documents {
    padding-top: 0;
}

.upload-area {
    padding-left: $default-padding;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
    overflow: hidden;
    margin-top: 0;

    .title {
        margin-top: 0;
        .optional {
            line-height: 2em;
            color: $gray-dark;
            font-size: $small-font-size;
            vertical-align: middle;
        }
    }

    .wrapper {
        padding: $default-padding;
        background: $color-white;
        border-radius: $default-radius;
    }
}

.cke_notifications_area {
    display: none;
}

#cke_notifications_area_editor1 {
    display: none;
}
