@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.openid-sign-text {
    text-align: left;

    // Override sign text defaults for styling consistency.
    .signing-box-title {
        padding-left: 0;
        @include OpenSans-Bold();
    }

    .signing-box-text {
        @include OpenSans-Regular();
        padding: 0;

        padding-bottom: $default-padding;
        &:first-child {
            padding-top: $default-padding;
        }
    }

    // Make description of document render in a single line.
    // The information is rendered in two divs that don't have any class.
    .signing-box-list-item > div:not([class]) {
        display: inline-block;
        margin-right: $default-margin * 0.25;
    }

    // Add a document icon to every document in the list of documents to be signed.
    .signing-box-subtitle {
        &:first-child {
            padding-top: 0;
        }
        &:before {
            @include far-icon('\f15c');
            font-weight: 100;
        }
    }
}
