// Header
$header-height: 55px;

$default-border: 1px solid #dedede;

$sidebar-color: #313443;
$main-color: $background-color;
// #ebecf2;

// Content
.default-content {
    padding: $default-padding;
}

.penneo-dashboard {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.penneo-layout {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.penneo-header-content {
    background: $color-white;
    position: absolute;
    height: $header-height;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

%logo {
    position: relative;
    height: 100%;
    width: 100%;
    text-align: center;
    padding: $default-padding * 0.25 0;

    a {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        display: inline-block;
        width: 100%;
        height: 100%;
    }
}

$penneo-header-branding-width: $navigation-sidebar-width * 0.8;
.penneo-header-branding {
    position: absolute;
    top: 0;
    left: 0;
    width: $penneo-header-branding-width;
    height: 100%;
    padding: math.div($default-padding, 3);

    // Emulate right and bottom border without taking extra 1px.
    box-shadow: inset -1px -1px 0 0 rgba(0, 0, 0, 0.1);

    .logo {
        @extend %logo;
    }
}

.penneo-header-layout-main {
    position: absolute;
    top: $header-height;
    // left: $navigation-sidebar-width;
    bottom: 0;
    right: 0;
    width: 100%;
    overflow-y: auto;
    background: $main-color;

    .penneo-header-main-content {
        position: relative;
        height: 100%;
        width: 100%;
        padding: $default-padding;
    }
}

.penneo-dashboard-header {
    background: $color-white;
    position: absolute;
    height: $header-height;
    top: 0;
    left: 0;
    right: 0;

    .dashboard-header-sidebar {
        z-index: 1000;
        position: absolute;
        top: 0;
        left: 0;
        width: $navigation-sidebar-width;
        height: 100%;
        padding: math.div($default-padding, 3);
        .logo {
            @extend %logo;
        }
    }

    .dashboard-header-content {
        position: absolute;
        top: -3px;
        left: $navigation-sidebar-width;
        bottom: 0;
        border-bottom: $default-border;
        padding: math.div($default-padding, 1.5) $default-padding;
        right: 0;
    }
}

$menu-item-font-size: 1em;
$main-nav-width: 50px;
.penneo-dashboard-navigation {
    position: absolute;
    top: $header-height;
    width: $navigation-sidebar-width;
    left: 0;
    bottom: 0;
    background: $sidebar-color;
    z-index: 500;

    @media #{$breakpoint-small} {
        width: 100%;
        font-size: 1.1em;
    }
}

.penneo-dashboard-main {
    position: absolute;
    top: $header-height;
    left: $navigation-sidebar-width;
    bottom: 0;
    right: 0;
    background: $main-color;

    .dashboard-main-content {
        position: relative;
        height: 100%;
        width: 100%;
        overflow: auto;
        padding: $default-padding;
        padding-top: $default-padding * 1.2;

        &.has-breadcrumbs {
            padding-top: $default-padding * 3.2;
        }

        @media #{$breakpoint-small} {
            padding: math.div($default-padding, 1.5);
        }
    }

    @media #{$breakpoint-small} {
        left: 0;
    }
}

.dashboard-menu {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    text-align: right;

    .dashboard-menu-item {
        appearance: none;
        margin: 0;
        padding: 0;
        border: 0;
        background: transparent;
        cursor: pointer;
        position: relative;
        display: inline-block;
        vertical-align: top;
        height: 100%;
        line-height: $header-height;
        padding: 0 $default-padding * 1.5;
        border-left: $default-border;
        border-top: 0px solid transparent;
        z-index: 500;
        @include transition(0.1s);

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 3px;
            background: transparent;
            transition: all 180ms ease-out;
        }
        &:hover {
            &::after {
                transition: all 180ms ease-out;
                content: '';
                position: absolute;
                top: 3px;
                bottom: 0;
                left: 0;
                right: 0;
                height: 3px;
                background: #086afa;
            }
        }

        &.open .dropdown-content {
            display: block;
        }

        .dropdown-content {
            display: none;
            position: absolute;
            right: 0px;
            top: 100%;
        }

        ul {
            position: relative;
            background: $color-white;
            border: $default-border;
            border-top: 0;
            text-align: left;
            line-height: normal;
            margin: 0;
            margin-left: 0px;
            padding: 0;
            box-shadow: 0px 7px 6px 1px rgba(0, 0, 0, 0.15);
            z-index: 10001 !important;

            li {
                white-space: nowrap;
                margin: 0;
                padding: 0;
                position: relative;

                .dropdown-img {
                    margin-right: $default-margin * 0.5;
                }

                .dropdown-link {
                    display: block;
                    padding: $default-padding;
                }
            }
        }
        @media #{$breakpoint-small} {
            ul {
                left: auto;
                right: -1px;
            }
        }
    }
}
