.archive-container {
    .content {
        padding-bottom: 1em;
    }

    .empty-message {
        font-size: 12pt;
    }

    .empty-table-view {
        padding-top: 2em;
    }

    .view-selector-container {
        position: relative;
        display: inline-block;
    }

    .search-widget-container {
        position: relative;
        top: -($default-padding * 0.5);
        width: 50%;
        display: inline-block;
    }

    .view-selector {
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        border-bottom: $color-white 2px solid;
        padding: 0 0.3em 0 0.4em;

        &:hover {
            border-bottom: $color-success 2px solid;
        }
    }
}

.container-header {
    display: inline-block;
}

.export-data {
    margin-top: $default-margin;
}

// TODO: This should be moved to Tailwind when the old signer-archive is sunset
.archive-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    &__search-group {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        gap: 0.75rem;
    }

    &__result-count {
        font-weight: 700;
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.75rem;
        padding: 0.25rem;
    }
}

.archive-search {
    position: relative;
    display: inline-block;

    &__icon {
        position: absolute;
        left: 0;
        top: -2px;
        height: 1rem;
        width: 1rem;
        opacity: 0.7;
        margin-left: 0.75rem;
        margin-top: 0.75rem;
        z-index: 10;
        font-weight: 900;
        color: currentColor;
    }

    &__input {
        padding-left: 2.25rem;
        width: 16rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}

.icon-button {
    height: 1.5rem;
    flex-shrink: 0;
}
