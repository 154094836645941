@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.better-flow-overview {
    // Pretty things go here
    h1,
    p,
    h2,
    h3,
    h4 {
        margin: 0;
    }

    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    align-content: flex-start;

    color: $text-dark;
}

.casefile-flow-overview-header {
    background: white;
    min-height: 55px; // Should be a global scss var ($header-height)
    border-bottom: $default-border;

    padding: 0 2em;

    display: flex;
    align-items: center;

    justify-content: space-between;
}

.casefile-flow-overview-subheader {
    background: white;
    padding: 1em 2em;
    border-bottom: $default-border;
}

.casefile-flow-overview-quick-glance {
    margin-top: 2em;
}

.casefile-flow-overview-quick-glance-item {
    @include OpenSans-SemiBold();
    font-size: 16px;
    margin-right: 1.5em;

    i {
        font-size: 18px;
        margin-right: 0.5em;
    }
}

.casefile-flow-rounds-container {
    height: 100vh;
    overflow: scroll;
    text-align: center;
    background-image: url(#{$images}/graphics/grid-background-pattern.svg);
    background-size: 55%;
    background-color: #f4f6fa;
    padding-bottom: 60px;
}

h2.signing-round-heading {
    @include OpenSans-Bold();
    padding: 0.6em 4em;
    margin-top: 2em;
    border-radius: 4px;
    font-size: 14px;
    background-color: #153047;
    display: inline-block;
    color: white;
}

.signing-round-block {
    background-color: white;
    border-radius: 4px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 12px,
        rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
}

.signing-round-documents,
.signing-round-signers {
    padding: 1.5em 2em;

    i {
        font-size: 17px;
        margin-right: 0.5em;
    }
}

.signing-round-documents-item,
.signing-round-signers-item {
    font-size: 16px;
    margin-top: 1em;
    @include OpenSans-SemiBold();
}

.signing-round-section-header {
    font-size: 14px;
    @include OpenSans-Bold();
    color: #7e7e7e;
}

.signing-round-signers {
    padding-top: 1em;
    background-color: #f7fafb;
}

.signing-round-block-container {
    grid-template-columns: auto;
    grid-template-rows: 1fr auto;
    margin-bottom: 1em;
    break-inside: avoid;
}

.signing-round-group {
    margin: 2em;
    text-align: left;

    column-count: 2;
    column-gap: 1em;
}

.signing-round-group.signing-round-one-column {
    column-count: auto;
    .signing-round-block-container {
        margin: 0 auto;
        width: 50%;
    }
}

.signing-round-separator {
    margin-top: -0.5em;
    margin-bottom: -0.5em;
    &-handle-top {
        margin: 0 auto;

        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: $color-blue-highlight;
    }

    &-bar {
        margin: 0 auto;

        height: 6px;
        width: 2px;
        background-color: $color-blue-highlight;

        // margin-top: 4px;
    }

    &-handle-bottom {
        margin: 0 auto;

        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: $color-blue-highlight;
    }
}

.casefile-flow-attachments {
    padding: 2em;
    background: white;
    border-top: $default-border;
}

.attachment-item {
    font-size: 16px;
    margin-top: 1em;
    margin-bottom: 1em;
    display: inline-block;
    background: #f7fafb;
    padding: 0.5em 1em;
    border-radius: 4px;
    border: $default-border;
    @include OpenSans-SemiBold();
    margin-right: 1em;
    i {
        font-size: 17px;
        margin-right: 0.5em;
    }
}
