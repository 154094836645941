.text-signature {
    line-height: normal;

    .signature-image {
        margin-right: $default-margin;
        margin-top: $default-margin;
        margin-left: $default-margin * 0.5;
    }

    &-content {
        padding: $default-padding;
        border: $default-border;
    }

    .text-signature-line {
        height: 55px;
        overflow: auto;
        background: transparentize($cf-blue, 0.9);
        border-bottom: 3px solid $cf-blue;
    }
}

.image-upload {
    line-height: normal;
    .image-upload-image {
        button {
            margin-right: $default-margin * 0.5;

            &:last-child {
                margin-right: 0;
            }
        }

        .signature-image {
            text-align: center;

            img {
                max-width: 400px;
                max-height: 500px;
            }
        }
    }

    .image-upload-dropzone {
        padding: $default-padding;
        border: $default-border;
        text-align: center;
    }
}
