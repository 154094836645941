@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
$card-shadow-blue: 0 0px 0px 1px transparentize($cf-blue, 0.2),
    0 2px 2px 0 transparentize($cf-blue, 0.8),
    0 8px 12px 0 transparentize($cf-blue, 0.9);

$card-shadow-dark-blue: 0 0px 0px 1px transparentize($color-blue-main, 0.7),
    0 2px 2px 0 transparentize($color-blue-main, 0.8),
    0 8px 12px 0 transparentize($color-blue-main, 0.9);

.penneo-card {
    width: 100%;
    position: relative;
    vertical-align: top;

    &-container {
        position: relative;
        @include transition(0.2s, all, ease-in-out);
        @include white-container();
        box-shadow: $card-shadow;
        padding: $default-padding $default-padding * 1.2;
    }

    &.hoverable:hover {
        cursor: pointer;

        .penneo-card-container {
            @include transition(0.2s, all, ease-in-out);
            box-shadow: $card-shadow-hover;
            transform: translateY(-4px);
        }

        &.blue .penneo-card-container {
            box-shadow: $card-shadow-blue;
            border-color: transparentize($cf-blue, 0.6);
        }

        &.darkblue .penneo-card-container {
            box-shadow: $card-shadow-dark-blue;
            // border-color: transparentize($cf-blue, 0.6);
        }
    }

    &.hoverable.no-motion:hover {
        .penneo-card-container {
            transform: none;
        }
    }
}

.penneo-card-hover-overlay {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 0;
    right: 0;
    z-index: 2;
    text-align: center;
    border-top-right-radius: $default-radius;
    overflow: hidden;
}

.penneo-card-hover-overlay-content {
    @include transition(0.2s, all, 'ease-in-out');
    background: $gray-dark;
    transform: rotate(45deg) translateX(20px) translateY(-20px);
    position: absolute;
    top: -30px;
    right: -30px;
    opacity: 0;
    height: 60px;
    width: 60px;
    color: $color-white;
    text-align: center;

    i {
        transform: rotate(-45deg);
        line-height: 96px;
        font-size: 9pt;
    }
}

.penneo-card:hover .penneo-card-hover-overlay-content {
    opacity: 1;
    transform: rotate(45deg) translateX(0px) translateY(0px);
}

// Themes
.penneo-card.blue {
    .penneo-card-hover-overlay-content {
        background: darken($cf-blue, 5);
    }
}

.penneo-card.darkblue {
    .penneo-card-hover-overlay-content {
        background: transparentize($color-blue-main, 0.1);
    }
}
