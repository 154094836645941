@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.signing-modal {
    .signing-components {
        .openid-modal-title-bar {
            min-height: 5rem;
        }

        /* Specific method styling fixes */
        &.bankid_se {
            .tab-switcher-content {
                padding-top: $default-padding * 4;
            }
        }
    }
}
