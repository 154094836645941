@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.casefile-document-details {
    position: relative;
    height: 100%;
}

.casefile-document-details-header {
    padding-bottom: $default-padding;
}

.casefile-document-details-actions {
    .icon-small {
        font-size: 95% !important;
    }
}

.casefile-document-details-title {
    @include OpenSans-Bold();
    color: $text-dark;
    font-size: 20pt;
    padding: 0;
    margin: 0;
}

.casefile-document-details-key {
    &-id {
        font-family: monospace;
        color: $text-dark;
        font-size: 95%;
    }

    &-label {
        font-size: 90%;
        @include OpenSans-SemiBold();
        padding-right: $default-padding * 0.5;
    }

    &-tooltip {
        text-align: left;
        padding: $default-padding * 0.5;
        display: inline-block;
        width: 220px;
        @include OpenSans-SemiBold();
    }
}

@keyframes placeholder-item-bg {
    0% {
        opacity: 0.05;
    }

    25% {
        opacity: 0.1;
    }

    50% {
        opacity: 0.05;
    }

    75% {
        opacity: 0.1;
    }

    100% {
        opacity: 0.05;
    }
}

@keyframes placeholder-item-fg {
    0% {
        transform: translateX(-1000%);
        opacity: 0.05;
    }

    50% {
        opacity: 0.1;
    }

    100% {
        transform: translateX(1000%);
        opacity: 0.05;
    }
}

.placeholder-item {
    position: inherit;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        animation: placeholder-item-bg 3s infinite;
        border-radius: $default-radius;
        background: #000;
        opacity: 0;
    }

    &:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 10%;
        background: #000;
        height: 100%;
        animation: placeholder-item-fg 1.5s infinite ease-in-out forwards;
        box-shadow: 0 0 60px 40px #000;
    }
}
