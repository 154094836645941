@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.button {
    @apply border
           content-center
           font-semibold
           gap-2
           inline-flex
           items-center
           justify-center
           p-2
           relative
           rounded
           text-sm
           whitespace-nowrap
           cursor-pointer;

    &.render-icon-left i {
        @apply order-first;
    }

    &.no-padding {
        @apply p-0;
    }
}

.button-full-width {
    @apply w-full;
}

.button:disabled,
.button[aria-disabled]:not([aria-disabled='false']) {
    @apply cursor-default;
    &.button-solid {
        @apply bg-neutral-400
               border-neutral-400
               text-white;
    }

    &.button-outline {
        @apply bg-transparent
               border-neutral-400
               text-neutral-400;
    }

    &.button-text {
        @apply bg-transparent
               text-neutral-400;
    }

    &.button-white {
        &.button-solid {
            @apply bg-neutral-400
                   border-neutral-400
                   text-neutral-700;
        }

        &.button-outline {
            @apply bg-transparent
                   border-neutral-400
                   text-neutral-400;
        }

        &.button-text {
            @apply bg-transparent
                   border-none
                   text-neutral-400;
        }
    }
}

.button-blue {
    @apply active:bg-secondary-700
           active:border-secondary-700
           bg-secondary-600
           border-secondary-600
           border-solid
           hover:bg-secondary-500
           hover:border-secondary-500
           text-white;

    &.button-outline {
        @apply active:text-secondary-700
               bg-transparent
               border-secondary-600
               hover:bg-secondary-50
               hover:text-secondary-500
               text-secondary-600;
    }

    &.button-text {
        @apply border-none
            bg-transparent
            text-secondary-600
            hover:bg-secondary-50
            hover:text-secondary-500
            active:text-secondary-700;
    }
}

.button-gray {
    @apply active:bg-neutral-800
           active:border-neutral-800
           bg-neutral-700
           border-neutral-700
           border-solid
           hover:bg-neutral-600
           hover:border-neutral-600
           text-white;

    &.button-outline {
        @apply active:text-neutral-700
               bg-transparent
               border-neutral-600
               hover:bg-neutral-100
               hover:text-neutral-500
               text-neutral-600;
    }

    &.button-text {
        @apply active:text-neutral-800
               bg-transparent
               border-none
               hover:bg-neutral-100
               hover:text-neutral-600
               text-neutral-700;
    }
}

.button-green {
    @apply active:bg-success-700
           active:border-success-700
           bg-success-600
           border-solid
           border-success-600
           hover:bg-success-500
           hover:border-success-500
           text-white;

    &.button-outline {
        @apply active:text-success-700
               bg-transparent
               border-success-600
               hover:bg-success-50
               hover:text-success-500
               text-success-600;
    }

    &.button-text {
        @apply active:text-success-700
               bg-transparent
               border-none
               hover:bg-success-50
               hover:text-success-500
               text-success-600;
    }
}

.button-red {
    @apply active:bg-danger-700
           active:border-danger-700
           bg-danger-600
           border-danger-600
           border-solid
           hover:bg-danger-500
           hover:border-danger-500
           text-white;

    &.button-outline {
        @apply active:text-danger-700
               bg-transparent
               border-danger-600
               hover:bg-danger-50
               hover:text-danger-500
               text-danger-600;
    }

    &.button-text {
        @apply active:text-danger-700
               bg-transparent
               border-none
               hover:bg-danger-50
               hover:text-danger-500
               text-danger-600;
    }
}

.button-white {
    @apply active:bg-neutral-200
           bg-white
           border-solid
           border-white
           hover:bg-neutral-100
           hover:border-neutral-100
           text-neutral-700;

    &.button-outline {
        @apply active:border-neutral-300
               active:text-neutral-300
               bg-transparent
               border-solid
               border-white
               hover:bg-opacity-10
               hover:bg-white
               hover:border-neutral-100
               text-white;
    }

    &.button-text {
        @apply active:text-neutral-300
               bg-transparent
               border-none
               hover:bg-opacity-10
               hover:bg-white
               text-white;
    }
}

.button-small {
    @apply px-1.5
           py-2
           text-xs;
}

.button-large {
    @apply px-2.5
           py-2
           text-md;
}
