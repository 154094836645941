$editor-sidebar-width: 180px;
$form-sidebar-width: 300px;
$editor-toolbar-height: 60px;
$letter-page-width: 900px;
$letter-page-height: auto;
$thumbnail-page-height: 200px;
$toggle-bar-height: 40px;

.shepherd-step {
    z-index: 99999999;
}
.toggle-bar {
    height: $toggle-bar-height;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    background: $gray-border;
    font-size: 0.9em;
    font-weight: 700;
    z-index: 1;
    .option {
        text-transform: uppercase;
        text-align: center;
        width: 50%;
        display: inline-block;
        color: $text-medium;
        vertical-align: top;
        line-height: $toggle-bar-height;
        cursor: pointer;
        &.active {
            background: $color-blue-highlight;
            color: $color-white;
        }
    }

    @media #{$breakpoint-medium} {
        display: block;
    }
}

.document-forms-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    &.form-action-bar {
        bottom: 55px;

        @media #{$breakpoint-medium} {
            bottom: 0;
        }
    }
}

%editor-sidebar-layout {
    position: absolute;
    top: 0;
    background: $gray-light;
    width: $editor-sidebar-width;
    height: 100%;
    border-right: 1px solid $gray-light;
    box-sizing: border-box;
    padding: $default-padding;
    overflow-y: auto;
}

.document-navigator {
    @include transition(0.3s);
    @extend %editor-sidebar-layout;
    left: 0;

    @media #{$breakpoint-large} {
        transform: translate(-($editor-sidebar-width), 0);
    }
}

.input-selector {
    @extend %editor-sidebar-layout;
    right: 0;
}

.hide {
    display: none;
}

.toolbar {
    // Prevent Text Selection on Toolbar
    // @see: http://stackoverflow.com/questions/880512/prevent-text-selection-after-double-click
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */

    text-align: center;
    position: relative;
    width: 100%;
    max-width: $letter-page-width;
    margin: $default-margin * 0.25 auto;
    .toolbar-content {
        display: inline-block;
        border-radius: $default-radius;
        color: $color-white;
        margin: math.div($default-margin, 1.5);
        background: $color-blue-highlight;
    }

    .toolbar-info {
        display: inline-block;
        vertical-align: middle;
        padding: math.div($default-padding, 1.5) $default-padding;
    }

    .toolbar-separator {
        display: inline-block;
        opacity: 0.5;
        padding: 0 $default-padding;
    }

    .toolbar-link {
        display: inline-block;
        text-decoration: none;
        color: $color-white;
        padding: math.div($default-padding, 1.5) $default-padding;
        vertical-align: middle;
        &:hover {
            background: rgba(0, 0, 0, 0.1);
            color: $color-white;
        }
        &.disabled {
            opacity: 0.3;
            &:hover {
                background: transparent;
            }
        }
        .fa {
            vertical-align: middle;
        }
    }
}

.save-batch-button {
    position: absolute;
    top: 0;
    right: 0;
    // right: $default-padding;
    margin: math.div($default-margin, 1.5) 0;
    background: $color-highlight;
    color: $color-white;
    border-radius: $default-radius;
}

.document-forms-viewer-wrapper {
    background: $gray-medium;
    position: absolute;
    left: $editor-sidebar-width;
    right: $editor-sidebar-width;
    top: 0;
    bottom: 0;

    &.large {
        right: $form-sidebar-width;
    }
    @include transition(0.3s);

    @media #{$breakpoint-large} {
        left: 0;
    }

    @media #{$breakpoint-medium} {
        &.large {
            right: 0;
        }
    }

    @media #{$breakpoint-medium} {
        top: $toggle-bar-height;
    }
}

.form-sidebar {
    @extend %editor-sidebar-layout;
    width: $form-sidebar-width;
    right: 0;
    padding: 0;
    @include transition(0.3s);
    @media #{$breakpoint-medium} {
        transform: translate($form-sidebar-width, 0);
    }

    @media #{$breakpoint-medium} {
        transform: translate(0, 0);
        width: 100%;
        padding-top: $toggle-bar-height;
    }

    // iOS scroll bug - re-allow scroll starting on input elements.
    // http://stackoverflow.com/a/10122689/781779
    -webkit-overflow-scrolling: touch;

    > input {
        pointer-events: auto;
        -webkit-transform: translateZ(0px);
    }

    .sidebar-content {
        margin: $default-margin;
    }
}

.gridToggle {
    display: inline-block;
    width: 100%;
}

.switch {
    position: relative;
    width: 30px;
    height: 17px;
    vertical-align: center;
}

.switch input {
    display: none;
}

.toggleLabel {
    position: relative;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 17px;
}

.slider:before {
    position: absolute;
    content: '';
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: $color-white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #2196f3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

.document-forms-viewer {
    overflow-y: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 $default-padding;
    top: $editor-toolbar-height;
    touch-action: none;
    -webkit-user-select: none;
    -webkit-user-drag: none;
    &.no-toolbar {
        top: $default-margin;
    }
    .page {
        position: relative;
        margin: $default-margin auto;
        background: $color-white;
        max-width: $letter-page-width;
        height: $letter-page-height;
        &:first-child {
            margin-top: 0;
        }

        .page-content {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
        }

        .page-field-overlay {
            font-family: $helvetica;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.page-selector {
    .page {
        position: relative;
        width: 100%;
        height: $thumbnail-page-height;
        border: 2px solid $gray-medium;
        background: $color-white;
        color: $text-dark;
        margin-bottom: $default-margin;
        border-radius: $default-radius;
        box-sizing: border-box;
        .page-number {
            position: absolute;
            display: block;
            top: 0em;
            left: 0em;
            background: $gray-border;
            text-align: center;
            vertical-align: middle;
            border-radius: 0 0 $default-radius 0;
            padding: math.div($default-padding, 2.5)
                math.div($default-padding, 1.5);
            font-size: 1em;
            color: $color-white;
            font-weight: 700;
        }
        &.selected {
            border: 2px solid $color-blue-highlight;
            .page-number {
                background: $color-blue-highlight;
            }
        }
    }
}

.input-draggable-item {
    display: inline-block;
    background: $color-white;
    border: $default-border;
    border-color: $color-blue-highlight;
    border-radius: $default-radius * 0.5;
    position: relative;
    font-size: 92%;
    padding: $default-padding * 0.25 $default-padding * 0.5;
    margin-bottom: $default-margin * 0.5;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    color: $text-medium;
    opacity: 0.8;
    .input-type {
        display: block;
        color: $color-blue-highlight;
        font-size: 75%;
        font-weight: bold;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: $color-blue-highlight;
        height: 100%;
        width: 3px;
    }

    &:hover {
        opacity: 1;
    }
}

$input-item-color: rgba(0, 128, 191, 0.05); // #f2f9fc
$input-item-border-color: rgba(0, 128, 191, 0.25); // #b5dbed

.page-field-overlay {
    &.show-grid {
        background-size: 40px 40px, 40px 40px, 8px 8px, 8px 8px;
        background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
        background-image: -webkit-linear-gradient(
                rgba(100, 100, 100, 0.1) 1px,
                transparent 2px
            ),
            -webkit-linear-gradient(0, rgba(100, 100, 100, 0.05) 1px, transparent
                        2px),
            -webkit-linear-gradient(rgba(100, 100, 100, 0.1) 1px, transparent
                        1px),
            -webkit-linear-gradient(0, rgba(100, 100, 100, 0.1) 1px, transparent
                        1px);
    }

    .input-item {
        position: absolute;
        line-height: normal; // Avoid placeholder vertically uncentered
        border-radius: 0;
        box-sizing: content-box; // Required for 2px padding compensation + accurate positioning.
        padding: 1px;
        border: none;
        margin: 0;
        background-color: #f2f9fc;
        background-color: $input-item-color;
        border: 1px solid #b5dbed;
        border: 1px solid $input-item-border-color;
        color: #aaa;
        outline: 0;
        height: 1em;
        font-family: Helvetica, Arial, sans-serif !important;
        font-size: 10pt; // scaling factor
        @include transition(0.2s);
        transition-property: box-shadow, background, color, border-color;
        resize: none;

        &:first-line {
            display: inline-block;
        }
        &:hover {
            border: 1px solid rgba(0, 128, 191, 0.5);
        }

        &.focused,
        &:focus {
            outline: 0;
            border-color: #ffeb9b;
            background-color: #fffbeb;
            background-color: transparentize(#ffeb9b, 0.6);
            box-shadow: 0px 0px 0px 1px #ffeb9b;
            z-index: 99999;
        }

        &::selection {
            background-color: #ffeb9b; /* WebKit/Blink Browsers */
        }
        &::-moz-selection {
            background-color: #ffeb9b; /* Gecko Browsers */
        }
    }
}

.document-title {
    margin-top: $default-margin * 0.25;
    &:first-letter {
        text-transform: uppercase;
    }
}
