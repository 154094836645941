@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
$card-shadow-blue: 0 0px 0px 1px transparentize($cf-blue, 0.2),
    0 2px 2px 0 transparentize($cf-blue, 0.8),
    0 8px 12px 0 transparentize($cf-blue, 0.9);

.casefile-recipients {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: -$default-margin * 0.5;
    margin-top: 0;
    margin-bottom: 0;
}

.casefile-recipient {
    cursor: pointer;
    display: inline-block;
    position: relative;
    width: math.div(100%, 3);
    min-width: 300px;
    max-width: 400px;
    padding: $default-padding * 0.5;
    vertical-align: top;

    &-container {
        position: relative;
        @include transition(0.2s, all, ease-in-out);
        @include white-container();
        box-shadow: $card-shadow;
        padding: $default-padding $default-padding * 1.2;
        height: 100%;
        display: flex;
        flex-direction: column;

        .casefile-recipient-info i {
            @include transition(0.2s);
        }

        &:hover {
            @include transition(0.2s, all, ease-in-out);
            box-shadow: $card-shadow-blue;
            transform: translateY(-4px);
            border-color: transparentize($cf-blue, 0.6);

            .casefile-recipient-info i {
                @include transition(0.2s, all, ease-in-out);
                color: $cf-blue;
            }
        }
    }

    &-footer {
        align-items: baseline;
        display: flex;
        justify-content: space-between;
        margin-top: auto;
    }

    &-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include OpenSans-Bold();
        color: $text-dark;
    }

    &-email {
        font-size: 95%;
        margin-top: 2px;
    }

    &-onbehalfof {
        font-size: 95%;
        margin-top: 2px;
    }
}

.casefile-recipient-hover-overlay {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 0;
    right: 0;
    z-index: 2;
    text-align: center;
    border-top-right-radius: $default-radius;
    overflow: hidden;
}

.casefile-recipient-hover-overlay-content {
    @include transition(0.2s, all, 'ease-in-out');
    background: darken($cf-blue, 5);
    transform: rotate(45deg) translateX(20px) translateY(-20px);
    position: absolute;
    top: -30px;
    right: -30px;
    opacity: 0;
    height: 60px;
    width: 60px;
    color: $color-white;
    text-align: center;

    i {
        transform: rotate(-45deg);
        line-height: 96px;
        font-size: 9pt;
    }
}

.casefile-recipient:hover .casefile-recipient-hover-overlay-content {
    opacity: 1;
    transform: rotate(45deg) translateX(0px) translateY(0px);
}

.casefile-recipients-cc-recipient {
    @include card();
    @include transition(0.3s);
    display: inline-block;
    cursor: pointer;

    margin-right: $default-margin;
    margin-top: $default-margin * 0.5;
    padding: math.div($default-padding, 1.5) $default-padding;
    i {
        padding-right: $default-padding * 0.5;
    }

    &:hover {
        box-shadow: $card-shadow-hover;
    }
}
