@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.signing-methods {
    position: absolute;
    right: 0;
    bottom: -0.2em;
    padding: $default-padding;
    padding-bottom: $default-padding * 0.7;
    border-radius: $default-radius * 2;
    background: $color-white;
    font-weight: 600;
    box-shadow: $card-shadow;
    z-index: 1;
    &.validation {
        position: relative;
        display: inline-block;
        padding: $default-padding * 3 $default-padding * 4;
        text-align: left;

        .validation-method-icon {
            max-width: 18px;
            max-height: 18px;
            object-fit: contain;
        }
    }

    &-list {
        margin: 0;

        li:not(:first-child) {
            margin-top: $default-padding;
        }

        eid-button:not(:first-child) {
            display: block;
            margin-top: $default-padding;
        }

        img {
            vertical-align: middle;
            margin-right: math.div($default-padding, 2);
        }
    }

    // Validation page
    &-reject {
        margin-top: $default-padding * 2.5;

        > button {
            font-size: $default-font-size;
        }
    }
}
