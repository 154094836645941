@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.casefile-signer-roles {
    font-size: 90%;
    margin-top: $default-margin * 0.5;
    list-style: disc;
    margin-left: 1em;
}

.casefile-signer-role-name {
    @include OpenSans-Bold();
    display: inline-block;
    margin-bottom: $default-margin * 0.2;
}

.casefile-signer-role-name-secondary {
    color: $text-medium;
    margin-left: math.div($default-margin, 3);
}

.recipient-item {
    display: flex;
    flex-direction: column;
}

.recipient-item-active-at-margin {
    margin-bottom: $default-margin * 0.2;
}

.item-inline-action {
    margin-left: $default-margin;
    font-size: 9.5pt;
    display: inline;
    color: $text-medium;
    color: $cf-blue;

    &:hover {
        cursor: pointer;
    }
}
