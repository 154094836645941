@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
$sign-widget-width: 100%;
$sign-widget-max-width: 500px;
$sign-widget-height: 300px;

.openid-sign {
    margin: 0 auto;
    text-align: center;
    width: $sign-widget-width;
    max-width: $sign-widget-max-width;
    background: $color-white;
    border-radius: $default-radius;
}

.openid-sign-container-logo {
    // Old logo images have a large transparent area around the logos.
    // This makes it be less spaced to the top of the container, making the widget look vertically symmetric
    margin-top: -15px;
    margin-bottom: -15px;
    // legacy value
    width: 90px;
}

// Newer logos don't have spacing baked in around them.
[data-penneo-service-id='nemlogin.dk'] .openid-sign-container-logo {
    margin-top: $default-padding;
    margin-bottom: 0;
    width: auto;
    max-width: 10rem;
}

.openid-error-friendly-message {
    margin-top: $default-margin * 0.5;
}

.openid-sign-container {
    height: $sign-widget-width;
    min-height: $sign-widget-height;
    color: $text-dark;
}

// This overrides the link styles coming from bootstrap.
// This is necessary for the time being, as the signing widgets are being mounted in the signing-service frontend.
a.openid-sign-link {
    @include OpenSans-Regular();
    @include transition(0.3s);
    color: $text-medium;
    font-weight: 300;
    font-size: $small-font-size;
    white-space: nowrap;

    &:hover {
        text-decoration: underline;
        color: $color-blue-highlight;
    }
}
