@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.donut-chart {
    margin: 0 auto;
    border-radius: 50%;
    display: block;
}

.donut-chart-track {
    fill: transparent;
    stroke: #d7d8d9;
}

.donut-chart-indicator {
    fill: transparent;
}

.donut-chart-value {
    font-size: $title-font-size;
}

.donut-chart-label {
    font-size: $title-font-size;
}
