@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
$avatar-size: 42px;
$avatar-badge-size: 20px;
$avatar-badge-icon-size: 10px;

.user-avatar {
    .user-avatar-leading {
        position: relative;
        display: inline-block;
        vertical-align: top;
        padding-right: $default-padding;
    }

    .user-avatar-details {
        display: inline-block;
        vertical-align: top;
    }

    .user-avatar-details-name {
        position: relative;
        margin: 2px 0;
        @apply font-semibold text-md;
    }

    .user-avatar-details-email {
        font-size: 95%;

        @apply text-neutral-600;
    }

    .user-avatar-details-date {
        @apply text-neutral-500 text-sm; // The date
    }

    .avatar-circle {
        text-transform: uppercase;
        display: inline-block;
        vertical-align: top;
        border-radius: 50%;
        width: $avatar-size;
        height: $avatar-size;
        text-align: center;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.025);

        @apply bg-secondary-50;

        .profile-select-list-entry:hover &,
        .profile-select-list-entry.selected & {
            @apply bg-white;
        }
    }

    .avatar-circle-text {
        height: $avatar-size;
        display: inline-block;
        @apply text-secondary-600;
        @apply font-semibold;
        @apply text-md;
        line-height: $avatar-size;
        @apply px-3;
    }

    .user-avatar-badge {
        height: $avatar-badge-size;
        width: $avatar-badge-size;
        position: absolute;
        bottom: -6px;
        border-radius: 50%;
        @apply text-neutral-600;
        @apply border-2;
        @apply border-white;
        @apply border-solid;
        right: 4px;

        &.blue {
            background: $cf-blue;
        }

        &.green {
            background: $penneo-green;
        }

        &.grey {
            @apply bg-neutral-100;
        }

        i {
            position: relative;
            bottom: 4px;
            font-size: $avatar-badge-icon-size;
        }
    }
}
