@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.branding-container {
    display: inline-block;

    &-left {
        vertical-align: top;
        display: inline-block;
        margin-right: $default-margin * 4;
    }

    &-right {
        vertical-align: top;
        display: inline-block;
    }
}

.branding-description {
    margin-bottom: $default-margin * 2;
}

.branding-footer {
    margin-top: $default-margin * 2;
}
