$tooltip-min-width: 120px;
$tooltip-max-width: 400px;
$tooltip-triangle-width: 12px;
$tooltip-triangle-height: 6px;
$tooltip-vertical-offset: 4px;
$tooltip-bg: transparentize($gray-darkest, 0.1);

.tooltip-hover {
    cursor: pointer;
    position: relative;

    .tooltip-text {
        @include transition(0.2s);
        text-transform: none;
        pointer-events: none;
        opacity: 0;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%) translateY(15px) scale(0.9);
        z-index: 9999;
        width: auto;
        max-width: $tooltip-max-width;
        min-width: $tooltip-min-width;
        margin-right: 0;
        margin-bottom: $tooltip-triangle-height + $tooltip-vertical-offset;
        background: $tooltip-bg;
        text-align: center;
        padding: $default-padding * 0.5;
        box-sizing: border-box;
        border-radius: $default-radius;
        font-size: $default-size;
        font-weight: 400;
        color: $color-white;
        word-wrap: break-word;
    }

    &.no-animations {
        .tooltip-text {
            @include transition(0s);
        }
    }

    &.no-show {
        .tooltip-text {
            @include transition(0s);
            display: none;
        }
    }

    &.show-arrow .tooltip-text .tooltip-text-triangle {
        @include bottom-triangle(
            $tooltip-triangle-width,
            $tooltip-triangle-height,
            $tooltip-bg
        );
    }

    &.down .tooltip-text {
        top: 100%;
        margin-top: 16px;
        bottom: auto;
        transform: translateX(-50%) translateY(-20px) scale(0.9);

        .tooltip-text-triangle {
            position: absolute;
            top: -($tooltip-triangle-height);
            transform: rotate(180deg);
        }
    }

    &:hover {
        .tooltip-text {
            opacity: 1;
            transform: translateX(-50%) translateY(0) scale(1);
        }
    }
}

.tooltip-underlined {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
}
