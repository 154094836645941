.password-login-widget {
    .error-message {
        margin-bottom: $default-margin;
    }
}

.password-login-form {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: $default-padding $default-padding * 2;

    label {
        color: $text-medium;
        display: inline-block;
        margin-top: $default-margin;
        margin-bottom: $default-margin * 0.25;
    }

    input {
        width: 100%;
        box-sizing: border-box;
        display: inline-block;
        margin: $default-margin * 0.5 0;
        padding: $default-padding;
    }

    .submit-button {
        text-align: center;
        margin-top: $default-margin;
        padding: math.div($default-padding, 1.6) $default-padding * 3.5;
        text-transform: none;
        font-size: 1.05em;
        border-radius: 100em;
    }
}
