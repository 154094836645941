.casefile-folder-selector-bar {
    @extend %input-base;
    width: 100%;
    padding: math.div($default-padding, 2.5) $default-padding * 0.5;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    position: relative;
    cursor: pointer;

    button {
        padding: $default-padding * 0.5 $default-padding;
        margin-right: $default-margin * 0.5;
    }

    .folder-title {
        position: absolute;
        white-space: nowrap;
        top: 12px; // manual adjustment to make long folder titles appear in one line
    }
}

.casefile-folder-actions {
    display: flex;
    justify-content: flex-end;
}

.casefile-folder-selector {
    *:not(input) {
        -webkit-font-smoothing: antialiased;
        @extend .prevent-selection;
    }

    height: 400px;
    width: 100%;
    position: relative;
    border: $default-border;
    text-align: left;

    .casefile-folder-root {
        color: $gray-darker;
        &:hover,
        &.selected {
            color: $cf-blue;
            font-weight: bold;
        }

        i {
            margin-right: math.div($default-padding, 2);
        }
    }

    .casefile-folder-container {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        overflow-y: auto;
        .desktop & {
            bottom: 54px; // fix breaking layout only on desktop
        }
        &.can-create-new {
            padding-left: $default-padding;
            top: 40px;
        }
    }

    ul {
        position: relative;
        margin: 0;
        padding: 0;
        overflow: hidden;

        ul {
            position: relative;
            padding-left: $default-padding;
            overflow: hidden;

            &:before {
                content: '';
                position: absolute;
                display: block;
                width: 1px;
                height: 100%;
                background: $gray-medium;
            }
        }

        li {
            width: 100%;
            cursor: pointer;
            &:focus {
                outline: none;
            }
        }

        .folder-title-container {
            position: relative;
            display: inline-block;
            padding: $default-padding $default-padding * 0.5;
            width: 100%;
            height: 100%;
            &:hover {
                color: $cf-blue;
            }

            > span {
                padding: math.div($default-padding, 3)
                    math.div($default-padding, 1.5);
                @include transition(0.1s);
            }
        }

        .new-folder-placeholder {
            padding: $default-padding $default-padding $default-padding * 0.5;

            > span {
                opacity: 0.6;
                border-radius: $default-radius;
                border: 1px dashed;
                padding: math.div($default-padding, 3)
                    math.div($default-padding, 1.5);
            }
        }

        .folder-new-container {
            padding: $default-padding;

            input {
                margin-bottom: $default-padding;
            }
        }

        .active {
            color: $color-blue-highlight;
            > span {
                border: 1px solid $color-blue-highlight;
                font-weight: bold;
                border-radius: $default-radius;
            }
        }

        .children {
            position: relative;
            margin-left: $default-margin * 0.5;
            text-transform: uppercase;
            font-size: 0.8em;
            top: 0px;
            color: $color-blue-highlight;
            // float: right;
        }

        .active {
            .children {
                border: none;
                font-weight: normal;
            }
        }

        .fa-chevron-down,
        .fa-chevron-right {
            font-size: 0.81em;
            position: relative;
            left: -2px;
            top: -1px;
        }
    }

    .current-folder {
        position: absolute;
        border-top: $default-border;
        bottom: 0;
        width: 100%;
        color: $color-white;
        padding: $default-padding * 0.5;
        height: 54px;
        vertical-align: middle;

        .custom-checkbox {
            position: relative;
            top: 7px;

            .check {
                margin-left: $default-padding * 0.5;
            }
        }

        button {
            position: relative;
        }
    }
}
