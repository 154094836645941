@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.signing-content {
    .document-preview {
        height: auto;

        &-pdf-container {
            height: calc(100vh - 100px);
            @media #{$breakpoint-medium} {
                /* We set this arbitrary extra height cut to fix bottom of PDF being cropped in mobile devices
                (As per tests in BrowserStack on Android and iOS) */
                height: calc(100vh - 100px);
            }
            background: $color-white;
        }

        &-pdf-canvas {
            padding-top: $default-padding;

            @media only screen and (min-width: 980px) {
                max-width: 1170px;
                margin: 0 auto;
            }

            &.native {
                overflow: hidden;
            }

            canvas {
                border: 3px solid $gray-darkest;
            }

            canvas:first-child {
                margin-top: $default-padding;
            }

            canvas:last-child {
                margin-bottom: $default-padding * 2;
            }
        }
    }
}

.pdf-iframe {
    border: none;
}
