@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
    position: relative;
}
.rdtPicker {
    display: none;
    position: absolute;
    width: 240px;
    padding: 8px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    font-size: 10pt;
    box-shadow: $card-shadow-hover;
    border: 1px solid #f9f9f9;
    margin-top: 15px;
    margin-bottom: $default-margin;
    padding-bottom: 15px;
}

.rdtOpen .rdtPicker {
    display: block;
}
.rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
}

.rdtPicker tfoot {
    position: relative;
    top: 5px;
}
.rdtPicker tfoot .rdtTimeToggle {
    position: relative;
    top: 5px;
    @include transition(0.3s);
    border-radius: 2px;
    font-weight: bold;
    color: $cf-blue;
    text-align: center;
    padding: $default-padding * 0.5;
    position: relative;
    border: 1px solid $cf-blue;

    &:after {
        content: '\f061';
        font-weight: 400;
        font-family: 'Font Awesome 5 Pro';
        padding-right: $default-padding * 0.5;
        position: absolute;
        right: 6px;
        top: 9px;
    }

    &:before {
        font-weight: 400;
        font-family: 'Font Awesome 5 Pro';
        padding-right: $default-padding * 0.5;
        content: '\f017';
    }

    &:hover {
        background: $cf-blue !important;
        color: $color-white;
    }
}

.rdtTime .rdtSwitch {
    @include transition(0.3s);
    border-radius: 2px;
    position: relative;
    font-weight: bold;
    color: $cf-blue;
    text-align: center;
    padding: $default-padding * 0.5;
    border: 1px solid $cf-blue;

    &:after {
        content: '\f060';
        font-weight: 400;
        font-family: 'Font Awesome 5 Pro';
        padding-right: $default-padding * 0.5;
        position: absolute;
        left: 6px;
        top: 9px;
    }

    &:before {
        font-weight: 400;
        font-family: 'Font Awesome 5 Pro';
        padding-right: $default-padding * 0.5;
        content: '\f073';
    }

    &:hover {
        background: $cf-blue !important;
        color: $color-white;
    }
}

.rdtPicker table {
    width: 100%;
    margin: 0;
    border-collapse: unset;
}
.rdtPicker td,
.rdtPicker th {
    font-size: 9.5pt;
    text-align: center;
    height: 24px;
}
.rdtPicker td {
    cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
    color: #999999;
}
.rdtPicker td.rdtToday {
    position: relative;
    font-weight: bold;
}
.rdtPicker td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-bottom: 6px solid $cf-blue;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 3px;
    right: 3px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
    border-radius: 2px;
    font-weight: bold;
    background-color: $cf-blue;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
    color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
}
.rdtPicker th.rdtSwitch {
    width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
    cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
    background: #eeeeee;
}

.rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
}
.rdtPicker button:hover {
    background-color: #eee;
}

.rdtPicker thead button {
    width: 100%;
    height: 100%;
}

td.rdtMonth,
td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
    background: #eee;
}

.rdtCounters {
    display: inline-block;
}

.rdtCounters > div {
    float: left;
}

.rdtCounter {
    height: 100px;
}

.rdtCounter {
    width: 40px;
}

.rdtCounterSeparator {
    line-height: 100px;
}

.rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}
.rdtCounter .rdtBtn:hover {
    background: #eee;
}
.rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
}

.rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
}

.rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
}

.rdtTime td {
    cursor: default;
}
