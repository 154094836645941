@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.start-work-widget-cta-container {
    display: flex;
    justify-content: space-between;
}

.start-work-widget-cta {
    display: flex;
    flex-direction: column;
    width: calc(50% - #{$default-padding * 2});
    overflow: hidden;

    a:hover {
        text-decoration: none;
    }

    &-image {
        margin-bottom: $default-padding * 0.3;
        font-size: 160%;
        color: $cf-blue;
    }

    &-title,
    &-subtitle {
        margin: 0;

        font-size: $heading-font-size;
    }

    &-title {
        color: $text-dark;
        font-weight: 600;
    }

    &-subtitle {
        color: $gray-dark;
        font-weight: 400;
    }

    &-button {
        margin-top: auto;
        padding-top: $default-padding;
    }

    // Only make it look disabled if main container is not also disabled
    .new-start-work-widget:not(.is-disabled) &.is-disabled {
        opacity: 0.5;

        & > a {
            @apply cursor-not-allowed;
        }
    }
}
