@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.footer {
    padding: $default-padding * 2 $default-padding $default-padding * 3.5
        $default-padding;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    background: $color-menu-bg;
    margin-bottom: -5px;

    @media #{$breakpoint-medium-min} {
        padding: $default-padding * 2 0 $default-padding * 3.5 0;
    }
}

.footer-text {
    max-width: 700px;
    margin: 0 auto;

    @media #{$breakpoint-medium-min} {
        display: flex;
        gap: $default-padding * 3;
    }

    .text-block {
        color: #fff;
        font-family: 'Open Sans';
        font-size: 14px;

        @media #{$breakpoint-medium-min} {
            width: 50%;
        }

        &.title {
            margin-bottom: $default-margin * 3;
        }

        &.links {
            color: #4aafff;
            font-weight: 600;
            line-height: 24px;
            text-decoration-line: underline;

            a {
                display: block;
                &:hover {
                    color: #86c6ff;
                }
                &:active {
                    color: #b8dcff;
                }
            }
        }
    }
}
