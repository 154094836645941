.auth-page {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: $default-padding * 4 0;
    background: $color-blue-main;

    .branding-logo {
        text-align: center;
        padding-bottom: $default-padding * 1.5;
    }

    .auth-page-container {
        box-sizing: border-box;
        margin: $default-margin * 2 auto 0;
        position: relative;
        width: 600px;
    }
}

.white-container {
    @include white-container();
    @extend %container;
}

.pl {
    padding-left: $default-padding;
}

.pr {
    padding-right: $default-padding;
}

.pb {
    padding-bottom: $default-padding;
}

.pt {
    padding-top: $default-padding;
}
