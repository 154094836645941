$signer-modal-max-height: 300px;

.signer-role-modal {
    position: relative;
    width: 100%;
    border: $default-border;
    border-radius: $default-radius;
    background: $color-white;
    max-height: $signer-modal-max-height;
    overflow-y: auto;

    > li {
        position: relative;
        border-bottom: $default-border;
        &:last-child {
            border-bottom: none;
        }
    }

    .label {
        position: relative;
        top: 1px;
    }

    input[type='text'] {
        position: absolute;
        padding: math.div($default-padding, 1.5);
        top: math.div($default-padding, 3);
    }

    label {
        padding: $default-padding $default-padding * 0.5;
        width: 100%;
        text-align: left;
    }

    tbody {
        color: $gray-dark;
        td {
            border-bottom: $default-border;
        }
    }

    .role-info {
        display: inline-block;
        right: $default-padding * 0.5;
        position: absolute;
        pointer-events: none;

        &-message {
            position: relative;
            top: 14px;
            margin-right: $default-margin * 0.25;
            span {
                text-transform: uppercase;
                font-size: math.div($small-font-size, 1.3);
                font-weight: bold;
            }

            .fa {
                position: relative;
                top: 2px;
            }

            &.multiline {
                top: 5px;
                text-align: right;
                width: 150px;
            }
        }
    }
}
