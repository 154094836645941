@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.signing-footer {
    padding: math.div($default-padding, 2);
    height: 50px;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 2;
    @apply bg-white shadow-lg shadow-black;

    &-content {
        @apply flex
               max-w-[1170px]
               relative
               flex-row
               h-full
               items-center
               mx-auto
               justify-end;

        &.has-multiple-documents {
            @apply justify-between;
        }

        .button {
            @apply md:px-2.5 md:py-2 md:text-md;

            &:not(:first-child) {
                @apply ml-2 md:ml-4;
            }
        }
    }
}
