/* Progress Bar */

.progress-bar {
    .steps {
        padding: 0;
        margin: 0;
        background: $color-white;
        color: $gray-border;
        .completed {
            color: $color-highlight;
        }
        .current {
            background: $color-blue-highlight;
            color: $color-white;
        }
        li {
            text-align: center;
            display: inline-block;
            box-sizing: border-box;
            border-right: 1px solid $gray-border;
            &:last-child {
                border-right: none;
            }
            span {
                display: inline-block;
                margin-left: $default-margin;
            }
            .fa {
                transform: scale(1.5, 1.5);
            }
        }
    }
}

@for $i from 1 through 6 {
    .steps li:first-child:nth-last-child(#{$i}),
    .steps li:first-child:nth-last-child(#{$i}) ~ li {
        width: math.div(100%, $i);
    }
}
