.animated {
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    -ms-animation-duration: 0.5s;
    -o-animation-duration: 0.5s;
    animation-duration: 0.5s;
}

// Override Fonts
div.hopscotch-bubble,
div.hopscotch-bubble h3,
div.hopscotch-bubble .hopscotch-content {
    font-family: $lato;
    font-size: $default-size;
}

// Title Positioning
div.hopscotch-bubble h3 {
    position: relative;
    margin-top: 2px;
    font-size: 1.15em;
}

// Position content in correct place.
div.hopscotch-bubble .hopscotch-content {
    padding-top: $default-padding;
    padding-left: 0px;
}

// Replace bubble sprite with CSS circle
div.hopscotch-bubble .hopscotch-bubble-number {
    background: $color-highlight;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin: 0 5px 0 0;
    line-height: 35px;
    padding: 0;
    position: relative;
    left: -5px;
    top: -5px;
}

// Override close button with .fa-times(x).
div.hopscotch-bubble .hopscotch-bubble-close {
    background: transparent;
    &:after {
        @extend .fa;
        @include transition(0.2s);
        content: '\f00d';
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        text-indent: 0;
        color: $gray-dark;
    }

    &:hover:after {
        color: $color-error;
    }
}

// Button Styling
div.hopscotch-bubble .hopscotch-nav-button {
    font-family: $lato;
    border: none;
    box-shadow: none;
    font-size: 0.9em;
    font-weight: 700;
    text-shadow: none;
    @include transition(0.2s);
    &:hover,
    &:active {
        box-shadow: none;
    }
}

div.hopscotch-bubble .hopscotch-nav-button.next {
    background: $color-blue-highlight;
    text-shadow: none;
    &:hover {
        background: $color-blue-highlight;
        box-shadow: none;
    }
}

div.hopscotch-bubble .hopscotch-nav-button.prev {
    background: $gray-border;
    color: $text-medium;
    text-shadow: none;
    &:hover {
        background: $gray-border;
        color: $text-medium;
        box-shadow: none;
    }
}

// Border Styling
div.hopscotch-bubble {
    padding: $default-padding * 0.25;
    border-color: $gray-dark;
    border-radius: $default-radius * 1.5;
    box-shadow: 2px 5px 20px rgba(0, 0, 0, 0.3);
}

div.hopscotch-bubble
    .hopscotch-bubble-arrow-container.left
    .hopscotch-bubble-arrow-border {
    border-right: 17px solid $gray-dark;
}

div.hopscotch-bubble
    .hopscotch-bubble-arrow-container.right
    .hopscotch-bubble-arrow-border {
    border-left: 17px solid $gray-dark;
}

div.hopscotch-bubble
    .hopscotch-bubble-arrow-container.down
    .hopscotch-bubble-arrow-border {
    border-top: 17px solid $gray-dark;
}

div.hopscotch-bubble
    .hopscotch-bubble-arrow-container.up
    .hopscotch-bubble-arrow-border {
    border-bottom: 17px solid $gray-dark;
}
