@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.tooltip-required {
    > i {
        cursor: pointer;
        font-size: 0.5em;
        margin-left: 0.25em;
        position: relative;
        top: -0.8em;
    }
}
