.signature-pad {
    position: relative;
    line-height: normal;

    h2 {
        @include OpenSans-Regular();
        margin-top: 0;
        font-size: 14pt;
        color: #9b9b9b;
        font-weight: normal;
    }

    canvas {
        cursor: pointer;
        background: $color-white;
        width: 100%;
        border: 1px solid $color-blue-highlight;
        border-radius: $default-radius;
    }

    &-controls {
        padding-top: $default-padding * 0.5;
        text-align: center;

        .button-gray {
            margin-right: $default-margin * 0.5;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
