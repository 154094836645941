@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.activity-graph-widget-title {
    margin-top: 0;
}

.activity-graph-widget-content {
    display: flex;
    justify-content: space-between;
}

.activity-graph-widget-date-select {
    position: absolute;
    right: $default-padding * 1.75;
    top: $default-padding * 1.75;

    select {
        font-size: $small-font-size !important;
        padding-top: math.div($default-padding, 2.5) !important;
        padding-bottom: math.div($default-padding, 2.5) !important;
    }
}

.activity-graph-widget-graphic {
    position: relative;
    width: 50%;
}

.activity-graph-widget-text {
    width: calc(50% - #{$default-padding * 2});
    display: flex;
    align-items: center;
}

.activity-graph-legend-item > a {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: $small-font-size;
    &:not(:hover) {
        color: $gray-dark;
    }
}

.activity-graph-legend {
    margin: 0;

    &-item {
        a {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            font-size: $small-font-size;
        }
        &.hovered {
            color: $cf-blue;
            text-decoration: underline;
            a {
                color: $cf-blue;
            }
        }
    }
}

.activity-graph-legend-item span {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    content: '';
    display: block;
    margin-right: 8px;
}

.activity-graph-widget-total {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    color: $text-dark;
    font-size: $title-font-size;
    font-weight: 600;
    margin-top: 0;
}

.activity-graph-widget-total span {
    font-size: $heading-font-size;
    font-weight: 400;
    color: $gray-darker;
}
