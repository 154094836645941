.delete-user-prompt {
    &-to-delete-list {
        padding: $default-padding;
    }

    .note {
        text-align: left;
        border-left: 3px solid $color-warning;
        padding-left: $default-padding * 0.5;
        // font-size: 0.95em;
    }
}

.delete-user-prompt-form {
    padding: $default-padding;
    background: $gray-light;
    text-align: left;

    .buttons {
        margin-top: $default-margin * 1.5;
        width: 100%;
        text-align: center;
    }

    .transfer-owner-selection {
        margin-top: $default-margin;
        font-weight: bold;
        width: 100%;
        input {
            font-weight: normal;
            width: 100%;
        }
    }

    .custom-checkbox .label {
        padding: 0;
        font-size: 1em;
        color: $text-medium;
    }
}
