@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.penneo-modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparentize($color-blue-main, 0.5);
    padding: $default-padding * 5 $default-padding * 2;
    z-index: 9999999;
    overflow-y: auto;
    overscroll-behavior: auto contain;

    * {
        -webkit-font-smoothing: antialiased;
    }
}

.penneo-modal-container {
    position: relative;
    border-radius: $default-radius;
    width: 100%;
    max-width: $modal-width-small;
    margin: 0 auto;
    background: $color-white;
}

.penneo-modal.xlarge .penneo-modal-container {
    max-width: $modal-width-xlarge;
    width: 100%;
}

.penneo-modal.fullscreen {
    padding: 0;
    background: none;
}

.penneo-modal.fullscreen .penneo-modal-container {
    max-width: none;
    border-radius: none;
    height: 100%;

    .penneo-modal-actions {
        i {
            padding: 10px 16px;
            font-size: 36px;
        }

        &-shortcut {
            opacity: 1;
        }
    }
}

.penneo-modal-content {
    height: 100%;
    margin: 0 auto;
    position: relative;
    padding: $default-padding * 2 0;
    margin: 0 $default-padding * 2;
    display: flex;
    flex-direction: column;
    color: $text-dark;
}

.penneo-modal-actions {
    cursor: pointer;
    position: absolute;
    top: $default-padding * 1.5;
    right: $default-padding * 1.5;
    z-index: 10;
    text-align: center;

    i {
        @include transition(0.2s);
        display: inline-block;
        text-align: center;
        padding: 8px 12px;
        border-radius: 50%;
        color: $gray-dark;
        position: relative;
        font-size: 24px;
    }

    &-shortcut {
        @media #{$breakpoint-small} {
            display: none;
        }

        opacity: 0;
        position: relative;
        top: 2px;
        display: block;
        font-size: 80%;
        color: $gray-dark;

        > span {
            font-weight: bold;
            border-radius: $default-radius;
            padding: 2px 8px;
            display: inline-block;
            background: $gray-light;
            border: 1px solid $gray-dark;
        }
    }

    &:hover {
        i {
            background: $gray-darker;
            color: $color-white;
            transform: rotate(-90deg);
        }

        .penneo-modal-actions-shortcut {
            opacity: 1;
        }
    }
}

.penneo-modal-header {
    padding-left: $default-padding * 0.5;
    padding-right: $default-padding * 0.5;
    color: #000;
}

.penneo-modal-body {
    position: relative;
    height: 100%;
    flex-grow: 2;
    overflow: auto;
    padding-left: $default-padding * 0.5;
    padding-right: $default-padding * 0.5;

    &.overflow-visible {
        overflow: visible;
    }
}

.penneo-modal-footer {
    padding-left: $default-padding * 0.5;
    padding-right: $default-padding * 0.5;
}

h1.penneo-modal-title {
    width: 100%;
    margin-top: 0;
    font-size: 2.2em;
    color: $gray-darkest;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
