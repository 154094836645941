@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.signing-validation {
    position: relative;
    padding: $default-padding * 2;
    max-width: 1170px;
    min-height: calc(100vh - 100px);
    margin: 50px auto;
    background: $color-white;
    @media only screen and (min-width: 768px) {
        padding: $default-padding * 2 $default-padding * 3;
    }

    &-background {
        position: absolute;
        z-index: 0;
        width: calc(100% - #{$default-padding * 4});
        height: calc(100% - #{$default-padding * 4});
        background-image: url(#{$images}/sign/hidden-document.png);
        @media only screen and (min-width: 768px) {
            width: calc(100% - #{$default-padding * 6});
        }
    }

    &-content {
        position: relative;
        z-index: 1;
        margin: 0 auto;
        text-align: center;
        @media only screen and (min-width: 768px) {
            padding: $default-padding * 2 0;
        }
    }

    &-header {
        margin-bottom: $default-padding * 3;
    }
}
