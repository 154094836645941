@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.my-pending-signatures-widget {
    .doesnt-expire {
        color: transparent;
        user-select: none;
    }

    .widget-entry-title {
        margin: 0;
        padding: 0;
        font-weight: 300;
    }

    .widget-entry-details {
        margin-top: 0;
        margin-bottom: 0;
    }
}
