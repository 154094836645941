.classic-credential-section {
    h3,
    h4 {
        margin-top: 0;
    }

    .username {
        display: block;
        .label,
        .value {
            display: inline-block;
            padding: $default-padding * 0.5 $default-padding * 1;
            border: $default-border;
        }

        .label {
            border-top-left-radius: $default-radius;
            border-bottom-left-radius: $default-radius;

            background-color: $gray-medium;
            font-weight: bold;
        }

        .value {
            border-top-right-radius: $default-radius;
            border-bottom-right-radius: $default-radius;

            border-left: none;
        }

        .last-updated {
            margin-left: $default-margin;
            opacity: 0.7;
            font-style: italic;
            font-size: $small-font-size;
        }
    }

    padding: $default-padding;
    border: $border-light;
    margin-bottom: $default-margin;
}

.auth-methods-list {
    h3,
    h4 {
        margin-top: 0;
    }

    padding: $default-padding;
    border: $border-light;
}
