.penneo-loader,
.penneo-loader:after {
    border-radius: 50%;
    width: 5em;
    height: 5em;
}

.penneo-loader {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.5em solid rgba(255, 255, 255, 0.9);
    border-right: 0.5em solid rgba(255, 255, 255, 0.9);
    border-bottom: 0.5em solid rgba(255, 255, 255, 0.9);
    border-left: 0.5em solid $color-success;
    background-color: $gray-light;
    transform: translateZ(0);
    animation: load8 0.8s infinite linear;

    &.large {
        width: 10em;
        height: 10em;
        border-width: 10px;

        &::after {
            width: 5em;
            height: 5em;
        }
    }

    &.small {
        width: 20px;
        height: 20px;
        border-width: 2px;

        &:after {
            width: 20px;
            height: 20px;
        }
    }

    &.light,
    &.inline {
        border-color: $color-success;
        background-color: transparent;
        border-left-color: $color-white;
    }

    &.inline {
        display: inline-block;
        vertical-align: middle;
    }
}

@-webkit-keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
