@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.client-selector {
    position: relative;
    width: inherit;

    &-input {
        z-index: 0;
        position: relative;
        width: 100%;
    }

    &-autocomplete {
        $item-height: 48px;
        background-color: $color-white;
        box-shadow: $card-shadow-hover;
        max-height: $item-height * 3.5;
        overflow: scroll;
        cursor: pointer;
        border-radius: $default-radius;
        position: absolute;
        z-index: 1;
        top: 100%;
        width: 100%;

        .autocomplete-client {
            display: flex;
            flex-direction: row;
            min-height: $item-height;
            padding: $default-padding;

            width: inherit;
            display: block;
            padding: math.div($default-padding, 1.5) $default-padding;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;

            &:hover {
                border-color: $gray-medium;
                background-color: $gray-light;
            }
        }
    }
}
