.member-item {
    padding: $default-padding;
    position: relative;

    &:hover {
        box-shadow: $card-shadow;
    }

    &-avatar {
        display: inline-block;
        vertical-align: middle;
        margin-right: $default-margin;
    }

    &-details {
        position: relative;
        margin-top: -0.25em;
        display: inline-block;
        vertical-align: middle;
    }

    &-actions {
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        right: 0;
        top: $default-padding;
        padding-right: $default-padding;
    }

    &-remove {
        display: inline-block;

        &:hover {
            cursor: pointer;
            color: $color-error;
        }
    }

    &-permissions {
        display: inline-block;
        margin-right: $default-margin;
    }
}
