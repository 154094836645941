@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.regletter-recipient-list-container {
    ul {
        @apply md:grid
                md:gap-4
                md:grid-cols-2
                lg:grid-cols-3;

        li {
            @apply md:block
                    md:mb-0;

            .penneo-card {
                &,
                &-container {
                    @apply h-full;
                }
            }
        }
    }

    .recipient-list-item {
        &-name {
            @apply mr-0;
        }

        &-main > span {
            @apply block;
        }

        &-access {
            @apply mt-2;
        }

        &-type {
            @apply justify-center;
        }
    }
}
