@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.sidebar-navigation {
    user-select: none;
    position: absolute;
    top: 55px;
    bottom: 0;
    width: 240px;
    padding: $default-padding * 1.5 $default-padding;
    background: $color-menu-bg;
    color: #999999;
    min-height: 600px;
    z-index: 900;
    overflow-x: hidden;
    overflow-y: auto;
    transition: width 180ms ease-out;
}

.sidebar-navigation.mobile {
    width: 100%;
    top: 55px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 200;
    font-size: 12pt;
    opacity: 0;
    transform: translateX(-100%);
    @include transition(0.3s);

    .collapse-tab {
        display: none;
    }

    &.open {
        opacity: 1;
        transform: translateX(0%);
    }
}

.sidebar-navigation-app-version {
    @include transition(0.2s);
    position: absolute;
    bottom: $default-padding;
    left: $default-padding;
    font-weight: bold;
    font-size: 9pt;
    color: $color-white;
    opacity: 0;
    &:hover {
        color: $color-white;
        opacity: 0.8;
    }
}

.sidebar-navigation .menu-item {
    @include OpenSans-SemiBold();
    @include transition(
        0.3s,
        'background, color, border, padding, height, width, opacity'
    );

    position: relative;
    line-height: 44px;
    border-top-right-radius: $default-radius;
    border-bottom-right-radius: $default-radius;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;

    .menu-item-icon {
        vertical-align: middle;
        width: 40px;
        font-size: 150%;
        padding-right: 1rem;
    }

    i {
        @include transition(
            0.3s,
            'background, color, border, padding, height, width, opacity'
        );
        color: transparentize(white, 0.4);
        font-size: 110%;
    }

    a:hover {
        color: $color-white;
    }

    .children-indicator {
        margin-left: auto;
        margin-right: 0;
        color: $color-white;
        font-size: 100%;
    }

    .menu-item-content {
        margin-left: 0.8rem;
        display: flex;
        align-items: center;
        color: $gray-dark;
        appearance: none;
        border: 0;
        background: transparent;
        padding: 0;

        &.has-children {
            width: calc(100% - 1.6rem);
        }

        &:hover {
            text-decoration: none;
            color: $color-white;
            @include transition(0.3s, 'color');
        }

        > a {
            color: inherit;
            display: block;
        }
    }
}

.sidebar-navigation .top-level-menu-container {
    overflow: visible;
    height: calc(100% - 55px);
    display: flex;
    flex-direction: column;
}

.sidebar-navigation .top-level-menu-container .dashboard-item {
    margin-top: 2rem;
}

.sidebar-navigation .top-level-menu-container .middle-items {
    margin-top: 4rem;
    margin-bottom: 0;
}

.sidebar-navigation .top-level-menu-container .bottom-items {
    margin: auto 0 0 0;
}

.sidebar-navigation .top-level-menu {
    text-align: left;
}

.sidebar-navigation:not(.collapsed) .sub-level-menu {
    > .menu-item {
        font-size: 95%;
        left: -2px;

        .menu-item-content {
            padding-left: $default-padding * 1.6;
        }
    }
}

.sidebar-navigation .collapse-tab {
    cursor: pointer;
    position: fixed;
    top: 40%;
    border-top-right-radius: 0.8em;
    border-bottom-right-radius: 0.8em;
    background: $color-menu-bg;
    left: 220px;

    color: transparentize($color-white, 0.35);
    appearance: none;
    border: 0;
    padding: 0;

    .collapse-tab-icon {
        padding: $default-padding * 1.5 $default-padding * 1.5 $default-padding *
            1.5 0;
        position: relative;
        left: 14px;
    }

    &:hover {
        color: $color-white;
    }
}

.tooltip-hover.collapse-tab-tooltip {
    position: relative;
    z-index: 99999;

    .shortcut-key {
        opacity: 0.7;
        font-weight: bold;
        font-size: 90%;
        padding-left: $default-padding * 0.5;
    }

    .tooltip-text {
        @include transition(0.2s);
        text-transform: none;
        pointer-events: none;
        background: rgba(0, 0, 0, 0.8);
        padding: $default-padding;
        opacity: 0;
        top: -50%;
        margin-top: -2px;
        bottom: auto;
        position: absolute;
        display: inline-block;
        @include OpenSans-SemiBold();
        white-space: nowrap;
        left: 125%;
        transform: none;
        z-index: 9999;
        text-align: center;
        box-sizing: border-box;
    }

    &.show-arrow div.tooltip-text-triangle {
        @include bottom-triangle(20px, 10px, rgba(0, 0, 0, 0.8));
        position: absolute !important;
        left: 0;
        margin: 0;
        left: -9px;
        top: 50%;
        margin-top: -4px;
        transform: rotate(90deg);
    }

    &:hover {
        .tooltip-text {
            opacity: 1;
            transform: none;
        }
    }
}

// Collapsed styles
.sidebar-navigation.collapsed {
    width: $collapsed-navigation-sidebar-width;

    .collapse-tab {
        left: 55px;
    }

    .menu-item {
        height: 45px;
        width: 45px;
        border-radius: $default-radius;
        text-align: center;
        border-left: 2px solid transparent;

        i {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            padding: 0;
        }
    }

    .menu-item-content {
        margin-left: 4px;
        height: 100%;
    }

    .menu-item-title {
        display: none;
    }

    .children-indicator {
        margin-left: 2.3rem;
        right: -15px;
        font-size: 85%;
        transform: translate(-50%, -50%);
    }

    .cta-main {
        height: 45px;
        width: 45px;
    }

    // Sub menu items
    .sub-level-menu-container {
        border-left: 1em transparent solid;
        position: absolute;
        line-height: normal;
        left: 100%;
        top: 0;
        z-index: 9999999999999999999999999999;

        .connect-indicator {
            position: absolute;
            left: -$default-padding;
            top: $default-padding * 1.6;
            height: 2px;
            width: 20px;
            background: $penneo-green;
            display: none;
            &:last-child {
                content: '';
                display: block;
                background: $penneo-green;
                width: 100px;
                height: 100px;
                top: 0;
                left: 0;
                position: absolute;
            }
        }

        .sub-level-menu {
            text-align: left;
            border-radius: $default-radius;
            padding: $default-padding;
            background: lighten($color-blue-main, 5);
            border-left: 3px solid $penneo-green;

            &:after {
                @include bottom-triangle(12px, 6px, $penneo-green);
                top: $default-padding * 1.5;
                left: -3px;
                transform: rotate(90deg);
            }

            .parent-item-title {
                font-weight: bold;
                padding: 0;
                display: inline-block;
                text-align: left;
                font-size: 9pt;
                text-transform: uppercase;
                padding-bottom: $default-padding;
            }

            .menu-item {
                border-left: none;
            }
        }

        .menu-item {
            white-space: nowrap;
            height: auto;
            width: auto;
            text-align: left;

            i {
                left: auto;
                top: auto;
                transform: none;

                position: relative;
                padding-right: 0;
                padding-right: $default-padding * 0.5;
            }
        }

        .menu-item-title {
            display: inline;
        }
    }

    // States
    .active-children {
        background: transparentize(#fff, 0.9);
    }
}

.whats-new-sidebar {
    position: absolute;
    width: 500px;
    height: 100%;
    transition: transform 0.2s ease;
    transform: translateX(-340px);
    z-index: 898;
    background-color: white;
    overflow: scroll;

    @media (max-width: 480px) {
        display: none;
    }
}

.whats-new-sidebar.active {
    transform: translateX($navigation-sidebar-width);
}

.sidebar-navigation.collapsed + .whats-new-sidebar {
    left: -$navigation-sidebar-width;
    top: 55px;
    height: calc(100% - 55px);
}

.sidebar-navigation.collapsed + .whats-new-sidebar.active {
    left: $collapsed-navigation-sidebar-width - $navigation-sidebar-width;
}

.whats-new-posts-container {
    padding: $default-padding $default-padding $default-padding $default-padding *
        2;
}

.whats-new-no-posts p {
    font-size: 1.4rem;
}

.whats-new-no-posts a {
    color: $cf-blue;
}

.whats-new-post {
    padding: 0.6rem;
    border-bottom: 1px solid $gray-border;
    margin-bottom: 1rem;

    &:last-child {
        border-bottom: none;
    }
}

.whats-new-close-icon-container {
    background-color: rgb(255, 255, 255, 0.8);
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    top: 0;
    position: sticky;
}

.whats-new-close-button {
    appearance: none;
    margin: 0;
    padding: 8px;
    border: 0;
    background: transparent;
}

.whats-new-close-icon {
    position: relative;
    font-size: x-large;
    color: $cf-blue;

    &:hover {
        color: $color-microsoft;
    }
}

.status-new {
    background-color: $penneo-green;
    color: white;
    font-weight: 600;
    border-radius: 1rem;
    padding: 0 0.8rem 0 0.8rem;
    margin-right: 1rem;
}

.post-date {
    color: $text-medium;
}

.post-title {
    font-size: 1.1rem;
    margin: 0;
}

.post-excerpt {
    margin-bottom: $default-margin * 1.5;
}

.post-image {
    border-radius: 1rem;
    max-width: 100%;
    margin-bottom: $default-margin;
}

#whats-new-button-container {
    display: flex;

    @media (max-width: 480px) {
        display: none;
    }
}

// Pulsating what's new dot
.ring-container {
    position: relative;
    top: -2px;
    left: -60px;

    &.ring-container-collapsed {
        top: 16px;
        left: -12px;
    }
}

.circle {
    width: 10px;
    height: 10px;
    background-color: $penneo-green;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    left: 20px;
}

.ringring {
    border: 3px solid $penneo-green;
    border-radius: 30px;
    height: 20px;
    width: 20px;
    position: absolute;
    left: 15px;
    top: 15px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
}

@keyframes pulsate {
    0% {
        -webkit-transform: scale(0.1, 0.1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

.dashboard-overlay {
    visibility: hidden;
    position: absolute;
    background-color: black;
    top: 55px;
    z-index: 897;
    height: calc(100% - 55px);
    transform: translateX($collapsed-navigation-sidebar-width);
    width: calc(100vw - $collapsed-navigation-sidebar-width);
    opacity: 0;
    @include transition(0.3s, 'opacity');

    &.active {
        opacity: 0.5;
        visibility: visible;
    }

    @media (max-width: 480px) {
        display: none;
    }
}

.top-overlay {
    visibility: hidden;
    background-color: black;
    opacity: 0;
    position: absolute;
    z-index: 600;
    height: 55px;
    width: calc(100vw - $navigation-sidebar-width);
    transform: translateX($navigation-sidebar-width);
    @include transition(0.3s, 'opacity');

    &.active {
        opacity: 0.5;
        visibility: visible;
    }

    @media (max-width: 480px) {
        display: none;
    }
}

.top-level-tooltip {
    line-height: normal;
    border-left: 3px solid $penneo-green;
    white-space: nowrap;
    border-bottom-right-radius: $default-radius;
    border-top-right-radius: $default-radius;
    background: lighten($color-menu-bg, 5);

    .parent-item-title {
        font-weight: bold;
        padding: 0;
        display: inline-block;
        text-align: left;
        font-size: 9pt;
        padding: $default-padding * 1.2;
        color: $text-light;

        &.active {
            background: transparentize(#fff, 0.9);
        }

        &:hover {
            color: white;
        }

        &:after {
            @include bottom-triangle(12px, 6px, $penneo-green);
            top: calc(48%);
            left: -3px;
            transform: rotate(90deg);
        }
    }

    .children {
        display: flex;
        flex-direction: column;
    }
}
