@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.extend-deadline {
    &-input {
        display: inline-block;
    }

    &-button {
        display: inline-block;
    }
}
