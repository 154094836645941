.form-rejected-page {
    @extend %info-box;
    text-align: center;
    .error-header-image {
        .fa {
            color: $color-warning;
            font-size: 4em;
        }
    }
    .title {
        margin-top: 0;
        color: $color-warning;
        margin-bottom: $default-margin * 0.25;
        text-transform: uppercase;
        font-weight: 700;
    }
}
