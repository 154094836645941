$highlight-yellow: #f7db6f;

.news-toggle-widget {
    &.unread-items {
        > a {
            animation: pulse 2s infinite;
            padding-right: $default-padding * 3;
        }

        .unread-count {
            display: block;
        }
    }

    .unread-count {
        display: none;
        background: $highlight-yellow;
        color: $color-blue-main;
        font-weight: bold;
        position: absolute;
        top: 3px;
        right: 3px;
        bottom: 3px;
        vertical-align: middle;
        line-height: 1.8em;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        padding: 0 math.div($default-padding, 1.5);
    }

    margin-bottom: $default-margin * 0.5;

    > a {
        display: block;
        position: relative;
        animation: none;
        box-shadow: 0 0 0 transparentize($highlight-yellow, 0.5);
        padding: $default-padding * 0.5 $default-padding * 1.5;
        border-radius: $default-radius;
        width: 100%;
        display: inline;

        background: transparentize($highlight-yellow, 0.95);
        border: 1px solid $highlight-yellow;
        color: $highlight-yellow;

        &:hover {
            color: $highlight-yellow;
        }
    }

    &-simple {
        .unread-count {
            position: absolute;
            top: 5px;
            right: 3px;
            display: inline-block;
            width: 14px;
            font-size: 0.8em;
            font-weight: 700;
            text-align: left;
        }
        &.unread-items {
            color: $highlight-yellow !important;
        }
    }
}

// Pulse animation

@keyframes pulse {
    0% {
        background: transparentize($highlight-yellow, 0.9);
        box-shadow: 0 0 0 0 transparentize($highlight-yellow, 0.5);
    }
    70% {
        box-shadow: 0 0 0 8px transparentize($highlight-yellow, 1);
    }
    100% {
        background: transparentize($highlight-yellow, 0.95);
        box-shadow: 0 0 0 0 transparentize($highlight-yellow, 1);
    }
}
