/* Header */

.header {
    @include transition(0.3s);
    height: $header-height;
    line-height: $header-height;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 900;
    box-shadow: 0px 0px 29px -9px rgba(0, 0, 0, 0.35);
    a {
        color: inherit;
        &:hover {
            color: inherit;
        }
    }

    .header-options {
        .option {
            display: inline-block;
            margin-left: $default-margin;
        }
    }

    .link {
        cursor: pointer;
        &:hover {
            color: $color-highlight;
        }
    }
}

.header-content {
    position: relative;
    max-width: $content-width;
    margin: 0 auto;
}

.branding {
    .logo {
        height: 38px;
        vertical-align: middle;
        position: relative;
        top: -2px;
    }
}
