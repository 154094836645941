@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
$form-width: 480px;
$card-shadow-blue: 0 0px 0px 1px transparentize($cf-blue, 0.2),
    0 2px 2px 0 transparentize($cf-blue, 0.8),
    0 8px 12px 0 transparentize($cf-blue, 0.9);

.signer-details {
    margin-top: $default-margin;

    &-title {
        @include OpenSans-Bold();
        color: $text-dark;
        font-size: 20pt;
        padding: 0;
        margin: 0;
    }

    &-footer {
        position: relative;
        width: 100%;
        padding-top: $default-padding * 1.5;
    }

    &-master-data {
        width: $form-width;
    }

    &-validation {
        width: $form-width;
    }

    .field-description {
        display: block;
        margin-bottom: $default-padding * 0.5;
    }

    &-actions {
        p {
            margin-top: 0;
        }

        .signer-action {
            margin-bottom: $default-margin;
            padding-bottom: $default-padding;
            border-bottom: 1.5px dashed $gray-medium;

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: 0;
            }
        }
    }

    &-files {
        .signer-details-file {
            cursor: pointer;
            display: inline-block;
            position: relative;
            width: math.div(100%, 3);
            min-width: 280px;
            padding: $default-padding * 0.5;
            vertical-align: top;

            &-container {
                position: relative;
                @include transition(0.2s, all, ease-in-out);
                @include white-container();
                box-shadow: $card-shadow;
                padding: $default-padding $default-padding * 1.2;

                &:hover {
                    @include transition(0.2s, all, ease-in-out);
                    box-shadow: $card-shadow-blue;
                    transform: translateY(-4px);
                    border-color: transparentize($cf-blue, 0.6);
                    color: $color-blue-highlight;
                }
            }
        }

        .signer-details-file-hover-overlay {
            position: absolute;
            width: 45px;
            height: 45px;
            top: 0;
            right: 0;
            z-index: 2;
            text-align: center;
            border-top-right-radius: $default-radius;
            overflow: hidden;
        }

        .signer-details-file-hover-overlay-content {
            @include transition(0.2s, all, 'ease-in-out');
            background: darken($cf-blue, 5);
            transform: rotate(45deg) translateX(20px) translateY(-20px);
            position: absolute;
            top: -30px;
            right: -30px;
            opacity: 0;
            height: 60px;
            width: 60px;
            color: $color-white;
            text-align: center;

            i {
                transform: rotate(-45deg);
                line-height: 96px;
                font-size: 9pt;
            }
        }

        .signer-details-file:hover .signer-details-file-hover-overlay-content {
            opacity: 1;
            transform: rotate(45deg) translateX(0px) translateY(0px);
        }
    }

    &-eventlog {
        border-radius: $default-radius;
        box-shadow: $card-shadow;

        .row {
            padding: $default-padding;
        }

        .column {
            display: inline-block;
        }

        .column-small {
            width: 80px;
        }

        .column-1 {
            width: 200px;
        }

        .table-sticky-headers {
            > div {
                display: inline-block;
            }

            @include OpenSans-SemiBold();
            color: $color-blue-main;
            border-bottom: $default-border;
            background-color: $gray-light;
        }

        .event .user {
            font-weight: bold;
            color: $color-blue-main;
        }

        .event .email {
            font-weight: normal;
            font-size: 90%;
            color: $color-blue-main;
        }

        .table-sticky-content {
            overflow: scroll;
            max-height: 400px;
        }
    }
}

.resend-request-confirm {
    .message {
        font-weight: bold;
        font-size: $title-size;
        padding: $default-padding * 1.5 0;
    }
}
