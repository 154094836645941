@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.login-footer {
    font-size: 0.9em;
    ul li {
        display: inline-block;
        a {
            color: $color-white;
        }
        &:after {
            margin: 0 $default-margin * 0.5;
            color: $color-white;
            content: '|';
        }
        &:last-child:after {
            content: none;
        }
    }
}
