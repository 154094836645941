.bankid-no-error {
    position: relative;
    border-radius: 3px;
    background-color: #fbfbfb;
    border: 1px solid #bbb;
    line-height: 280px;
    .header {
        height: 46px;
        text-align: left;
        line-height: 46px;
        border-bottom: 1px solid #bbb;
        padding-left: $default-padding * 0.5;
        font-size: 18px;
        img {
            vertical-align: baseline;
        }
        .title {
            text-transform: capitalize;
            padding-left: $default-padding * 0.25;
            position: relative;
            top: -1px;
        }
    }
    font-size: 100%;
    .content {
        line-height: 1.2;
        display: inline-block;
        vertical-align: middle;
        font-size: 15px;
        color: #555;
    }

    .bankid-no-buttons {
        margin-top: $default-margin;
        button {
            text-transform: none;
            box-sizing: content-box;
            padding: 2px 8px;
            padding-top: 1px;
            border: 1px solid #bbb;
            -moz-border-radius: 3px;
            -webkit-border-radius: 3px;
            border-radius: 3px;
            height: 31px;
            min-width: 21px;
            white-space: nowrap;
            margin: 0;
            vertical-align: middle;
            font-size: 14.5px;
            background: #fbfbfb;
            font-weight: normal;
            color: #555;
            opacity: 1;
            .fa {
                padding-left: -2;
            }

            &:hover {
                background: $color-white;
                color: #333;
            }
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 46px;
        text-align: left;
        line-height: 46px;
        border-top: 1px solid #bbb;
        padding: 0 $default-padding * 0.5;
        .error-code {
            display: inline-block;
            .title {
                margin-top: 6px;
                line-height: 14px;
                display: block;
                font-size: 12px;
                font-weight: bold;
                color: #555;
            }

            .text {
                line-height: 20px;
                font-size: 14px;
                display: block;
                color: #555;
            }
        }
    }
}
