.header-container {
    .header-layout-content {
        background: $background-color;
        position: absolute;
        top: 55px;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
