@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
// This stylesheet extends/overrides table styles specified in:
// src/Common/styles/components/_table.scss

$table-border: 1px solid $gray-medium;
$table-text-color: #747474;

.data-table-container {
    overflow: hidden;

    .loading-data {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        margin-left: -30px;
    }
}

// Added to white-container to allow table to expand to full width of container
.no-padding-container {
    padding: 0 !important;
}

.data-table-container table {
    border-top: $table-border;
    border-bottom: $table-border;
    border-left: none !important;
    border-right: none !important;
}

.data-table-container .table-pagination {
    text-align: center;
    padding-bottom: $default-padding;
    overflow: hidden;

    .limit {
        width: auto;
        margin-left: $default-padding;
        padding-right: $default-padding * 2;
    }

    .table-current-page {
        width: auto;
        text-align: center;
        margin: 0px math.div($default-margin, 1.8);
    }

    button,
    .pages {
        display: inline-block;
        color: $cf-blue;
    }

    button {
        text-transform: inherit;
        background-color: transparent;
        border: none;

        &.prev {
            i {
                margin-right: $default-margin * 0.5;
            }
        }

        &.next {
            i {
                margin-left: $default-margin * 0.5;
            }
        }
    }
}

.table-cell-content {
    position: relative;
    z-index: 2;
    word-break: break-word;
}

.table-cell-loading-container {
    position: absolute;
    top: 0;
    left: $default-padding * 0.5;
    bottom: 0;
    right: $default-padding;
    max-width: 550px;
    z-index: 0;
}

.table-cell-loading {
    background-color: #ddd;
    position: absolute;
    top: math.div($default-padding, 1.2);
    bottom: math.div($default-padding, 1.4);
    left: $default-padding;
    right: $default-padding;
    border-radius: $default-radius;
    animation: AnimationOpacity 1.5s ease-in-out infinite;
    transform-origin: 0 0;
    overflow: hidden;

    .table-cell-loading-glare {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: Animation 1.5s ease-in-out infinite;

        &:after {
            content: '';
            display: block;
            height: 100%;
            width: 25px;
            background: rgba(255, 255, 255, 0.5);
            box-shadow: 0px 0px 15px 15px rgba(255, 255, 255, 0.5);
        }
    }
}

@keyframes AnimationOpacity {
    0% {
        opacity: 0.2;
        transform: scaleX(1);
    }

    50% {
        opacity: 0.6;
        transform: scaleX(0.95);
    }

    100% {
        opacity: 0.2;
        transform: scaleX(1);
    }
}

@keyframes Animation {
    0% {
        transform: translateX(-100px);
    }

    100% {
        transform: translateX(250%);
    }
}
