@mixin transition($duration, $target: all, $timing-function: ease) {
    transition-property: #{$target};
    transition-duration: $duration;
    transition-timing-function: #{$timing-function};
}

@mixin bottom-triangle($width, $height, $color) {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -($width * 0.5);
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: $height ($width * 0.5) 0 ($width * 0.5);
    border-color: $color transparent transparent transparent;
    -webkit-transform: rotate(360deg);
}

/// Stacks elements horizontally,
/// (converts elements to inline-block and applies default margin)
@mixin stack-horizontal($columns) {
    width: math.div(100%, $columns);
    display: inline-block;
    box-sizing: border-box;
    padding: $default-padding;
    padding-right: 0;
    padding-left: $default-padding;
    &:first-child {
        padding-left: 0;
    }
}

@mixin hyphenate() {
    /* Needed for old IE support, but words are broken up letter-by-letter */
    -ms-word-break: break-all;
    word-break: break-all;

    /* Non standard for webkit */
    word-break: break-word;

    /* Modern CSS3 Hyphenation with locale support */
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

@mixin prevent-selection() {
    // @see: http://stackoverflow.com/questions/880512/prevent-text-selection-after-double-click
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
}

@mixin fullscreen() {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
}

/// Base container for white widgets.
@mixin white-container() {
    box-sizing: border-box;
    border: none;
    border-radius: $default-radius;
    padding: 0;
    > .title {
        @include OpenSans-SemiBold();
        font-size: 10.5pt;
        width: 100%;
        border-bottom: $default-border;
        padding: $default-padding;
        margin: 0;
    }
    .content {
        padding: $default-padding * 1.5;
        overflow-x: auto;
    }

    background: $color-white;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05);
    color: $gray-darker;
}

// Fonts
@mixin OpenSans-Regular() {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-weight: 400;
}

@mixin OpenSans-Italic() {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-style: italic;
    font-weight: 400;
}

@mixin OpenSans-SemiBold() {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-weight: 600;
}

@mixin OpenSans-Bold() {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-weight: 700;
}

@mixin center-vertical($percentage: 50%) {
    position: relative;
    top: $percentage;
    transform: translateY(-$percentage);
}

// Animations

@mixin animation-pulse($animationName, $color) {
    @keyframes #{$animationName} {
        0% {
            box-shadow: 0 0 0 0 transparentize($color, 0.5);
        }
        70% {
            box-shadow: 0 0 0 8px transparentize($color, 1);
        }
        100% {
            box-shadow: 0 0 5px 0 transparentize($color, 1);
        }
    }
}

@mixin card() {
    box-sizing: border-box;
    border: none;
    border-radius: $default-radius;
    padding: 0;
    > .title {
        @include OpenSans-SemiBold();
        font-size: 10.5pt;
        width: 100%;
        border-bottom: $default-border;
        padding: $default-padding;
        margin: 0;
    }
    .content {
        padding: $default-padding * 1.5;
        overflow-x: auto;
    }

    background: $color-white;
    box-shadow: $card-shadow;
    color: $gray-darker;
}

@mixin far-icon($glyph) {
    content: $glyph;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
}

@mixin input-base() {
    @include transition(0.2s, 'border-color, background-color');
    -webkit-appearance: none; // Fix iOS Styling
    font-size: $default-font-size;
    padding: $input-padding;
    position: relative;
    border-radius: $default-radius;
    background: $color-white;
    color: $text-dark;
    box-sizing: border-box;
    border: $default-border;
    outline: none;
    height: auto;
    line-height: normal;
    width: 100%;
    @include OpenSans-Regular();
    border-radius: 2px;
    padding: math.div($default-padding, 1.7) $default-padding * 0.8;
    font-size: 10.5pt;
    color: #7b7b7b;
    text-align: left;

    &:focus {
        border-color: $color-blue-highlight;
        outline: none;
    }

    &:disabled {
        background: $gray-light;
        color: $text-medium;
        border-color: $gray-border;
    }
}

// Compatibility with Fontawesome 5
@mixin fa {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
}
