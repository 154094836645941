@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
$card-shadow-dark-blue: 0 0px 0px 1px transparentize($color-blue-main, 0.7),
    0 2px 2px 0 transparentize($color-blue-main, 0.8),
    0 8px 12px 0 transparentize($color-blue-main, 0.9);

.casefile-documents {
    position: relative;
    margin: -$default-margin * 2;
    margin-top: 0;
    margin-bottom: 0;
    background-color: #f0f4f7;

    &-container {
        padding: $default-padding * 1.25;
    }
}

.casefile-document {
    display: inline-block;
    vertical-align: top;
    position: relative;
    @include white-container();
    padding: $default-padding;
    box-shadow: $card-shadow;
    margin: math.div($default-margin, 1.5);
    width: 190px;
    @include transition(0.2s, all, ease-in-out);

    &-thumbnail {
        position: relative;
        width: 100%;
        height: 180px;
        background: $gray-light;
        border: $default-border;
        background-size: 110%;
        background-position-x: 2%;
        background-position-y: 2%;
    }

    &-title {
        @include OpenSans-Bold();
        color: $text-dark;
        margin-top: $default-margin * 0.25;
        margin-bottom: 0;

        span {
            overflow-wrap: break-word;
        }
    }

    &-type {
        @include OpenSans-SemiBold();
        color: $cf-blue;
        font-size: 90%;
        margin-top: $default-margin * 0.25;
    }

    &-role-name {
        color: $cf-blue;
        @include OpenSans-SemiBold();
        text-transform: capitalize;
    }

    &-status {
        display: inline-block;
        font-size: 8.5pt;
        font-weight: bold;
        border-radius: $default-radius;
        padding: math.div($default-padding, 6) $default-padding * 0.5;
    }

    &-page-count {
        position: absolute;
        right: $default-padding;
        bottom: $default-padding * 1.2;
        font-size: 9pt;
    }
}

.casefile-document-thumbnail-icon {
    @include center-vertical();
    @include prevent-selection();
    text-align: center;
    font-size: 18pt;

    &-description {
        font-size: 9.5pt;
    }
}

.casefile-document:hover {
    cursor: pointer;
    transform: translateY(-4px);
    box-shadow: $card-shadow-dark-blue;
}

.casefile-document-hover-overlay {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 0;
    right: 0;
    z-index: 2;
    text-align: center;
    border-top-right-radius: $default-radius;
    overflow: hidden;
}

.casefile-document-hover-overlay-content {
    @include transition(0.2s, all, 'ease-in-out');
    background: transparentize($color-blue-main, 0.1);
    transform: rotate(45deg) translateX(20px) translateY(-20px);
    position: absolute;
    top: -30px;
    right: -30px;
    opacity: 0;
    height: 60px;
    width: 60px;
    color: $color-white;
    text-align: center;

    i {
        transform: rotate(-45deg);
        line-height: 96px;
        font-size: 9pt;
    }
}

.casefile-document:hover .casefile-document-hover-overlay-content {
    opacity: 1;
    transform: rotate(45deg) translateX(0px) translateY(0px);
}
