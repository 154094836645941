// .avatar-circle {
//     text-transform: uppercase;
//     display: inline-block;
//     vertical-align: top;
//     color: $color-white;
//     // font-size: 9pt;
//     font-weight: bold;
//     padding: 2em;
//     // height: 1.5em;
//     // width: 1.5em;
//     // margin-right: $default-margin;
//     // line-height: 34px;
//     // height: 36px;
//     // width: 36px;
//     text-align: center;
//     border-radius: 50%;
//     border: 2px solid $color-white;

//     .avatar-circle-text {
//         position: absolute;
//         top: 0;
//     }
// }

.av-pink {
    background: #c5009f;
}

.av-orange {
    background: #ffa128;
}

.av-turquoise {
    background: #10bac2;
}

.av-indigo {
    background: #00025f;
}

.av-gray {
    color: $gray-darker;
    background: $gray-medium;
}
