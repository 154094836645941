.batch-creation {
    .document-preview-images {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
    }

    .preview-document {
        position: relative;
        height: 100%;
        img {
            height: 100%;
            margin-right: $default-padding;
            border: 1px solid $gray-border;
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .batch-creation-header {
        position: relative;
    }
}

.batch-field-mapping {
    min-width: 140px;
}

.csv-table {
    width: 100%;
    overflow-x: scroll;

    &.shadow-right {
        box-shadow: inset -30px 0px 50px -25px rgba(0, 0, 0, 1);
    }

    &.shadow-left {
        box-shadow: inset 30px 0px 50px -25px rgba(0, 0, 0, 1);
    }

    &.shadow-both {
        box-shadow: inset 30px 0px 50px -25px rgba(0, 0, 0, 1),
            inset -30px 0px 50px -25px rgba(0, 0, 0, 1);
    }
}

.csv-table-warning {
    text-align: center;
    color: $text-medium;
    font-weight: 700;
}

.clearfix {
    overflow: auto;
    zoom: 1;
    clear: both;
}
