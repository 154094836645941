@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
$header-color: #142d43;

.casefile-eventlog-container {
    border-radius: $default-radius;
    box-shadow: $card-shadow;

    .casefile-eventlog {
        overflow: auto;
        max-height: 380px;
    }
}

.casefile-eventlog-headers {
    > div {
        display: inline-block;
    }

    @include OpenSans-SemiBold();
    color: $header-color;
    border-bottom: $default-border;
    background-color: #f0f4f7;
    z-index: 2;

    .row {
        padding: $default-padding;
        padding-left: $default-padding * 1.5;
    }
    .column {
        display: inline-block;
    }

    .column-1 {
        width: 168px;
    }
}

.casefile-eventlog-entry {
    padding: $default-margin 0;
    padding-left: $default-margin * 0.5;

    &.green {
        .casefile-eventlog-entry-icon {
            border-color: $penneo-green;
            border-width: 2px;
            color: $penneo-green;
        }
    }

    &.red {
        .casefile-eventlog-entry-icon {
            border-color: $color-error;
            border-width: 2px;
            color: $color-error;
        }
    }

    &.blue {
        .casefile-eventlog-entry-icon {
            border-color: $cf-blue;
            border-width: 2px;
            color: $cf-blue;
        }
    }

    &:last-child {
        .casefile-eventlog-entry-icon:after {
            display: none;
        }
    }

    > div {
        display: inline-block;
        vertical-align: middle;
        margin-right: $default-margin;
    }

    .user {
        font-weight: bold;
        color: $color-blue-main;
    }

    &-timestamp {
        margin-left: $default-margin;
        font-weight: normal;
        font-size: 90%;
        color: $color-blue-main;
        @include OpenSans-SemiBold();
    }

    .email {
        font-weight: normal;
        font-size: 90%;
        color: $color-blue-main;
    }

    &-icon {
        position: relative;
        height: 40px;
        width: 40px;
        background: $color-white;
        border: 1px solid $header-color;
        border-radius: 50%;
        text-align: center;

        i {
            position: relative;
            display: block;
            @include center-vertical();
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            top: 100%;
            width: 1px;
            height: $default-margin * 2.5;
            background: transparentize($header-color, 0.7);
        }
    }

    &-description {
        .email-subject-link {
            @include OpenSans-SemiBold();
            font-size: 90%;

            &:hover {
                color: $cf-blue;
            }
        }
    }

    &-details {
        position: relative;
        top: -2px;
    }

    &-time-relative {
        font-size: 80%;
    }
}
