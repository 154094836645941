// Set the modal at 20% of the top of the viewport.
// According to good practices, modals should be aligned to top
// instead of in the middle.
.waiting-modal {
    text-align: center;
    color: #666;
    transform: translate3d(-50%, -20%, 0) !important;
    top: 20% !important;
}

// @note: this overrides the values set by Boron in the classless
// div that takes the styling for the modal.
.waiting-modal > div {
    background: $color-white !important;
    padding: 1em !important;
    border-radius: $default-radius !important;
}

.modal-text {
    border-top: 1px solid $gray-border;
    // border-bottom: 1px solid $gray-border;
    margin: $default-margin * 0.5 0;
    padding: $default-padding * 0.5 0;
}

.modal-buttons {
    button {
        margin: 0 $default-margin * 0.5;
    }
}

.modal-header {
    position: relative;
    width: 100%;

    .modal-title {
        margin-top: 0;
    }

    .modal-close {
        position: absolute;
        right: 0;
        top: 0;
        &:hover {
            color: $color-error;
        }
    }
}

// Set the modal at 20% of the top of the viewport.
// According to good practices, modals should be aligned to top
// instead of in the middle.
.waiting-modal {
    text-align: center;
    color: #666;
    transform: translate3d(-50%, -20%, 0) !important;
    top: 20% !important;
}

// @note: this overrides the values set by Boron in the classless
// div that takes the styling for the modal.
.waiting-modal > div {
    background: $color-white !important;
    padding: 1em !important;
    border-radius: $default-radius !important;
}

.modal-text {
    border-top: 1px solid $gray-border;
    // border-bottom: 1px solid $gray-border;
    margin: $default-margin * 0.5 0;
    padding: $default-padding * 0.5 0;
}

.modal-buttons {
    button {
        margin: 0 $default-margin * 0.5;
    }
}

.loading-wrapper {
    position: relative;
    top: 50px;
}
