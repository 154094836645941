@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.case-files-item-modal {
    label {
        float: left;
    }
    input {
        width: 100%;
        margin-bottom: $default-margin;
    }

    .default {
        border: 1 solid $gray-medium;
        float: left;
    }

    .success,
    .info {
        float: right;
    }
}
