.casefiles-error-page-wrapper {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;

    .error-content-wrapper {
        width: 40%;
        height: 60%;
        top: 20%;
        left: 30%;
        position: relative;
    }

    .error-image {
        width: 100%;
        height: 60%;
        text-align: center;
        img {
            height: 100%;
        }
    }

    .error-message {
        width: 100%;
        height: 40%;
        text-align: center;
        h1 {
            font-size: 2em;
            color: #7a7a7c;
        }
        h2 {
            color: #7a7a7c;
        }
    }
}
