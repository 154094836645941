@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.autocomplete-tags {
    &__control {
        @include input-base();
        @apply bg-white
                rounded-sm;
    }

    &__control--is-focused {
        @apply border
            border-solid
            border-secondary-600;
    }

    &__value-container {
        @apply gap-1;
    }

    &__option {
        @apply px-5
                py-2;
    }

    &__option:hover,
    &__option:focus-visible,
    &__option:focus,
    &__option-is-selected,
    &__option--is-focused {
        @apply cursor-pointer
                text-white
                bg-secondary-600;
    }

    &__noOptionsMessage {
        @apply p-2;
    }

    &__menu {
        @apply border
            border-solid
            border-neutral-300
            rounded
            bg-white
            overflow-x-hidden
            mt-0.5
            shadow-md;
    }

    &__menu-notice--no-options {
        @apply px-2
                py-4;
    }

    &__multi-value {
        @apply text-white
                bg-secondary-600
                p-1
                rounded-sm;

        &__label {
            @apply px-2
                    py-0;
        }

        &__remove:hover,
        &__remove:focus-visible {
            @apply cursor-pointer
                    text-neutral-700;
        }
    }

    &__indicator:hover,
    &__indicator:focus-visible {
        @apply cursor-pointer
                text-secondary-600;
    }
}
