.penneo-header-layout {
    .casefile-access-denied {
        margin: $default-margin;
    }
}

$error-image-width: 350px;
$error-image-width-medium: 200px;

.casefile-access-denied {
    @extend .white-container;
    border-radius: $default-radius;
    overflow: hidden;
    min-height: 350px;
    height: auto;
    position: relative;

    h1 {
        margin-top: 0;
    }

    .container {
        position: relative;
        margin-right: $error-image-width;
        padding: $default-padding;
    }

    &.small {
        .container {
            width: 100%;
            position: relative;
        }
        .error-image {
            width: 100%;
            position: relative;
            overflow: hidden;
            height: 70px;
            background-position: -10% 40%;
            text-align: left;
            padding: 0 $default-padding;
            h1 {
                float: right;
                margin: 0;
                line-height: 70px;
                font-size: 16pt;
                display: inline-block;
            }
            h2 {
                margin: 0;
                line-height: 70px;
                display: inline-block;
            }

            #locked-document {
                display: none;
            }
        }
    }

    &.medium {
        .container {
            margin-right: $error-image-width-medium;
        }
        .error-image {
            width: $error-image-width-medium;
            background-position: 50%;
        }
    }

    .error-image {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: $error-image-width;
        background-size: cover;
        background-repeat: no-repeat;
        text-align: center;
        color: #fff;

        * {
            position: relative;
            z-index: 2;
        }

        h1 {
            margin-top: $default-margin * 1.5;
            font-size: 2em;
        }
    }

    .overlay {
        background: transparentize($color-info, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
}

#locked-document {
    display: inline-block;
    position: relative;
    height: 130px;
    width: 100px;
    z-index: 9999;
    text-align: center;
    color: #fff;

    .fa {
        position: relative;
        color: $color-info;
        top: 20px;
        font-size: 40px;
    }

    img {
        position: absolute;
        width: 180px;
        left: 50%;
        margin-left: -79px;
        top: 60px;
    }

    .animated-document {
        @include transition(0.3s);
        height: 130px;
        width: 100px;
        left: 50%;
        margin-left: -50px;
        background: #fff;
        border-radius: 2px;
        position: absolute;
        box-sizing: border-box;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
        z-index: 2;
    }

    .stack-1 {
        top: -7px;
        z-index: 0;
        transform: rotate(-5deg) translate(0px, 0px);
    }

    .stack-2 {
        top: -5px;
        z-index: 0;
        transform: rotate(5deg) translate(0px, 0px);
    }

    .line {
        position: relative;
        display: block;
        height: 2px;
        top: 35px;
        width: 70%;
        margin: 0 auto;
        background: #999;
        border-radius: 2px;
        box-sizing: border-box;
        animation: documentLines 3s infinite;
    }

    .line-2 {
        top: 45px;
        animation-delay: 1s;
    }

    .line-3 {
        top: 55px;
        animation-delay: 0.5s;
    }

    .line-4 {
        top: 65px;
        animation-delay: 0.2s;
    }

    @keyframes documentLines {
        0% {
            transform: scale(1, 1);
        }
        50% {
            transform: scale(0.8, 0.8);
        }
    }

    &:hover {
        .stack-1 {
            transform: rotate(-10deg) translate(-5px, 0px);
        }

        .stack-2 {
            transform: rotate(10deg) translate(5px, 5px);
        }
    }
}
