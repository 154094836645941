.activate-user {
    background: $color-white;
    border-radius: $default-radius * 2;
    box-shadow: $card-shadow;
    padding: $default-padding;
    padding-bottom: $default-padding * 2;
    text-align: center;

    .integration-form {
        .input,
        input {
            max-width: none;
        }
    }

    .welcome-message {
        h3 {
            margin-top: $default-margin * 3;
            padding: 0 $default-padding * 4;
            margin-bottom: $default-margin * 2;
        }
    }

    .method-list {
        text-align: left;
        background: $gray-light;
        border: $default-border;
        margin: 0 $default-padding * 4;
        padding: $default-padding;
        border-radius: $default-radius;

        li {
            padding: $default-padding * 0.5 0;
        }
        .tag {
            background: $color-info;
            padding: $default-padding * 0.2 $default-padding * 0.5;
            border-radius: $default-radius;
            font-size: 0.9em;
            display: inline-block;
            color: $color-white;
            margin-right: $default-margin * 0.5;
        }

        .title {
            font-weight: bold;
        }

        .fa-check {
            padding-left: $default-padding * 0.5;
            color: $color-success;
        }
    }

    .auth-method-content .auth-method-password,
    .bankid-reset-container,
    .password-reset-form {
        margin: 0 auto;
    }

    .password-reset-form {
        max-width: 400px;
        border: $default-border;
        padding: $default-padding;
        border-radius: $default-radius;
    }
}

.activate-user__footnote {
    color: $color-white;
}

.activate-user__footnote__icon {
    margin-right: $default-padding * 0.5;
}
