@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.kyc-requirement-item {
    width: 480px;
    margin-bottom: $default-margin * 0.5;

    padding: $default-padding;
    border: $default-border;
    border-radius: $default-radius;

    .inline-block {
        display: inline-block;
        vertical-align: middle;
    }

    &-checkbox {
        padding-bottom: 0 !important;
    }

    &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-icon {
        color: $color-blue-main;
        display: inline-block;
    }

    &-title {
        font-weight: bold;
        margin-left: $default-margin * 0.5;
        color: $color-blue-main;
        display: inline-block;
    }
}
