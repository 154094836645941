@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
$card-shadow-blue: 0 0px 0px 1px transparentize($cf-blue, 0.2),
    0 2px 2px 0 transparentize($cf-blue, 0.8),
    0 4px 12px 0 transparentize($cf-blue, 0.7);

.modal-create-new-title {
    text-align: center;
    padding-top: 10em;
    h1,
    p {
        margin: 0;
    }

    p {
        margin-top: $default-padding * 0.5;
    }

    @media #{$breakpoint-medium} {
        padding-top: 2em;
    }
}

.modal-create-new-content {
    padding-top: $default-padding * 2;
}

.modal-create-new-options {
    display: flex;
    justify-content: center;

    @media #{$breakpoint-medium} {
        display: block;
    }
}

.modal-create-new-option {
    @include card();
    @include transition(0.15s);
    cursor: pointer;
    display: flex;
    width: 200px;
    padding: $default-padding;
    padding-top: $default-padding * 4;
    border: $default-border;
    border-radius: $default-radius;
    vertical-align: top;
    margin: 0 $default-margin * 0.5;
    flex-direction: column;

    &:hover {
        box-shadow: $card-shadow-blue;
        border-color: transparent;
        text-decoration: none;

        h3 {
            color: $cf-blue;
        }
    }

    @media #{$breakpoint-medium} {
        width: 98%;
        margin-bottom: $default-margin;
        height: auto;
        position: relative;
        text-align: left;
        padding: 0;

        img {
            text-align: center;
            position: absolute;
            top: $default-margin * 1.3;
            left: $default-margin;
            height: 50px;
        }

        h3,
        p {
            margin: 0;
            margin-left: 80px;
        }

        h3 {
            margin-top: $default-margin * 1.5;
        }

        p {
            margin-bottom: $default-margin * 1.5;
        }

        i {
            display: block;
            margin-bottom: $default-margin * 1.5;
            margin-left: 80px;
        }

        button {
            display: none;
        }
    }
}

.modal-create-new-option-content {
    flex-grow: 1;
}
