$header-title-size: 1.2em;

.login-container-header {
    text-align: center;
    width: 100%;
    margin-bottom: $default-margin;
    .title {
        color: $text-medium;
        font-weight: normal;
        font-size: $header-title-size;
        padding: $default-padding * 0.25 0;
        margin: 0;
    }

    .back {
        position: absolute;
        top: math.div($default-padding, 0.5);
        left: $default-padding;
    }
}
