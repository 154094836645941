.signer-component-wrapper {
    .signer-information-button {
        padding-bottom: 0.5em;
    }

    .signer-table-wrapper {
        min-width: 100%;
        overflow-x: auto;

        .signer-table {
            display: inline-block;
            position: relative;
            box-sizing: border-box;
            width: 100%;
            font-family: $lato;
            border-collapse: collapse;
            border: $default-border;
            display: inline-block;

            thead {
                color: $gray-darker;

                th {
                    border-bottom: $default-border;
                    padding: $default-padding 0;
                    padding-left: $default-padding;
                    text-transform: capitalize;
                    text-align: left;
                    cursor: pointer;
                    background: $gray-light;
                    padding: 0.3em;
                }

                th:first-child {
                    padding-left: math.div($default-padding, 1.5);
                }

                th:nth-child(3) {
                    padding-left: 0em;
                    padding-right: 0.3em;
                    width: 15%;
                }

                th:nth-child(4) {
                    width: 12%;
                }

                th:nth-child(5) {
                    width: 12%;
                }

                th:nth-child(6) {
                    width: 12%;
                }

                th:nth-child(7) {
                    width: 6%;
                    padding-left: 0em;
                }

                th:nth-child(8) {
                    width: 5%;
                }

                th:nth-child(9) {
                    width: 2%;
                }
            }

            tbody {
                background: $color-white;
                color: $gray-dark;

                td:first-child {
                    padding-left: math.div($default-padding, 1.5);
                }

                td {
                    border-bottom: $default-border;
                    padding: 1em 0.25em;
                    vertical-align: top;

                    &.align-middle {
                        padding-top: $default-padding * 1.7;
                    }
                }
            }

            input,
            select {
                width: 100%;
                padding: math.div($default-padding, 1.4) $default-padding * 0.5;
            }

            button {
                width: 100%;
                font-size: 80%;
                height: 39px;
            }

            .ssn-input-container {
                gap: 5px;
            }

            input[name='ssn'] {
                width: auto;
                max-width: 115px;
            }
        }
    }
}
