@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.duration-input {
    &-number-input {
        display: inline-block;
    }
    &-select {
        display: inline-block !important;
        width: auto !important;
        padding: math.div($default-padding, 3.6) $default-padding !important;
        padding-right: $default-padding * 2 !important;
        border: $default-border;
    }
}
