@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.user-integrations-container {
    min-height: 250px;
    max-height: 600px;
    overflow: auto;

    border: $border-light;
    padding: $default-padding;

    .user-integration {
        display: inline-block;
        width: 70%;

        img {
            vertical-align: middle;
            max-height: 60px;
            max-width: 60px;
        }

        &-details {
            display: inline-block;
            vertical-align: middle;
            padding-left: $default-padding;

            .name {
                margin-top: 0;
                margin-bottom: -$default-margin * 0.5;
                font-weight: bold;
                padding-bottom: $default-padding * 0.5;
            }

            .integration-status {
                &-active {
                    i {
                        color: $color-success;
                    }
                }

                &-inactive {
                    i {
                        color: $color-error;
                    }
                }

                &-unknown {
                    i {
                        color: $gray-darker;
                    }
                }
            }

            .integration-message {
                color: $penneo-orange;
            }
        }

        &-actions {
            display: inline-block;
            vertical-align: middle;
            text-align: right;
            width: 30%;

            .confirmation-question {
                text-align: center;
            }

            button {
                margin-right: $default-margin * 0.5;
            }
        }
    }
}
