@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.account-setup-error {
    padding: $default-padding * 2;
    padding-top: $default-padding * 5;

    p {
        padding: $default-padding * 0.5 $default-padding * 2;
    }

    .error-code {
        color: $text-medium;
        position: absolute;
        text-align: center;
        padding: 0;
        margin: 0;
        bottom: 0;
        right: 0;
        left: 0;
        padding-bottom: $default-padding * 2;
    }

    &-back-link {
        @include OpenSans-SemiBold();
        position: absolute;
        top: $default-padding * 2;
        left: $default-padding * 2;
    }
}
