.tab-documents {
    .document-preview {
        img {
            width: 100px;
            border: 1px solid $gray-light;
            border-radius: $default-radius;
            margin-right: $default-margin * 0.5;
        }
    }
}
