.workflow-comments {
    padding: 0 $default-padding;

    ul {
        li {
            background: $gray-light;
            border-radius: $default-radius;
            margin-bottom: $default-margin;
            padding: $default-padding * 0.5;
            box-sizing: border-box;
            .timestamp {
                display: block;
                font-weight: 700;
                text-align: right;
                font-size: 0.8em;
                margin-bottom: $default-margin * 0.25;
                padding-bottom: $default-padding * 0.25;
                border-bottom: 1px solid $gray-border;
            }
        }
    }
}

.add-comments {
    input {
        margin-bottom: $default-margin * 0.5;
    }
}
