.team {
    &-child,
    &-parent {
        @include item;
        position: relative;
        background: $color-white;
        min-height: 90px;
        // border-radius: $default-radius*2;
        margin-top: $default-margin * 1;

        &-name {
            color: $color-info;
            font-size: 12pt;
            cursor: pointer;
            margin-bottom: $default-margin * 0.2;

            &:hover {
                color: $color-blue-highlight;
            }
        }

        .member-count {
            position: absolute;
            top: $default-margin;
            right: $default-margin;
        }

        .member-group {
            margin-top: $default-margin * 0.5;
            display: inline-block;

            .member {
                position: relative;
                display: inline-block;
                margin-left: -($default-margin * 0.5);

                &:hover {
                    z-index: 100 !important;
                }

                &:first-child {
                    margin-left: 0;
                }
            }

            .more-members {
                margin-left: $default-margin * 0.25;
                display: inline-block;
                color: $gray-dark;
            }
        }
    }

    .highlight {
        box-shadow: 0px 1px 5px transparentize($color-blue-highlight, 0.5);
        border-color: transparentize($color-blue-highlight, 0.5);
    }

    &-child {
        margin-left: $default-margin * 2;

        &:before {
            overflow: hidden;
            height: 150%;
            z-index: 1;
            content: '';
            width: 1px;
            background: $cf-blue;
            position: absolute;
            left: -$default-margin;
            top: -100%;
        }

        &:after {
            z-index: 1;
            content: '';
            height: 1px;
            background: $cf-blue;
            position: absolute;
            width: $default-margin;
            left: -$default-margin;
            top: 65%;
            margin-top: -$default-margin;
        }
    }

    &-child:nth-child(2) {
        &:before {
            height: 65%;
            top: -$default-margin;
        }
    }
}
