.user-picker {
    position: relative;

    &-filter {
        margin-bottom: $default-margin;
        text-align: center;
        width: 100%;
        margin-top: $default-margin;
    }

    &-list {
        border: $default-border;
        border-radius: $default-radius;
        height: 360px;
        overflow: scroll;
        margin-bottom: $default-margin;
        width: 100%;

        &-item {
            padding: $default-padding;
            position: relative;
            vertical-align: middle;

            &:hover {
                box-shadow: $card-shadow;
            }

            &-avatar {
                display: inline-block;
                margin-right: $default-margin;
            }

            &-details {
                display: inline-block;
                position: relative;
                margin-top: -0.25em;
                vertical-align: middle;

                &-name {
                    color: $cf-blue;
                }
            }

            .checkbox {
                top: -$default-padding * 0.5;
                position: relative;
            }
        }
    }

    &-list-empty {
        text-align: center;
        padding: $default-padding;
    }
}
