@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.image-uploader {
    border: $default-border;

    .image-present {
        padding: $default-padding;
        vertical-align: middle;
        display: flex;
        flex-direction: row;
        align-items: center;

        .preview-image img {
            max-width: 160px;
            max-height: 120px;
            border: $default-border;
            padding: 4px;
        }

        .preview-description {
            padding-left: $default-padding;
            flex: 1;

            h4 {
                color: $text-dark;
                margin: 0;
            }

            p {
                margin: 0;
            }

            .file-size {
                margin-top: $default-margin * 0.25;
                font-size: 85%;
                font-weight: 600;
            }
        }

        .actions {
            display: flex;
            flex-direction: column;

            .button:not(:last-child) {
                margin-bottom: $default-margin * 0.5;
            }
        }
    }

    .no-image {
        padding: $default-padding;
        vertical-align: middle;
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon {
            color: $text-dark;
            text-align: center;
            vertical-align: middle;
            display: inline-block;

            i {
                font-size: 2em;
            }
        }

        .description {
            flex: 1;
            display: inline-block;
            color: $text-dark;
            padding-left: $default-padding * 0.5;
        }

        .actions {
            display: inline-block;
            text-align: right;
        }
    }
}
