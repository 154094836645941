.casefile-details-table-container {
    width: 100%;
    position: relative;
}

.casefile-details-container {
    .button-container {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 1em;
        width: 100%;
    }
}
.casefile-details-info {
    padding: 0 $default-padding;
    margin-bottom: 0;
}
.casefile-details-table-container {
    position: relative;
    width: 100%;
    overflow: auto;
    padding: $default-padding;

    .table {
        background-color: $color-white;
        tr {
            td,
            th {
                height: 46px;
                white-space: nowrap;
                text-align: center;
                max-width: 170px;
            }
        }

        th,
        td {
            border: $default-border;
            text-align: left;
            padding: $default-padding * 0.5;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .odd-highlight {
            tr:nth-child(odd) {
                background-color: $gray-light;
            }
        }

        .even-highlight {
            tr:nth-child(even) {
                background-color: $gray-light;
            }
        }
    }

    .table-sidebar {
        float: left;
    }

    .table-body {
        overflow: auto;
        position: relative;
        overflow-x: auto;
    }
}
