%container {
    .container-header {
        position: relative;
        width: 100%;
        padding-bottom: $default-padding;
        box-sizing: border-box;
        .content-right {
            position: absolute;
            top: 0;
            right: 0;
            padding-bottom: $default-padding;
            vertical-align: top;
        }
    }
}

.white-container {
    @include white-container();
    @extend %container;
}

.pl {
    padding-left: $default-padding;
}

.pr {
    padding-right: $default-padding;
}

.pb {
    padding-bottom: $default-padding;
}

.pt {
    padding-top: $default-padding;
}

.ml {
    margin-left: $default-padding;
}

.mr {
    margin-right: $default-padding;
}

.mb {
    margin-bottom: $default-padding;
}

.mt {
    margin-top: $default-padding !important;
}

.tabs-layout {
    .tab-header {
        border-bottom: $default-border;
        ul {
            margin: 0;
            padding: 0;
        }

        li {
            cursor: pointer;
            position: relative;
            margin: 0;
            font-weight: 600;
            display: inline-block;
            box-sizing: border-box;
            a {
                display: inline-block;
                padding: $default-margin;
                opacity: 0.6;
                &.active {
                    opacity: 1;
                    color: $color-blue-highlight;

                    &:after {
                        display: block;
                        content: '';
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        width: 100%;
                        height: 4px;
                        background: $color-blue-highlight;
                    }
                }
            }
        }
    }

    .tab-layout-content {
        padding: $default-padding * 1.5;

        h3:first-child {
            margin-top: 0;
        }

        &.no-padding {
            padding: 0;
        }
    }
}

// For floating elements. Previously added by Fontawesome V4
.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.pull-right-flex {
    display: flex;
    justify-content: flex-end;
}
