@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
$button-size: $default-padding * 0.5 $default-padding * 1.6;
$button-radius: 8px;

$color-itsme-tangerine: #ff4612;
$color-itsme-dark: #2d3131;
$color-itsme-border: #e4e8e1;

.itsme-button {
    @include transition(
        0.2s,
        'background-color, border-color, color, transform'
    );
    @include OpenSans-Bold();
    outline: 0;
    position: relative;
    border-radius: $button-radius;
    border: 1px solid transparent;
    font-size: 14px;
    background-color: transparent;
    padding: $button-size;
    white-space: nowrap;
    text-transform: none;
    text-align: center;
    cursor: pointer;

    // Icons in buttons.
    .itsme-button-icon {
        font-size: 13pt;
        vertical-align: middle;
        margin-right: $default-margin * 0.5;
    }

    .itsme-button-text {
        position: relative;
        top: 2px;
    }

    // Button states
    &:active {
        transform: scale(0.98, 0.98);
    }

    &:disabled {
        &:active {
            transform: none;
        }

        opacity: 0.5;
    }
}

.button-full-width {
    width: 100%;
}

// Button themes / variants
.itsme-button-tangerine {
    background-color: $color-itsme-tangerine;
    color: #ffffff;

    &:hover:not(:disabled) {
        background-color: darken($color-itsme-tangerine, 5);
    }
}

.itsme-button-black {
    background-color: $color-itsme-dark;
    color: $color-white;

    &:hover:not(:disabled) {
        background-color: darken($color-itsme-dark, 5);
    }
}

.itsme-button-white {
    border-color: $color-itsme-border;
    background-color: $color-white;
    color: $color-itsme-dark;

    &:hover:not(:disabled) {
        background-color: darken($color-white, 5);
    }
}
