.casefile-document-information,
.casefile-copy-recipients {
    .section-content {
        position: relative;
        .table {
            background: $color-white;
            height: 246px;
            overflow-y: auto;
            display: inline-block;
            width: 100%;

            thead {
                width: 100%;
            }

            th {
                background: $gray-light;
                padding-left: $default-padding * 0.25;
            }

            .empty-table-message {
                height: 100%;
            }

            .single-row {
                width: 100%;
                border: none;
            }

            td {
                padding-right: 0;
                padding: math.div($default-padding, 3);
                border-bottom: none;
                &:last-child {
                    padding-right: $default-padding * 0.5;
                }
            }

            .reorder-cell,
            .reorder-column {
                .order {
                    position: relative;
                    left: 4px;
                    font-size: 0.8em;
                    padding: $default-padding * 0.25;
                }

                padding: $default-padding * 0.5 0;

                + td {
                    padding-left: math.div($default-padding, 6);
                }

                + th {
                    padding-right: 0;
                    padding-left: $default-padding * 0.2;
                }
            }

            .actions {
                i {
                    display: inline;
                    padding-right: $default-padding * 0.5;
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }

            .reorder-cell {
                cursor: move;
                i {
                    color: $text-light;
                }
            }

            .reorder-column {
                color: $text-light;
                padding-left: $default-padding;
            }
        }

        .document-count {
            margin: 0;
            padding-top: math.div($default-padding, 1.5);
        }
    }

    .fake-anchor {
        color: #88dab3;
        text-decoration: underline;
        cursor: pointer;
        background: none;
        border: none;
        margin: 0;
        padding: 0;
        &:hover {
            color: #6bd09f;
        }
    }
}

li.dralt {
    width: 100%;
    position: relative;
    padding: math.div($default-padding, 3) 0;

    input[type='text'] {
        width: 60%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    select {
        position: relative;
        left: -1px;
        width: 40%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-color: $gray-medium;
        color: $text-dark;
    }

    .drag-handle {
        cursor: move;
        position: absolute;
        margin-top: math.div($default-margin, 1.5);
        margin-left: $default-margin * 0.5;
        font-size: 90%;
    }

    .options-button {
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -8px; // half the fa-cog icon height;
    }

    .drag-handle {
        opacity: 0.5;
    }
}

.igrp {
    margin: 0 40px;
}

.document-info > ul {
    overflow-y: auto;
    padding: 0;
    margin: 0;
    background: $color-white;
    height: 216px;
    border-radius: $default-radius;
    border: $default-border;
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding-top: $default-padding * 0.5;
    padding-bottom: $default-padding * 0.5;
}

.document-list {
    width: 100%;
    height: 100%;
    input {
        padding: 0;
        margin: 0;
        border: none;
        width: auto;
    }

    li {
        width: 100%;
        border-bottom: $default-border;
        padding: $default-padding * 0.5;
        > * {
            margin: 0;
        }
    }
}

.document-list-header {
    background: $gray-light;
    border: $default-border;
    padding: $default-padding * 0.5 40px;
    border-top-left-radius: $default-radius;
    border-top-right-radius: $default-radius;
    .icon-header {
        font-weight: bold;
        color: $gray-darker;
        position: absolute;
        left: $default-padding;
    }
    .text-headers {
        > span {
            color: $gray-darker;
            font-weight: bold;
            display: inline-block;
        }

        span:first-child {
            width: 60%;
        }
    }
}
