input.editor-field,
textarea.editor-field {
    outline: 0;
    border: none;
    width: 100%;
    transition: none;
    &:focus {
        border: none;
        outline: 0;
    }
    resize: both;
}

.input-editable {
    .bounding-box {
        cursor: move;
    }
    input,
    textarea {
        padding: 2px;
        line-height: 100%;
        background: transparent;
        position: relative;
        height: 100%;
        width: 100%;
    }

    /**
     * This makes an "invisible box" that makes the field draggable
     * But revealing a small corner allowing for resizing the field
     */
    .bounding-box:after,
    .bounding-box:before {
        content: '';
        background: transparent;
        display: block;
        position: absolute;
        z-index: 1;
    }

    .bounding-box:after {
        width: 100%;
        height: calc(100% - 12px);
        left: 0;
        top: 0;
    }

    .bounding-box:before {
        width: calc(100% - 12px);
        height: 100%;
        display: block;
        left: 0;
        bottom: 0;
    }

    .order {
        position: absolute;
        bottom: 98%;
        left: 0%;
        color: $color-blue-highlight;
        z-index: 2;
        background: bisque;
        width: 1.5em;
        padding-top: 0.15em;
        text-align: center;
        height: 1.5em;
    }
}

.move-handle {
    display: block;
    height: 25px;
    width: 25px;
    color: #fff;
    position: absolute;
    left: -25px;
    text-align: center;
    opacity: 0.3;
    line-height: 25px;
    background: $color-blue-highlight;
    border-radius: $default-radius;
    top: -4px;
    &:hover {
        opacity: 1;
    }
}

.resize-handle {
    display: block;
    height: 25px;
    width: 25px;
    color: #fff;
    position: absolute;
    right: -25px;
    text-align: center;
    opacity: 0.3;
    line-height: 25px;
    background: $color-blue-highlight;
    border-radius: $default-radius;
    top: -4px;
    &:hover {
        opacity: 1;
    }
}

.remove-handle {
    display: block;
    height: 25px;
    width: 25px;
    color: #fff;
    position: absolute;
    right: -51px;
    text-align: center;
    opacity: 0.3;
    line-height: 25px;
    background: $color-blue-highlight;
    border-radius: $default-radius;
    top: -4px;
    &:hover {
        opacity: 1;
    }
}
