.form-attachment {
    .title {
        font-weight: bold;
        color: $text-medium;
        margin-bottom: $default-margin * 0.5;
    }

    .upload-area {
        @include transition(0.3s);
        cursor: pointer;
        width: 100%;
        border: $default-border;
        font-size: 92%;
        padding: $default-padding $default-padding;
        border-color: $color-blue-highlight;
        background: $color-blue-highlight;
        color: $color-white;
        border-radius: $default-radius;
        text-align: center;

        .fa {
            margin-left: 0;
        }

        .file {
            display: none;
        }

        &.has-items {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        &:hover {
        }
    }

    .file-list {
        ul {
            margin-top: -1px;
            background: $color-white;
            border: $default-border;
            border-top: none;
            border-bottom-left-radius: $default-radius;
            border-bottom-right-radius: $default-radius;
        }
        ul li {
            font-size: 95%;
            position: relative;
            width: 100%;
            padding: $default-padding * 0.5;
            border-bottom: $default-border;
            color: $text-medium;
            height: 2.5em;

            .remove {
                position: absolute;
                top: math.div($default-padding, 1.5);
                right: $default-padding * 0.5;
                cursor: pointer;

                &:hover {
                    color: $color-error;
                }
            }

            &:last-child {
                border-bottom: none;
            }
        }

        .file-title {
            position: absolute;
            left: $default-padding * 0.5;
            top: math.div($default-padding, 1.5);
            height: 1.2em;
            text-overflow: ellipsis;
            white-space: nowrap;
            // background: green;
            right: $default-padding * 2;
            overflow: hidden;
        }
    }
}
