@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.recipient-suggestion-modal {
    min-width: 50em;
    margin-top: $default-margin;

    .recipient-container {
        border: $default-border;
        border-radius: $default-radius;
        margin-top: $default-margin;
        margin-bottom: $default-margin * 4;

        .recipient-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: $default-padding;
            align-items: center;

            &-name {
                flex: 1;
                padding-left: $default-padding;
                font-weight: bold;
            }

            &-roles {
                flex: 1;
                font-weight: bold;
            }
        }

        .recipient-list {
            max-height: 510px; // Fits 10 items and cuts off at a point where it's visible that there are more items in the list.
            overflow: scroll;

            .recipient {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: $default-padding;
                cursor: pointer;

                &-checkbox {
                    padding: 0;
                }

                &-name {
                    flex: 1;
                    padding-left: $default-padding;
                }

                &-roles {
                    flex: 1;
                }

                &:hover {
                    @include transition(0.2s, all, ease-in-out);
                    background-color: $gray-lighter;
                }
            }
        }
    }
}
