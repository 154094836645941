@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.signing-container-background {
    margin: 76px 0 0 0;
    padding: $default-padding * 2 $default-padding;
    max-width: 1170px;
    min-height: calc(100vh - 312px);
    background: $color-white;
    background-image: url(#{$images}/circle-of-trust-mobile.svg);
    background-position-x: center;
    background-repeat: no-repeat;

    @media #{$breakpoint-medium-min} {
        margin: 64px auto 0 auto;
        min-height: calc(100vh - 239px);
        background-image: url(#{$images}/circle-of-trust.svg);
    }

    .signing-content {
        max-width: 700px;
        margin: 0 auto;

        .penneo-logo {
            height: 120px;
            margin-bottom: $default-margin * 2;
            @media #{$breakpoint-medium-min} {
                height: auto;
                margin-bottom: $default-margin * 3;
            }
        }

        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }

        .title,
        .section-title {
            color: $penneo-blue-default;
            text-align: center;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            margin-top: $default-margin;

            &.about-penneo {
                margin-top: $default-margin * 1.5;
                @media #{$breakpoint-medium-min} {
                    margin-top: $default-margin * 4.5;
                }
            }
        }

        .description,
        .section-description,
        .info-text {
            color: #111827;
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            margin-top: $default-margin;

            &.info-text {
                font-weight: 400;
            }

            &.description,
            &.section-description {
                font-weight: 600;
            }
            &.info-text,
            &.section-description {
                text-align: left;
            }
        }

        .archive-container {
            padding: $default-padding;
            border-radius: 6px;
            background: $color-menu-bg;
            margin-top: $default-margin * 2;
            margin-bottom: $default-margin;
            @media #{$breakpoint-medium-min} {
                margin-top: $default-margin * 4.5;
            }
            .archive-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;

                .archive-info {
                    display: flex;
                    align-items: flex-start;
                    align-self: stretch;
                    padding-left: $default-margin;
                    margin-bottom: $default-margin;

                    img {
                        margin-top: $default-margin * 0.25;
                    }
                }

                .archive-text {
                    flex: 1 0 0;
                    color: #fff;
                    margin-left: $default-margin * 0.75;
                    padding-right: $default-margin * 0.5;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                }

                .archive-button {
                    display: flex;
                    align-items: flex-start;
                    margin-top: $default-margin;
                    padding: $default-padding $default-padding $default-padding
                        $default-padding * 0.5;
                    justify-content: center;
                    align-items: center;
                    color: #41dfaa;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    text-align: center;
                    background-color: inherit;
                    border: none;

                    img {
                        margin-left: $default-margin * 0.5;
                        margin-right: auto;
                    }
                }
            }
        }

        .visit-penneo {
            display: flex;
            align-items: baseline;
            margin-top: $default-margin;
            flex-wrap: wrap;

            .visit-text {
                color: #111827;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                align-items: flex-start;
                margin-right: $default-margin * 0.25;
            }

            a {
                color: $penneo-blue-default;
                display: inline-flex;
                flex-wrap: wrap;
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
                text-decoration-line: underline;
                &:hover {
                    color: #008bff;
                }
                &:active {
                    color: #1c56e7;
                }
            }
        }

        .try-penneo {
            display: grid;
            place-items: center;
            justify-content: center;
            align-items: center;
            margin-top: $default-margin * 3;
        }

        .dashboard-image {
            margin: $default-margin * 4 0;
        }
    }
}
