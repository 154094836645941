@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.breadcrumbs {
    @include OpenSans-SemiBold();
    font-size: 9.6pt;
    display: inline-block;
    background: $color-white;
    box-shadow: $card-shadow;
    border-radius: $default-radius;
    padding: $default-padding * 0.5 $default-padding * 1.5;
    width: 100%;
    z-index: 2;
    position: absolute;
}

.breadcrumb-item {
    &:last-child {
        .breadcrumb-item-title {
            color: $cf-blue;
        }

        .breadcrumb-item-arrow {
            display: none;
            color: $color-blue-main;
        }
    }
}

.breadcrumb-item-arrow {
    margin: 0 $default-margin;
}
