@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.signing-role {
    position: fixed;
    right: calc($default-padding / 4);
    top: 90px;
    color: $gray-darker;
    background: $color-white;
    border: 2px solid $gray-dark;
    box-shadow: $card-shadow;
    border-radius: $default-radius * 2.75;
    padding: $default-padding * 0.5 10px $default-padding * 0.5 $default-padding *
        2.5;
    @media only screen and (min-width: 980px) {
        right: $default-padding * 2;
        top: 120px;
    }
    @media only screen and (min-width: 1170px) {
        right: calc((100vw - 1170px) / 2);
    }

    .fa-stack {
        position: absolute;
        color: $gray-dark;
        font-size: 13px;
        left: $default-padding;
        top: calc(50% - 13px);
    }

    .signing-as {
        font-size: $small-font-size;
        display: block;
    }
}
