// Search Bar
.search-input {
    position: relative;

    .fa {
        position: absolute;
        top: 50%;
        margin-top: -8px;
        left: $default-padding;
        z-index: 2;
    }

    input {
        position: relative;
        min-width: 300px;
        width: 100%;

        z-index: 1;
        height: 100%;
        // @todo: remove important, make a round border style
        padding-left: $default-padding * 2.5 !important;
        // border-radius: 1000em !important;
        &:focus {
            outline: none;
        }
    }

    .result-help {
        position: absolute;
        top: 50%;
        margin-top: -($default-margin * 0.5);
        font-size: 0.9em;
        right: $default-margin;
        font-weight: bold;
        color: $text-medium;
        // color: $color-info;
        z-index: 2;
    }
}
