@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
$header-color: #142d43;

.casefile-details {
    .back-link {
        cursor: pointer;
        @include transition(0.2s);
        position: absolute;
        top: 22px;
        left: 0px;
        height: $default-padding * 3;
        padding: $default-padding * 0.5 $default-padding * 0.25;
        background: $color-blue-main;
        border-top-right-radius: $default-radius;
        border-bottom-right-radius: $default-radius;
        width: 20px;
        overflow: hidden;

        i {
            @include transition(0.2s);
            margin-left: 0 !important;
            padding: 0;
            display: inline-block;
            position: relative;
            top: 4px;
            left: 0px;
            font-size: 10pt;
            color: $color-white;
        }

        .back-button-text {
            @include transition(0.2s);
            position: absolute;
            left: -5px;
            top: 13px;
            width: 100px;
            text-align: center;
            font-size: 95%;
            @include OpenSans-Bold();
            color: $color-white;
            opacity: 0;
        }

        &:hover {
            background: $color-blue-main;

            i {
                color: $color-white;
                margin-left: 5px !important;
            }

            .back-button-text {
                opacity: 1;
                margin-left: 10px;
            }
            box-shadow: $card-shadow;
            width: 100px;
        }
    }

    @include white-container();
    position: relative;
    padding: $default-padding * 2;
    padding-top: $default-padding * 1.5;
    margin: $default-padding * 0.5;
    box-shadow: $card-shadow;

    &-title {
        margin: 0;
        width: 80%; // Allow displaying really long titles. (works for most of the cases)
        @include OpenSans-SemiBold();
        font-size: 15pt;
        color: black;

        .item-inline-action {
            font-size: 9.5pt;
            display: inline;
            color: $text-medium;

            &:hover {
                color: $cf-blue;
                cursor: pointer;
            }
        }
    }

    &-info-note {
        .fa-exclamation-triangle {
            color: $penneo-orange;
        }
    }

    &-subtitle {
        margin-left: $default-margin * 0.5;
        font-size: 9.5pt;
    }
}

.casefile-details-section-header {
    color: $header-color;
    margin-top: $default-margin * 2;
    font-size: 11pt;
    letter-spacing: 0.2pt;
    position: relative;

    &.small-margin-bottom {
        margin-bottom: $default-margin * 0.5;
    }

    .casefile-details-section-count {
        position: absolute;
        background: $header-color;
        margin-left: $default-margin * 0.5;
        color: $color-white;
        display: inline-block;
        width: 22px;
        line-height: 22px;
        font-size: 10pt;
        border-radius: 50%;
        text-align: center;
    }
}

.casefile-details-section-subtitle {
    @include OpenSans-Italic();
    margin-top: $default-margin * 0.25;
    margin-bottom: 0;
    font-size: 9.5pt;
    letter-spacing: 0.1pt;
    font-weight: normal;
}

.input.casefile-details-input {
    display: inline-block;
    margin-right: $default-margin * 2;

    > input {
        width: 220px;
    }
}

.casefile-details .casefile-error {
    .code {
        color: $color-blue-main;
    }

    button {
        margin-top: $default-margin;
    }
}

.validation-errors-list {
    background: transparentize($penneo-orange, 0.9);
    border-radius: $default-radius;
    border: 1px solid $penneo-orange;
    padding: $default-padding;
    margin-top: $default-margin;
    color: $text-dark;

    i {
        color: $penneo-orange;
        margin-right: $default-margin * 0.5;
    }

    &-item {
        margin-top: $default-margin * 0.5;

        &:first-child {
            margin-top: 0;
        }
    }
}

.casefile-privacy {
    .fa-question-circle {
        cursor: pointer;
    }
}
