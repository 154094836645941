.tab-content-section {
    &:first-child {
        padding-top: 0;
    }
    padding-top: $default-margin * 0.5;
    margin-bottom: $default-margin;
    h3 {
        margin-top: 0;
        margin-bottom: $default-margin * 0.5;
    }

    h4 {
        margin-top: 0;
        margin-bottom: 0;
        text-transform: uppercase;
        // opacity: 0.7;
        // border-left: 3px solid $color-blue-highlight;
        // padding-left: $default-padding/4;
        // position: relative;
        // left: -($default-padding/4);
    }
    p {
        margin-top: $default-margin * 0.5;
    }

    ul {
        margin: 0;
    }
}

.signer-item {
    border-left: 2px solid $color-blue-highlight;
    padding-left: $default-padding * 0.5;
    position: relative;

    h3 {
        margin-top: $default-margin * 0.5;

        // margin-top: 0;
        // background: orange;
    }
}

.tab-details {
}

.resend-emails-button {
    height: 250px;
    padding-top: 180px;
}

.attachment-tab {
    .attachment-container {
        border-bottom: solid 1px $gray-medium;
    }
    .details-content-title {
        border-bottom: solid 1px $gray-medium;
        padding-bottom: 0.5em;
        margin-bottom: 0.3em;
    }
    .attachment-title {
        display: inline-block;
    }
    .button {
        display: inline-block;
        margin-right: 1em;
    }
}
