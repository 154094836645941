.autocomplete-list-holder {
    position: relative;
    width: inherit;
    min-height: 40px;

    input {
        width: 100%;
        position: relative;
        z-index: 0;
    }

    .autocomplete-list {
        $item-height: 56px;
        $item-height-large: 74px;

        cursor: pointer;
        min-width: 260px;
        width: 100%;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 100%;
        background-color: $color-white;
        border-radius: $default-radius;
        box-shadow: $card-shadow-hover;
        margin: 0;
        overflow: hidden;

        .info {
            width: inherit;
            display: block;
            padding: math.div($default-padding, 1.5) $default-padding;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;

            &.active {
                border-color: $gray-medium;
                background-color: $gray-light;
            }

            font-size: $small-font-size;

            .name {
                font-size: $default-font-size;
                margin-bottom: 0px;
            }
        }
    }
}
