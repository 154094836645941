@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.openid-error-details {
    margin: $default-margin * 1.5 0;
}

.openid-error-details-message {
    font-weight: bold;
}

.openid-error-details-explanation {
    margin-top: $default-margin * 0.5;
}

.openid-error-details-footer {
    margin-top: $default-margin;
    text-align: right;
    font-weight: 600;
    font-size: 0.9em;
    font-family: monospace;
}

.openid-error-details-timestamp {
    font-size: 0.85em;
}

.openid-error-details-timestamp-time {
    padding-left: $default-padding * 0.25;
}

.openid-error-details-code {
    float: left;
}

.openid-error-details-status {
    background: $color-error;
    color: $color-white;
    border-radius: $default-radius;
    padding: 0 $default-padding * 0.25;
    margin-left: $default-margin * 0.25;
}

.openid-error-details-traceid {
    color: $text-medium;
    border-radius: $default-radius;
    letter-spacing: 1px;
    padding: $default-padding * 0.25 $default-padding * 0.5;
    @include OpenSans-SemiBold();
    font-size: 0.8em;
}
