@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.storage-selector {
    @include OpenSans-SemiBold();
    font-size: 9pt;
    padding-top: $default-padding * 0.5 $default-padding;
    padding-bottom: $default-padding * 0.5 $default-padding;
    margin-top: $default-margin;
    margin-bottom: $default-margin;
    border-radius: $default-radius;
    border: 1px solid transparentize($cf-blue, 0.6);
    background: transparentize($cf-blue, 0.92);
}

.storage-selector-question {
    text-align: center;
}

.storage-selector-options {
    text-align: left;
    margin-left: 10%;
    margin-right: 10%;
}

.storage-option {
    label.radio {
        padding-bottom: 0;
    }
}

.sign-account-container {
    padding-top: $default-padding;
}

.sign-account-item-card {
    .penneo-card-container {
        padding: 0;
    }
}

.sign-account-item {
    cursor: pointer;
    margin-bottom: $default-margin * 0.5;

    label.radio {
        padding: $default-padding * 0.5 $default-padding;
        padding-bottom: math.div($default-padding, 3);
    }
}

.sign-account-details {
    display: inline-block;
    vertical-align: middle;
    margin-left: $default-margin * 0.5;
}

.sign-account-details-user {
    &-name {
        @include OpenSans-SemiBold();
        color: $text-dark;
        margin: 0;
        padding: 0;
    }

    &-email {
        @include OpenSans-Regular();
        margin: 0;
        padding: 0;
    }
}

.sign-account-details-role {
    margin: 0;
    padding: 0;
    font-size: 90%;
    position: relative;
    top: -2px;

    @include OpenSans-Bold();

    .customer-name {
        color: $penneo-blue;
    }
}

.sign-account-customer-logo {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding: $default-padding;

    img {
        height: 100%;
    }
}

.post-sign-storage-options {
    text-align: left;
    padding: 0 $default-padding;

    &-title {
        margin-top: 0;
    }
}

.storage-option {
    margin-bottom: $default-margin;
}
