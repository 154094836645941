.tab-switcher {
    min-height: 300px;
    &-header {
        ul {
            margin: 0;
            padding: 0;
            border-bottom: $gray-border 1px solid;
        }

        li {
            cursor: pointer;
            position: relative;
            margin: 0;
            font-weight: bold;
            display: inline-block;
            box-sizing: border-box;
            text-align: center;
            width: 50%;
            border-right: $gray-border 1px solid;

            &:last-child {
                border-right: none;
            }

            a {
                display: block;
                width: 100%;
                height: 100%;
                padding: math.div($default-padding, 1.2) $default-padding;
                &.active {
                    opacity: 1;
                    color: $color-blue-highlight;
                    &:after {
                        display: block;
                        content: '';
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        width: 100%;
                        height: 4px;
                        background: $color-blue-highlight;
                    }
                }
            }
        }
    }

    &-content {
        position: relative;
        text-align: left;
        min-height: 300px;
        padding-bottom: 0;
    }
    &-container {
        min-height: 300px;
    }
}
