.dashboard-menu .status-notification {
    position: relative !important;
    display: inline-block;

    .notification-icon {
        color: $text-medium;
        padding: $default-margin * 0.25;

        &.unread + span {
            top: -3px;
            right: -3px;
        }
    }

    .status-notification-unread {
        top: -2px;
        right: 35px;
    }
}

.status-notification {
    * {
        -webkit-font-smoothing: antialiased;
    }

    position: absolute;
    top: 0;
    right: 0;
    padding: $default-padding;
    z-index: 500;

    .notification-icon {
        position: relative;
        padding: $default-padding * 0.5;
        border-radius: 50%;
        color: $color-white;
        font-size: 1.4em;

        &:before {
            font-size: 90%;
        }

        &.unread {
            & + span {
                display: block;
                background: $color-info;
                height: 10px;
                width: 10px;
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 50%;
            }

            &.error + span {
                background: $color-error;
            }

            &.warning + span {
                background: $color-warning;
            }

            &.success + span {
                background: $color-success;
            }

            &.normal + span {
                background: $color-info;
            }
        }
    }

    .status-notification-content {
        display: inline-block;
        position: relative;
        cursor: pointer;
    }

    .status-notification-unread {
        white-space: nowrap;
        &:before {
            content: '';
            transform: rotate(45deg);
            display: block;
            position: absolute;
            right: -5px;
            top: 50%;
            margin-top: -5px;
            height: 10px;
            width: 10px;
            background: $color-error;
        }
        @include transition(0.1s);
        text-align: center;
        position: absolute;
        opacity: 0;
        top: 4px;
        right: 40px;
        padding: $default-padding * 0.5;
        border-radius: $default-radius;
        background: $color-error;
        color: $color-white;

        .cta {
            font-weight: bold;
            font-size: 0.9em;
        }

        &.active {
            opacity: 1;
        }
    }
}

.mitid-notification {
    text-align: left;
    width: 360px;
    padding: $default-padding * 0.5;
    border-bottom: $default-border;

    .info-text {
        color: white;
        background-color: $cf-blue;
        font-size: 75%;
        padding: math.div($default-padding, 3) $default-padding * 0.5;
        display: inline-block;
        margin-bottom: 2px;
        font-weight: bold;
        border-radius: $default-radius;
    }

    h3 {
        margin: $default-margin * 0.5 0 math.div($default-margin, 3) 0;
    }

    p {
        margin: math.div($default-margin, 3) 0 $default-margin 0;
    }

    .action-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $default-margin * 0.5;
    }

    .read-more-link {
        color: black;
        font-weight: 600;
        text-decoration: underline;

        &:hover {
            color: $cf-blue;
        }
    }
}

.incident-list {
    @extend .prevent-selection;
    text-align: left;

    width: 360px;
    padding: $default-padding * 0.5;

    .incident:last-child {
        background: orange;
        border-bottom: none;
    }

    .incident {
        display: block;
        color: $text-dark;
        margin-bottom: $default-padding;
        padding-bottom: $default-padding;
        border-bottom: $default-border;

        &:hover {
            .link {
                color: $color-blue-highlight;
                opacity: 1;
            }
        }
    }

    .link-bottom {
        font-weight: bold;
    }

    .time {
        color: $text-medium;
        float: right;
    }

    .label {
        padding: math.div($default-padding, 3) $default-padding * 0.5;
        display: inline-block;
        margin-bottom: 2px;
        font-weight: bold;
        text-transform: uppercase;
        border-radius: $default-radius;
        font-size: 75%;

        &.investigating,
        &.identified {
            background: $color-error;
            color: #fff;
        }

        &.monitoring {
            background: $color-warning;
            color: #333;
        }

        &.resolved {
            background: $color-success;
            color: #fff;
        }
    }

    .link {
        @include transition(0.3s);
        text-decoration: underline;
        // opacity: 0.5;
    }

    h3 {
        @include transition(0.3s);
        margin: 0;
        font-size: 1.05em;
    }

    p {
        margin: $default-margin * 0.5 0;
        margin-top: 0;
    }
}
