.batch-process-overview {
    @include white-container();

    .title {
        margin-bottom: $default-margin * 0.5;
    }

    .subtitle {
        margin-top: 0;
    }

    .entry {
        padding: $default-padding 0;
        border-bottom: $gray-border 1px solid;
        &:first-child {
            border-top: $gray-border 1px solid;
        }
    }
}
