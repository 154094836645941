.login-page {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: $default-padding * 4 0;
    background: $color-blue-main;

    .branding-logo {
        text-align: center;
        padding-bottom: $default-padding * 1.5;
    }

    @media #{$breakpoint-small} {
        padding: $default-padding;
    }

    .eid-login-container {
        position: relative;
        width: 460px;
        min-height: 580px;
        margin: 0 auto;
        padding: $default-padding * 1.5 0;
        box-sizing: border-box;
        background: $color-white;
        border-radius: $default-radius * 2;
        box-shadow: $card-shadow;

        @media #{$breakpoint-small} {
            width: 100%;
        }
    }

    .notice {
        padding-top: $default-padding * 0.5;
        p {
            // font-size: $small-font-size;
            font-size: 8.5pt;
            margin-bottom: 0;
            color: $text-medium;
            font-weight: normal;
        }
        span {
            color: $text-light;
        }
    }
}

.login-status {
    margin-top: $default-padding * 1.5;
    border-radius: $default-radius * 2;

    padding: math.div($default-padding, 2) $default-padding * 1.5;
    box-sizing: border-box;
    background: $color-warning;
    color: $color-white;
    text-shadow: 0 0.125rem 0.15rem rgba(000, 000, 000, 0.25);
    border: 0.25rem solid darken($color-warning, 10%);
    box-shadow: $card-shadow;
    display: block;

    &:hover {
        color: $color-white;
        background: lighten($color-warning, 5%);
    }
}

.penneo-login-image-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url(#{$images}/auth/penneo-login-background.jpg);
    opacity: 0.25;
    z-index: 2;
    background-size: cover, contain;
    background-repeat: no-repeat;
}

.penneo-login-background-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: $color-blue-main;
    z-index: 1;
    background-size: cover, contain;
    background-repeat: no-repeat;
}

.penneo-login-page {
    * {
        -webkit-font-smoothing: antialiased;
    }
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    overflow-y: auto;

    .login-widget-container {
        position: relative;
        width: 480px;
        margin: 0 auto;
        text-align: center;
        padding: $default-padding * 3 0;
        padding-top: $default-padding * 4;
        z-index: 2;

        .auth-validate {
            margin-top: $default-padding * 2;
        }

        .eid-title {
            text-align: center;
        }

        @media #{$breakpoint-small} {
            width: 95%;
            padding: $default-padding 0;
        }

        @media #{$breakpoint-xsmall} {
            width: 100%;
        }
    }

    .login-widget-header {
        position: relative;
        @media #{$breakpoint-small} {
            text-align: left;
        }

        @media #{$breakpoint-xsmall} {
            padding: 0 $default-padding * 0.5;
        }

        .branding-logo {
            position: relative;
            top: 15px;
            @media #{$breakpoint-small} {
                top: 0;
            }
        }
    }
}

.password-login-widget {
    margin: $default-margin 0;
    margin-top: $default-margin * 1.5;
    padding: $default-padding * 1.2;
    background: $color-white;
    border-radius: $default-radius;

    @media #{$breakpoint-small} {
        margin-top: $default-margin;
    }

    * {
        -webkit-font-smoothing: antialiased;
    }

    h4 {
        margin-top: 0;
        color: #545454;
        font-size: 1.1em;
        text-align: left;
        margin-bottom: $default-margin;
    }

    form {
        text-align: left;

        label {
            color: $text-dark;
            opacity: 0.7;
        }

        input {
            width: 100%;
        }
    }
}

.eid-widget {
    border-radius: $default-radius;
    background: $color-white;
    padding: $default-padding * 0.8;
    padding-top: $default-padding * 1.2;

    h4 {
        color: #545454;
        margin-top: 0;
        padding: 0 $default-padding * 0.4;
        font-size: 1.1em;
        text-align: left;
        margin-bottom: $default-margin * 0.5;
    }
}

.eid-widget-list {
    $item-width-standard: math.div(100%, 3);
    $item-width-compact: 100% * 0.25;
    $item-padding: $default-padding * 0.5;

    display: flex;
    flex-wrap: wrap;
    margin: 0;

    li {
        flex: 0 0 auto;
        flex-basis: $item-width-standard;
        min-width: 105px;
        padding: $item-padding;
        text-align: center;

        // @see https://stackoverflow.com/a/21950351
        @media #{$ie-only} {
            flex-basis: calc(#{$item-width-standard} - #{$item-padding * 2});
        }

        .item-wrapper {
            position: relative;

            // Corner
            .auth-flag {
                position: absolute;
                left: 1px;
                top: 1px;

                img {
                    height: 34px;
                    width: 34px;
                    border-top-left-radius: $default-radius;

                    @media #{$breakpoint-small} {
                        height: 30px;
                        width: 30px;
                    }
                }
            }

            .auth-logo {
                padding: 0;
                height: 98px;
                display: flex; // to make horizontal alignment easy
                align-items: center;

                img {
                    display: block;
                    width: 100%;
                    max-width: 98px;
                    height: auto;
                    margin: 0 auto;
                }
            }

            &:hover {
                cursor: pointer;
                .method-title {
                    border-color: transparent;
                    background: $color-blue-highlight;
                    color: $color-white;
                }
                .item-content {
                    background: $color-white;
                    border-color: $color-blue-highlight;
                }
            }

            .method-title {
                @include transition(0.3s);
                border-top: $default-border;
                font-size: 95%;
                color: $text-medium;
                font-weight: bold;
                background: $gray-light;
                padding: math.div($default-padding, 1.5) 0;
            }
        }

        .item-content {
            @include transition(0.3s);
            background: #fbfbfb;
            border-bottom: $default-border;
            border-width: 10px;
            border: $default-border;
            border-radius: 4px; // same radius as the flags'
        }
    }

    &.eid-widget-list--compact {
        @media screen and (min-width: #{$break-small}) {
            justify-content: center;

            li {
                flex-basis: $item-width-compact;

                // @see https://stackoverflow.com/a/21950351
                @media #{$ie-only} {
                    flex-basis: calc(
                        #{$item-width-compact} - #{$item-padding * 2}
                    );
                }
            }
        }
    }
}

.login-widget {
    position: relative;
    margin-top: $default-margin * 2;
    border-radius: $default-radius;
    background: $color-white;
    padding: $default-padding * 1.5 0;
    padding-bottom: $default-padding * 0.5;
    min-height: 600px;

    @media #{$breakpoint-small} {
        margin-top: $default-margin;
    }

    @media #{$breakpoint-xsmall} {
        border-radius: 0;
    }
}

.eid-widget-container {
    padding: $default-padding * 2;
    padding-bottom: 0;

    @media #{$breakpoint-small} {
        padding: $default-padding;
        padding-bottom: 0;
    }

    @media #{$breakpoint-xsmall} {
        padding: 0;
        padding-bottom: 0;
    }

    .widget-footer {
        position: relative;
        margin-top: $default-margin * 0.5;
        text-align: left;

        a {
            font-weight: bold;
            text-decoration: underline;
            color: $color-blue-highlight;
            font-size: $small-font-size;
            position: absolute;
            top: 2px;
            right: 0;
            display: inline-block;
            white-space: nowrap;
        }
    }
}
