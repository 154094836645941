@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.obfuscated-text-field {
    position: relative;
    input {
        padding-right: $default-padding * 4 !important;
    }

    &-icon {
        position: absolute !important; // Overrides relative position in V1-embedded mode
        bottom: 4px;
        right: 4px;
    }
}
