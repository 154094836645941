// Penneo Iconset
// @import "../../../node_modules/font-awesome/scss/font-awesome";

// Custom font-awesome icons
.fa-grip:before {
    content: '\f142 \202F \f142 \202F \f142 \202F \f142';
}

// Google Fonts

// Lato
@import url(//fonts.googleapis.com/css?family=Lato:400,700);

// Open Sans
@import url(//fonts.googleapis.com/css?family=Open+Sans:400,600,700);

// Caveat
@import url(//fonts.googleapis.com/css?family=Caveat);

@mixin OpenSans-Regular() {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-weight: 400;
}

@mixin OpenSans-Italic() {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-style: italic;
    font-weight: 400;
}

@mixin OpenSans-SemiBold() {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-weight: 600;
}

@mixin OpenSans-Bold() {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-weight: 700;
}

// Penneo Iconset
$fonts-directory: '/assets/fonts';
