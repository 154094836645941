@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.profiles-list-container {
    @apply py-0
            px-6
            pb-8
            overflow-hidden
            bg-white
            shadow-light-sm;
}

.profile-select-list-entry {
    padding: 1rem 1rem;
    border-bottom: 1px solid $gray-lighter;

    &:last-child {
        border-bottom: none;
    }
}

.profile-select-list-entry:hover {
    @apply bg-neutral-100;
    cursor: pointer;
}

.profile-select-list-entry.selected {
    @apply bg-secondary-50;
    cursor: default;
}

.profiles-list-loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10rem;
}

.profile-select-list-entry.disabled {
    filter: grayscale(1);
}

.user-avatar-details-email {
    @apply text-neutral-600;
}

.profile-select-list-entry.disabled {
    .user-avatar-details-name,
    .user-avatar-details-email,
    .user-avatar-details-date,
    .avatar-circle-text {
        @apply text-neutral-400;
    }
}

.profile-select-list-entry.disabled:hover {
    background-color: transparent;
    cursor: default;
    .avatar-circle {
        @apply bg-secondary-50;
    }
}
