@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.penneo-side-panel {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999999;
    overflow-y: auto;
    overscroll-behavior: auto contain;

    * {
        -webkit-font-smoothing: antialiased;
    }
}

.penneo-side-panel-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparentize($color-blue-main, 0.5);
    z-index: 900;
}

.penneo-side-panel-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    min-width: 820px;
    z-index: 1010;
}
