.news-feed {
    position: absolute;
    top: 0;
    left: $navigation-sidebar-width;
    right: 0;
    bottom: 0;
    overflow-y: hidden;

    &-backdrop {
        background: transparentize(#373a47, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2000;
        overflow: hidden;
    }

    &-header {
        height: $header-height;
        text-align: center;
        border-bottom: $default-border;
        h1 {
            line-height: $header-height;
            padding: 0;
            margin: 0;
        }

        .close-icon {
            position: absolute;
            top: 0;
            right: $default-padding;
            line-height: $header-height;
        }
    }

    &-empty {
        .fa {
            font-size: 2em;
            opacity: 0.7;
        }
        padding: $default-padding $default-padding * 2;
        text-align: center;
    }

    &-content {
        position: absolute;
        top: 0;
        bottom: 0;
        margin-top: $header-height;
        padding-top: $default-padding;
        width: 100%;
        overflow-y: auto;
    }

    &-item {
        padding: 0 $default-padding * 1.5;
        padding-bottom: $default-padding * 1.5;

        margin-bottom: $default-margin;
        border-bottom: $default-border;

        img {
            position: relative;
            width: 100%;
        }

        h3 {
            margin-top: 0;
        }

        p {
            line-height: 1.3em;
            white-space: pre-line;
        }

        a {
            display: inline-block;
            color: $color-blue-highlight;
            border: 1px solid $color-blue-highlight;
            padding: $default-padding * 0.5 $default-padding;
            border-radius: $default-radius;

            &:hover {
                background: $color-blue-highlight;
                color: #fff;
            }

            .fa {
                position: relative;
                top: 1px;
                font-weight: bold;
                padding-left: $default-padding * 0.5;
            }
        }

        .post-date {
            color: $gray-dark;
            font-size: 0.9em;
            line-height: 2.8em;
            vertical-align: middle;
            // padding-top: $default-padding/1;
            float: right;
        }

        .new-tag {
            background: $color-blue-highlight;
            border-radius: $default-radius;
            padding: 2px 6px;
            font-size: 0.8em;
            color: #fff;
            margin-right: $default-margin * 0.5;
            font-weight: normal;
        }
    }

    &-container {
        opacity: 1;
        background: $color-white;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 400px;
        z-index: 2000;
    }
}

.news-feed-backdrop-transition {
    &-enter,
    &-appear {
        opacity: 0.01;
        transition: all 0.3s ease-out;

        &-active {
            opacity: 1;
        }
    }

    &-leave {
        opacity: 1;
        transition: all 0.3s ease-out;

        &-active {
            opacity: 0.01;
            transition: all 0.3s ease-out;
        }
    }
}

.news-feed-sidebar-transition {
    &-enter,
    &-appear {
        opacity: 0.1;
        transform: translateX(-100%);
        transition: all 0.3s ease-out;

        &-active {
            opacity: 1;
            transform: translateX(0%);
        }
    }

    &-leave {
        opacity: 1;
        transform: translateX(0%);
        transition: all 0.3s ease-in;

        &-active {
            opacity: 0.1;
            transform: translateX(-100%);
            transition: all 0.3s ease-in;
        }
    }
}

// Styles to override behavior of news-feed in signing-service
.signing-components .news-feed {
    left: 0 !important;

    // Make sidebar appear from right
    &-container {
        left: auto !important;
    }

    // Animate sidebar from/to right
    .news-feed-backdrop-transition {
        &-enter,
        &-appear {
            opacity: 0.01 !important;
            transition: all 0.3s ease-out !important;

            &-active {
                opacity: 1 !important;
            }
        }

        &-leave {
            opacity: 1 !important;
            transition: all 0.3s ease-out !important;

            &-active {
                opacity: 0.01 !important;
                transition: all 0.3s ease-out !important;
            }
        }
    }

    .news-feed-sidebar-transition {
        &-enter,
        &-appear {
            opacity: 0.1 !important;
            transform: translateX(100%) !important;
            transition: all 0.3s ease-out !important;

            &-active {
                opacity: 1 !important;
                transform: translateX(0%) !important;
            }
        }

        &-leave {
            opacity: 1 !important;
            transform: translateX(0%) !important;
            transition: all 0.3s ease-in !important;

            &-active {
                opacity: 0.1 !important;
                transform: translateX(100%) !important;
                transition: all 0.3s ease-in !important;
            }
        }
    }
}
