$cf-container-small: 520px;
$cf-container-medium: 768px;
$cf-container-large: 980px;
$cf-blue: #3f99f1;
// $gray-label: #B0BCC7;
$gray-label: #717981;
$button-size: math.div($default-padding, 1.6) $default-padding * 1.6;

@mixin item() {
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #e5e6eb;
    padding: $default-padding;
    box-shadow: 0 2px 4px 0 rgba(239, 239, 239, 0.5);
}

@mixin column() {
    display: inline-block;
    vertical-align: top;
    padding-right: $default-padding * 1.5;
    padding-left: $default-padding * 1.5;

    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
        border-right: none;
    }
}

%cf-container {
    background: $color-white;
    margin: 0 auto;
    width: $cf-container-small;
    padding: $default-padding * 2;
    margin-bottom: $default-margin;
    box-shadow: 0 2px 4px 0 rgba(239, 239, 239, 0.5);
    border-radius: 3px;
}

%cf-footer {
    @extend %cf-container;
    padding: $default-padding $default-padding * 2;
}

%cf-header {
    margin: 0 auto;
    width: $cf-container-small;
    padding: 0 $default-padding * 2;
    margin-bottom: $default-margin * 1.5;
    text-align: center;

    h1 {
        @include OpenSans-Bold();
        font-size: 15pt;
        color: black;

        padding-bottom: $default-padding * 0.25;
        margin-bottom: 0;

        .dot {
            font-size: 17pt;
            color: $cf-blue;
        }
    }

    p {
        @include OpenSans-Regular();
        margin-top: 0;
        font-size: 10pt;
        color: #7e7e7e;
        text-align: center;
    }
}

.form-v2,
.ui-v2 {
    @include OpenSans-Regular();

    input,
    select,
    textarea {
        width: 100%;
        @include OpenSans-Regular();
        border-radius: 2px;
        padding: math.div($default-padding, 1.7) $default-padding * 0.8;
        font-size: 10.5pt;
        color: #7b7b7b;
        text-align: left;
    }

    input[type='checkbox']:not(.specific) {
        padding: 0;
    }

    .item {
        @include item;
    }

    //TODO: Should these go? (tailwind-classes can make up for these)
    .mt0 {
        margin-top: 0;
    }

    .mb0 {
        margin-bottom: 0;
    }

    .pt0 {
        padding-top: 0;
    }

    .pb0 {
        padding-bottom: 0;
    }

    label {
        @include OpenSans-Bold();
        font-size: 10pt;
        color: $gray-label;
        letter-spacing: 0.15px;
        display: block;
        margin-top: $default-margin * 0.5;
        margin-bottom: 0;
        padding-bottom: $default-padding * 0.5;
    }

    label.label {
        margin-top: $default-margin * 0.5 !important;
    }

    // When using TextInput, the label is nested within a div.
    div:first-of-type > label {
        margin-top: 0;
    }

    // Don't add the margin-top to the first element of the form.
    .input-validation:first-child label:first-of-type {
        margin-top: 0;
    }

    // When using validation wrapper, label is deeply nested.
    .input-validation label:first-of-type {
        margin-top: $default-margin * 0.5;
    }

    .signer-form {
        margin-top: $default-margin * 0.5;
    }

    .input-validation + .input-field > label {
        margin-top: $default-margin * 0.5 !important;
    }

    .file-input {
        display: inline-block;
        padding-bottom: 0;
    }

    .icon-text-input {
        position: relative;

        i.fa {
            position: absolute;
            z-index: 1;
            top: $default-padding * 0.9;
            left: $default-padding;
            color: $text-light;
        }
        input {
            padding-left: $default-padding * 2.5;
        }
        input:-webkit-autofill {
            box-shadow: 0 0 0px 1000px $color-white inset;
            -webkit-box-shadow: 0 0 0px 1000px $color-white inset;
        }
    }

    .casefile-folder-selector-bar {
        @extend %input-base;
        width: 100%;
        padding: math.div($default-padding, 2.5) $default-padding * 0.5;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        position: relative;
        cursor: pointer;
        height: 40px;

        button {
            border-radius: 2px;
            border: 1px solid $cf-blue;
            @include OpenSans-SemiBold();
            font-size: 9.5pt;
            color: $cf-blue;
            background: transparent;
            text-transform: none;
            text-align: center;
            padding: math.div($default-padding, 3.5) $default-padding * 2 !important;
        }

        .folder-title {
            position: absolute;
            white-space: nowrap;
            color: #7b7b7b;
            left: 10px;
            top: 10px; // manual adjustment to make long folder titles appear in one line
        }
    }

    .underline-link {
        @include OpenSans-SemiBold();
        color: $cf-blue;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }

    .date-selector {
        display: inline-block;
        margin-right: $default-margin * 3;

        .switch-on {
            @include OpenSans-SemiBold();
            cursor: pointer;
            padding-left: 2px;
            padding-right: 2px;

            &:hover {
                color: $cf-blue;
            }
        }

        .switch-off {
            cursor: pointer;
            padding: $default-padding * 0.5;
            text-align: center;
        }
    }

    .conditional-signing {
        display: flex;
        align-items: center;

        &-switch-on {
            @include OpenSans-SemiBold();
            cursor: pointer;

            &:hover {
                color: $cf-blue;
            }
        }

        &-switch-off {
            cursor: pointer;
            padding: $default-padding * 0.5;
            text-align: center;
        }
    }

    .custom-checkbox {
        @include OpenSans-Regular();
        font-size: 10.8pt;
        color: #666;
        letter-spacing: 0.15px;
        display: block;
        margin-top: 0;
        margin-bottom: 0;
    }

    .checkbox-group {
        .checkbox {
            display: block !important;
            margin-top: 0 !important;
            padding-bottom: math.div($default-padding, 1.5) !important;

            &:last-child {
                padding-bottom: 0 !important;
            }
        }
    }

    .toggle-input {
        border: $default-border;
        border-radius: $default-radius;
        display: block;
        padding: $default-padding * 0.25 $default-padding * 0.25;
        padding-left: 0;

        &-option {
            display: inline-block;
            text-align: center;
            padding-left: $default-padding * 0.25;

            &-content {
                position: relative;
                @include transition(0.2s);
                @include OpenSans-SemiBold();

                color: $text-medium;
                font-size: 9.5pt;
                padding-top: $default-padding * 0.5;
                padding-bottom: $default-padding * 0.5;
                border-radius: $default-border;
            }

            &:hover {
                .toggle-input-option-content {
                    cursor: pointer;
                    background: $gray-light;
                }
            }
        }

        .active .toggle-input-option {
            &-content {
                @include OpenSans-SemiBold();
                background: $cf-blue;
                border-radius: $default-border;
                font-size: 9.5pt;
                color: #fff;
            }
        }
    }

    .columns {
        display: block;
        width: 100%;

        .column-2 {
            @include column;
            vertical-align: top;
            width: 50%;

            label {
                margin-top: $default-margin;
            }

            label:first-child {
                margin-top: 0;
            }
        }
    }

    .tag {
        border-radius: 2px;
        padding: $default-padding * 0.2 $default-padding * 1;
        color: $cf-blue;
        @include OpenSans-SemiBold();
        font-size: 9pt;
        margin-right: $default-margin * 0.5;
        display: inline-block;
        border: 1px solid $cf-blue;

        .tag-title {
            @include OpenSans-Bold();
            border-right: 1px solid transparentize($cf-blue, 0.5);
            padding-right: math.div($default-padding, 1.5);
            margin-right: math.div($default-padding, 1.5);
            margin-left: -$default-padding * 0.25;
        }
    }

    .radio {
        @include prevent-selection();
        cursor: pointer;
        position: relative;
        line-height: 1.6em;
        display: block;

        .radio-label {
            cursor: pointer;
            @include OpenSans-Regular();
            font-size: 10.8pt;
            color: #7b7b7b;
            letter-spacing: 0.15px;
        }

        .radio-input {
            cursor: pointer;
            display: inline-block;
            position: relative;
            width: 18px;
            margin-right: math.div($default-margin, 3);
            height: 18px;
            border: 2px solid #ccc;
            border-radius: 100%;
            -webkit-tap-highlight-color: transparent;
            top: 0.25em;
            background: $color-white;

            // @fix webkit animation jitter
            transform: translate3d(0, 0, 0);

            &:after {
                cursor: pointer;
                content: '';
                position: absolute;
                top: 2px;
                left: 2px;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background: $cf-blue;
                transform: scale(0);
                transition: all 0.2s ease;
                opacity: 0.08;
                pointer-events: none;
            }
        }

        &:hover .radio-input {
            border-color: $cf-blue;
        }

        &:hover .radio-input:after {
            transform: scale(3.5);
        }

        &:hover .radio-label {
            color: $cf-blue;
        }

        &.checked .radio-label {
            color: #7b7b7b;
        }

        input[type='radio'] {
            position: absolute;
            opacity: 0;
        }

        input[type='radio']:checked + .radio-input {
            border-color: $cf-blue;
            &:after {
                transform: scale(1);
                transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
                opacity: 1;
            }
        }

        input[type='radio']:disabled {
            & + .radio-input {
                border-color: #7b7b7b;
                &:after {
                    background: #7b7b7b;
                    transform: scale(1) !important;
                }

                & + .radio-label {
                    color: #7b7b7b !important;
                }
            }
        }
    }

    .conditional-signing {
        .react-numeric-input {
            margin: 0;
        }
    }
} // .form-v2

.casefile-process-v2,
.widget {
    * {
        -webkit-font-smoothing: antialiased;
        // @extend .prevent-selection;
    }

    @include OpenSans-Regular();
    color: #7b7b7b;
    text-align: left;

    .header,
    .widget-header {
        @extend %cf-header;
    }

    .body,
    .widget-body {
        @extend %cf-container;

        > label:first-child {
            margin-top: 0;
        }
    }

    .footer,
    .widget-footer {
        @extend %cf-footer;

        .footer-content {
            width: 100%;
            display: inline-block;
            text-align: left;
            vertical-align: top;

            &.right {
                text-align: right;
            }
        }
    }

    &.medium {
        .body,
        .footer,
        .widget-body,
        .widget-foot,
        .widget-width {
            width: $cf-container-medium;
        }
    }
}

.casefile-process-body-header {
    margin-bottom: $default-margin;

    .count {
        float: right;
        line-height: 36px;
    }
}

/* Documents */

.document-list-v2-empty {
    text-align: center;
    position: relative;
    padding-top: $default-padding * 7;

    .document-list-empty-graphic {
        display: inline-block;
        color: $cf-blue;
        position: relative;

        .fa-file-alt {
            font-size: 28pt;
        }

        .fa-mouse-pointer {
            position: absolute;
            top: 65%;
            left: 115%;
            font-size: 14pt;
        }
    }

    h2 {
        /* Drag and drop files: */
        @include OpenSans-Regular();
        font-size: 14pt;
        color: #9b9b9b;
        text-align: center;
        font-weight: normal;
    }
}

.document-list-v2 > ul {
    overflow-y: auto;
    margin: 0;

    height: 400px;

    /* Rectangle 7: */
    background: #fefefe;
    border: 1px solid #e5e6eb;
    border-radius: 2px;
    padding: $default-padding;
}

.document-list-item-v2 {
    display: block;
    justify-content: space-between;
    align-items: center;
    margin-top: math.div($default-margin, 1.2);

    @include item;

    .filename-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .fa-file-pdf {
        font-size: 18pt;
        margin: 0 10px;
    }

    .fa-grip-vertical {
        font-size: 13pt;
        color: #cdcccd;
        cursor: move;
    }

    .filename {
        @include OpenSans-SemiBold();
        color: #4a4a4a;
    }

    .type-actions-container {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-left: $default-margin;
    }

    .actions-container {
        margin-left: $default-margin;
        position: relative;
        padding: 10px 0;

        .far {
            font-size: 14pt;
            cursor: pointer;
        }

        .fa-eye {
            position: relative;
            top: 1px;
        }

        .uploading-text {
            margin-right: $default-margin;
        }

        .fa-circle-notch {
            position: relative;
            top: 1px;
            cursor: auto;
        }
    }

    .type {
        .fas {
            margin-right: $default-margin * 0.5;
        }

        font-weight: bold;
        margin-right: $default-margin;
    }
}

.label-tooltip {
    background: $color-blue-main;
    border-radius: $default-radius;
    padding: $default-padding * 0.5 $default-padding;
    color: $color-white;

    &.tooltip--top::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%);
        margin-bottom: 15px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 7px solid $color-blue-main;
    }
}

.documents-requirements {
    background: transparentize($penneo-orange, 0.9);
    border-radius: $default-radius;
    border: 1px solid $penneo-orange;
    padding: $default-padding;
    margin-top: $default-margin;
    color: $text-dark;

    i {
        color: $penneo-orange;
    }

    .document-type-requirements-item {
        margin-top: $default-margin * 0.5;

        &:first-child {
            margin-top: 0;
        }
    }
}

// DocumentModalAdd.jsx
.document-type-container {
    margin-bottom: $default-margin;

    p {
        margin-top: calc($default-margin * 0.5);
        margin-bottom: calc($default-margin * 0.5);
    }

    label {
        margin: 0;
    }

    .document-type-header {
        margin-top: $default-margin;
    }
}

.document-type-list {
    overflow-y: scroll;
    max-height: 420px;
}

.signer-list-v2 {
    overflow: visible;
    margin: 0;
}

.box-gray {
    background: #fefefe;
    border: 1px solid #e5e6eb;
    border-radius: $default-radius;
    padding: $default-padding;
}

.box-blue {
    background: transparentize($cf-blue, 0.98);
    border: 1px solid transparentize($cf-blue, 0.6);
    border-radius: $default-radius;
    padding: $default-padding;
}

.signer-list-v2-empty {
    text-align: center;
    position: relative;

    .signer-list-empty-graphic {
        display: inline-block;
        color: $cf-blue;
        position: relative;

        i:first-child {
            font-size: 28pt;
        }

        i:last-child {
            position: absolute;
            top: 0%;
            left: 115%;
            font-size: 14pt;
        }
    }

    h2 {
        /* Drag and drop files: */
        @include OpenSans-Regular();
        font-size: 14pt;
        color: #9b9b9b;
        text-align: center;
        font-weight: normal;
    }
}

%blue-slanted-bg {
    background: $cf-blue;
    border-top-left-radius: $default-radius;
    border-top-right-radius: $default-radius;
    padding: 1.5em 2em;
    margin-left: -2em;
    margin-right: -2em;
    margin-top: -1em;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        height: 300px;
        width: 200px;
        background: darken($cf-blue, 3);
        top: -50%;
        transform: rotate(15deg);
        right: -50px;
        z-index: 1;
    }

    &:after {
        content: '';
        position: absolute;
        height: 300px;
        width: 200px;
        background: darken($cf-blue, 7);
        top: -50%;
        transform: rotate(15deg);
        right: -130px;
        z-index: 1;
    }
}

.casefile-modal-v2.ad-modal {
    text-align: center;

    .modal-ad-header {
        @extend %blue-slanted-bg;
    }

    .casefile-modal-ad-title {
        position: relative;
        z-index: 2;
        font-size: 14pt;
        color: $color-white;
        margin: 0;
    }
}

.casefile-modal-v2 {
    * {
        -webkit-font-smoothing: antialiased;
    }

    padding: 0 $default-padding;
    width: 440px;

    &.wide {
        width: 680px;
    }

    ul {
        margin: 0;
    }

    h4 {
        margin-top: $default-margin;
        margin-bottom: $default-margin * 0.5;
        font-weight: bold;
        color: black;
        font-size: 10.5pt;
    }

    &-title,
    label.casefile-modal-v2-title {
        @include OpenSans-Bold();
        margin-top: 0;
        text-align: center;
        width: 100%;
        margin-bottom: 0;
        padding-bottom: $default-padding * 0.5;

        @apply text-neutral-800 text-lg;
    }

    &-footer {
        position: relative;
        width: 100%;
        padding-top: $default-padding * 1.5;
        text-align: center;

        .left-link {
            cursor: pointer;
            text-align: left;
        }

        .right-link {
            cursor: pointer;
            text-align: right;
        }
    }

    text-align: left;
}

// Extended recipient modal (with restrictions)
.casefile-modal-v2.recipient-modal-extended {
    @media only screen and (min-width: 980px) {
        width: 500px;
    }
}

// Documents summary list
.casefile-summary-documents {
    display: flex;
    flex-wrap: wrap;
    padding: $default-padding;
    padding-bottom: 0;
}

.casefile-summary-document {
    display: inline-block;
    padding-bottom: $default-padding;
    width: 50%;

    &:nth-child(even) {
        padding-left: $default-padding * 0.5;
    }

    &:nth-child(odd) {
        padding-right: $default-padding * 0.5;
    }
}

.casefile-summary-document-content {
    @include item;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    height: 100%;
    word-break: break-all;
}

.casefile-summary-document-name {
    flex-grow: 1;
}

.tag-color {
    position: relative;
    top: 2px;
    display: inline-block;
    width: 1em;
    height: 1em;
    border-radius: 3px;
}

.document-type-legend {
    // Only give vertical spacing to the items in the second and greater
    // if the elements wrap.

    // @see https://stackoverflow.com/a/30891910/781779
    padding-top: $default-padding;
    padding-left: $default-padding;
    padding-right: $default-padding;

    margin-top: -$default-padding * 0.5;

    li {
        display: inline-block;
        border-radius: 2px;
        border-style: solid;
        border-width: 0px;
        // padding: $default-padding/6 $default-padding/4;
        margin-right: $default-margin;
        margin-top: $default-padding * 0.5;

        .tag-color {
            margin-right: $default-margin * 0.25;
        }
    }
}

label.underline-link {
    position: relative;
    display: inline !important;

    select {
        display: block !important;
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
    }
}

.casefile-messages {
    &.invalid {
        .casefile-messages-subject,
        .casefile-messages-message {
            label {
                color: $penneo-orange;
            }

            input {
                border-color: $penneo-orange;
            }

            textarea {
                border-color: $penneo-orange;
            }
        }
    }
}

.casefile-messages-header {
    margin-bottom: $default-margin;
}

.casefile-messages-tabs {
    ul {
        margin-top: $default-margin;
        margin-bottom: $default-margin;
        padding: 0;
        li {
            @include OpenSans-SemiBold();
            @include prevent-selection();
            font-size: 10pt;
            padding: math.div($default-padding, 1.5) $default-padding * 1.5;
            display: inline-block;
            vertical-align: top;
            background: $color-white;
            border: $default-border;
            border-right: none;
            position: relative;

            &:first-child {
                border-left: $default-border;
                border-top-left-radius: $default-radius;
                border-bottom-left-radius: $default-radius;
            }

            &:last-child {
                border-right: $default-border;
                border-top-right-radius: $default-radius;
                border-bottom-right-radius: $default-radius;
            }

            &:hover {
                color: $cf-blue;
            }

            &.active {
                border-right: 1px solid $cf-blue;
                color: $cf-blue;
                border-color: $cf-blue;

                + li {
                    border-left: none;
                }

                &.invalid {
                    color: $color-warning;
                    border: 1px solid $penneo-orange;

                    &:hover {
                        color: $penneo-orange;
                    }
                }
            }

            &.invalid {
                color: $color-warning;
                background-color: transparentize($penneo-orange, 0.9);

                &:hover {
                    color: $penneo-orange;
                }
            }
        }
    }
}

.casefile-messages-subject,
.casefile-messages-message,
.casefile-messages-recipients {
    position: relative;

    label {
        position: absolute;
        padding-top: $default-padding * 0.5;
        padding-left: $default-padding;
        margin-top: 0;
        z-index: 3;
        top: 1px;
        left: 1px;
        right: 1px;
        border-radius: 2px;
        background: $color-white;
        color: $gray-darker;
        text-transform: uppercase;
        font-size: 8.5pt;
    }

    input,
    textarea,
    .react-select__control {
        padding-top: $default-padding * 2;
    }
}

.casefile-messages-recipients {
    label {
        z-index: 6 !important;
    }

    .autocomplete-tags {
        &__control {
            padding: 0 12px 8px;
            padding-top: $default-padding * 2;
        }
    }
}

.casefile-messages-subject {
    input {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        z-index: 1;
        &:focus {
            z-index: 2;
        }
    }
}

.casefile-messages-recipients + .casefile-messages-subject {
    input {
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    input:focus {
        // Slight CSS hack to make a border appear only when focused without introducing a flicker
        box-shadow: inset 0 1px 0 0 $cf-blue;
    }
}

.casefiles-messages-templates {
    select {
        max-width: 480px;
    }
}

.casefile-messages-recipients.recipients-general {
    label,
    input[placeholder] {
        background: #fefefe;
        background: transparentize($cf-blue, 0.98);
    }

    input[placeholder] {
        font-weight: 600;
        font-size: 1.05em;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        &::placeholder {
            color: $gray-darker;
        }
    }
}

.casefile-messages-message {
    $textarea-height: 200px;
    $textarea-padding: $default-padding * 2;

    position: relative;
    margin-bottom: 0;
    padding-bottom: 0;
    label {
        top: 0;
    }
    textarea {
        resize: none;
        position: relative;
        top: -1px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        min-width: 100%;
        height: calc(#{$textarea-height} - #{$textarea-padding});
        padding-top: $textarea-padding;
        z-index: 1;
        &:focus {
            z-index: 2;
        }
    }

    &.with-footer-notice {
        textarea {
            height: $textarea-height;
            padding-bottom: $textarea-padding;
        }
    }

    .casefile-message-signature {
        position: absolute;
        left: 1px;
        padding-left: $default-padding;
        right: 1px;
        bottom: 2px;
        background: $color-white;
        @include OpenSans-SemiBold();
        padding-top: $default-padding * 0.5;
        z-index: 3;
        font-size: 9pt;
    }
}

.vertical-center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

$progress-highlight-color: #10bac2;
$progress-background-color: #b5e9ec;
$progress-step-indicator-radius: 8px;

$step-link-color: #b9b9b9;

.progress-navigation {
    margin: 0 auto;
    position: relative;
    padding-bottom: 0.5em;

    > ul {
        width: 100%;
        padding: 0;
        margin: 0;
        text-align: center;
    }

    .step {
        font-size: 11.5pt;
        // width will be set via javascript. depends on number of items.
        display: inline-block;
        text-align: center;
        position: relative;
        z-index: 0;
    }

    .progress-indicator {
        position: relative;
        margin: 0 10px;
        .line {
            line-height: 11.5pt;
            vertical-align: middle;
            display: inline-block;
            width: $default-margin;
            height: 2px;
            margin-left: math.div($default-margin, 3);
            background: $gray-label;
            &:first-child {
                margin-left: 0;
            }
        }
    }

    .done-indicator {
        left: 50%;
        width: 15px;
        height: 15px;
        margin-left: -7px;
        margin-top: 3px;
        opacity: 0;
        background: $cf-blue;
        border-radius: 50%;
        position: absolute;

        i {
            width: 15px;
            position: relative;
            vertical-align: top;
            color: #fff;
            font-size: 9px;
            left: 0px;
            top: 3px;
        }
    }

    .step-link {
        color: $gray-label;
        font-weight: bold;

        border-radius: $default-radius;
        padding: $default-padding * 0.25 math.div($default-padding, 1.5);

        i {
            transform: scale(0.9);
            margin-right: $default-margin * 0.25;
        }
    }

    .done {
        .done-indicator {
            opacity: 1;
        }

        .progress-indicator {
            .line {
                background: $cf-blue;

                &:first-child {
                    transform: scaleX(3);
                    transform-origin: left;
                }
            }
        }

        .step-link {
            color: $cf-blue;
        }
    }

    .done.error {
        .done-indicator {
            transform: scale(1.2);

            i {
                transform: scale(0.9);
                top: 2px;
                color: $color-white;
            }
            background: $penneo-orange;
        }

        .line {
            background: $penneo-orange;
        }

        .step-link {
            color: darken($penneo-orange, 5);
        }
    }

    .active {
        .progress-indicator {
            .line {
                background: $cf-blue;
            }
        }

        .step-link {
            color: $color-white;
            font-weight: bold;
            background: $cf-blue;
        }
    }
}

.input-validation {
    position: relative;

    &.input-validation-error {
        input {
            border-color: $penneo-yellow;
        }

        // If wrapping a <label> containing the input field, it removes the
        // bottom spacing to visually attach the error message to the field
        label:only-child {
            padding-bottom: 0;
        }
    }

    .input-validation-message {
        position: relative;
        top: -1px;
        background: $penneo-yellow;
        color: darken($penneo-yellow, 45);
        font-weight: bold;
        font-size: 9pt;
        padding: $default-padding * 0.25 $default-padding * 0.5;
        width: 100%;
        display: inline-block;
        border-bottom-right-radius: $default-radius;
        border-bottom-left-radius: $default-radius;
    }
}

.collapsing-box {
    background: #fefefe;
    border: 1px solid #e5e6eb;
    border-radius: 2px;
    background: transparentize($cf-blue, 0.98);
    border: 1px solid transparentize($cf-blue, 0.6);
    @include transition(0.2s, 'background');

    &.collapsed {
        background: transparentize($cf-blue, 0.92);
        border: 1px dashed $cf-blue;

        &:hover {
            background: transparentize($cf-blue, 0.98);
        }

        .collapsing-box-content {
            display: none;
        }

        .collapsing-box-header {
            @include prevent-selection();
            border-bottom: none;
        }
    }

    &-content {
        padding: $default-padding;
        padding-top: $default-padding * 0.5;
    }

    &-header-title {
        color: $cf-blue;
        @include OpenSans-SemiBold();
        display: inline-flex;
        flex-direction: row;
        align-items: center;
    }

    &-header .pull-right {
        font-size: 9pt;
        color: $cf-blue;
        @include OpenSans-SemiBold();

        i {
            position: relative;
            top: 2px;
            font-size: 11pt;
        }
    }

    &-header {
        border-bottom: 1px dashed $cf-blue;
        padding: $default-padding;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.message-editor-container {
    margin-top: $default-margin;
}

.recipient-autocomplete-tags {
    z-index: 4;

    .autocomplete-tags {
        &__control {
            @apply rounded-b-none;
        }
    }
}
.form-v2 .legend-label,
.ui-v2 .legend-label,
.legend-label {
    margin: 0 6px 0 0;
    padding: 2px;
    &-circle {
        min-height: 8px;
        min-width: 8px;

        margin-right: 8px;
        border-radius: 999px;
        background-color: #ccc;

        display: inline-block;
    }
}

//Hide arrow for details element in safari
details > summary {
    list-style: none;
}

details > summary::-webkit-details-marker {
    display: none;
}
