@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.casefile-status {
    border-radius: $default-radius;
    display: inline-block;
    font-size: 85%;
    padding: $default-padding * 0.25 $default-padding * 0.5;
    text-align: center;
    width: 100%;

    border: 1px solid transparentize($gray-dark, 0.6);
    background: transparentize($gray-dark, 0.6);
    color: darken($gray-dark, 30);

    &-name {
        @include OpenSans-Bold();
    }

    &-explanation {
        @include OpenSans-SemiBold();
    }

    &.cyan {
        border: 1px solid transparentize(#5abede, 0.6);
        background: transparentize(#5abede, 0.6);
        color: darken(#5abede, 30);

        .casefile-document-details-status-name {
            border-right-color: darken(#5abede, 10);
        }
    }

    &.yellow {
        background: $penneo-yellow;
        color: darken($penneo-yellow, 50);

        .casefile-document-details-status-name {
            border-right-color: darken($penneo-yellow, 20);
        }
    }

    &.green {
        border: 1px solid transparentize($penneo-green, 0.6);
        color: darken($penneo-green, 20);
        background: transparentize($penneo-green, 0.7);

        .casefile-document-details-status-name {
            border-right-color: darken($penneo-green, 2);
        }
    }

    &.red {
        background: $color-error;
        color: $color-white;

        .casefile-document-details-status-name {
            border-right-color: darken($color-error, 12);
        }
    }

    .tooltip-text {
        min-width: 180px !important;
    }
}
