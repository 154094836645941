.form-builder {
    .first-title {
        margin-top: 0;
    }

    .input-element {
        display: inline-block;
        vertical-align: top;
        margin: $default-margin;
        margin-left: 0;
        margin-bottom: 0;
        .label {
            display: inline-block;
            margin-bottom: $default-margin * 0.5;
            font-size: $small-font-size;
            font-weight: bold;
        }

        .checkbox-wrapper {
            background: $gray-lighter;
            border-radius: $default-radius;
            padding: math.div($default-padding, 1.375);
            padding-left: $default-padding * 1.3;
            border: 1px solid $gray-border;
        }

        &.mt0 {
            margin-top: 0;
        }

        .x2 {
            width: 209%;
        }
    }

    .button-input {
        position: relative;
        input {
            padding-right: $default-padding * 2;
        }

        i {
            cursor: pointer;
            display: block;
            position: absolute;
            right: $default-margin * 0.5;
            top: math.div($default-margin, 1.4);
            font-size: 110%;
            &:hover {
                color: $cf-blue;
            }
        }
    }

    .multiple-selection-options {
        display: inline-block;
        margin-top: $default-margin * 2.4;
        font-size: 95%;
        .multiple-selection-option {
            cursor: pointer;
            border-radius: 2px;
            background: $gray-lighter;
            margin-right: $default-margin * 0.5;
            border: 1px solid $gray-border;
            padding: $default-padding * 0.25 $default-padding * 0.5;
            padding-left: $default-padding * 0.25;

            &:hover {
                background: $color-error;
                color: $color-white;
                border-color: darken($color-error, 20);

                .multiple-selection-option-number {
                    border-color: darken($color-error, 20);
                }
            }
        }

        .multiple-selection-option-number {
            font-weight: bold;
            font-size: 90%;
            margin-right: $default-margin * 0.5;
            padding: $default-padding * 0.25;
            padding-right: $default-margin * 0.5;
            border-right: $default-border;
        }
    }

    .email-template-preview {
        display: block;
        border: $default-border;
        border-radius: $default-radius;
        background: $gray-light;
        padding: $default-padding;
        h3 {
            margin: 0;
            position: relative;
            top: -5px;
            font-size: $small-font-size;
        }
        label {
            font-size: $small-font-size;
            position: relative;
            top: 2px;
            margin-bottom: 0;
        }
    }

    .title-link {
        position: relative;
        margin-left: $default-margin;
        font-size: $small-font-size;
        text-decoration: underline;
    }

    .folder-selector {
        max-width: 480px;
        margin-bottom: $default-margin;
    }
}

.form-builder-entry {
    @extend %match-parent;
    border-top: 1px solid $gray-border;
    padding: $default-padding * 0.5;
    padding-left: $default-padding * 2.5;
    padding-top: 0;
    box-sizing: border-box;
    position: relative;
    &:before {
        @include transition(0.2s);
        content: '';
        position: absolute;
        display: block;
        left: -5px;
        opacity: 0;
        background: $color-blue-highlight;
        height: 100%;
        width: 2px;
    }

    &:hover:before {
        opacity: 0.8;
    }

    .entry-title {
        margin: $default-margin 0;
    }
    &:last-child {
        border-bottom: 1px solid $gray-border;
    }

    input[type='text'],
    select {
        font-size: 95%;
    }

    label {
        margin-top: 0;
    }

    .drag-handle {
        height: 100%;
        padding: $default-padding * 0.5;
        position: absolute;
        left: $default-padding * 0.25;
        display: inline-block;
        vertical-align: middle;
        opacity: 0.5;

        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;

        i {
            top: 50%;
            position: relative;
            transform: translateY(-50%);
        }

        &:hover {
            opacity: 0.8;
        }

        &.grab-active {
            cursor: grabbing;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
        }
    }
}

.custom-title-template {
    button {
        margin-top: 1px;
    }

    select {
        width: auto;
        padding-right: 2em;
    }
}
