@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.recipient-modal-role-list {
    clear: both;
    max-height: 170px; // Fits 5 items and cuts off at a point where it's visible that there are more items in the list.
    overflow: auto;
}

.recipient-custom-roles {
    margin-left: -($default-margin * 2);
    margin-right: -($default-margin * 2);
    max-height: 220px;
    overflow: auto;

    > table {
        width: 100%;

        th,
        td {
            padding: ($default-padding * 0.5) 0;

            &:first-child {
                padding-left: $default-margin * 2;
            }

            &:last-child {
                padding-right: $default-margin * 2;
            }
        }

        > thead {
            th {
                background: $color-white;
                color: #717981;
                font-size: 0.935em;
                position: sticky;
                text-align: left;
                top: 0;
                z-index: 2;
            }

            > tr > th {
                padding-top: 0;
            }
        }

        > tbody {
            > tr {
                &:nth-child(even) {
                    background: $gray-light;
                }

                > td {
                    width: 50%;
                }
            }
        }
    }

    .recipient-custom-role-label {
        font-size: 1em;
        font-weight: 400;
    }
}

.custom-role-and-activation-date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: $default-margin;
    margin-bottom: $default-margin;
}

.activation-date-container {
    &:not(:last-child) {
        margin-bottom: $default-margin * 0.5;
    }
}

.activation-date-role-name {
    margin-bottom: $default-margin * 0.5;
    font-weight: 600;
}

.activation-date-picker {
    display: flex;
    flex-direction: row;
    align-items: center;

    .clear-date-btn {
        margin-left: $default-margin * 2;
    }
}

.sms-input-container {
    display: flex;
    gap: 1rem;
}
