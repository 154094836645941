@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.dashboard-new-widgets-grid {
    display: flex;
    flex-direction: column;
    gap: 0;

    @media only screen and (min-width: 980px) {
        display: grid;
        grid-template-columns: repeat(2, calc(50% - $default-padding * 0.5));
        grid-template-rows: repeat(3, auto);
        grid-column-gap: $default-padding * 0.5;
        grid-row-gap: $default-padding * 0.5;
    }

    @media only screen and (min-width: 1200px) {
        grid-template-columns: repeat(3, calc(33.3% - $default-padding * 0.5));
        grid-template-rows: repeat(2, auto);

        .my-pending-signatures-widget {
            grid-row: 1/3;
            grid-column: 3/4;
        }
    }
}

.toast-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    opacity: 0;
    transform: translateY(-20px);
}

.toast-container.show {
    opacity: 1;
    transform: translateY(0);
}

.casefile-sent-notification {
    background: $color-white;
    padding: $default-padding * 2 $default-padding * 3;
    margin-top: -10px;
    box-shadow: $card-shadow-hover;

    &-heading {
        font-size: $title-font-size;
        font-weight: bold;
        margin: 0;
        color: $penneo-green;
    }
}
