@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.debug-information-content {
    padding: $default-padding 0;

    label {
        padding-top: $default-padding;
        padding-bottom: math.div($default-padding, 1.5);
        &:first-child {
            padding-top: 0;
        }
    }
}
