@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.folder-tree-item {
    cursor: pointer;
    position: relative;
    padding: math.div($default-padding, 6) $default-padding * 0.5;
    padding-right: 0;
    outline: none;

    .folder-tree-item-content {
        position: relative;
        @include transition(0.2s);
        border-radius: $default-radius;
        padding: math.div($default-padding, 3) math.div($default-padding, 1.5);
        border: 1px solid transparent;
        color: $text-dark;
    }

    .folder-tree-item {
        padding-left: $default-padding * 1.5;
    }

    &.active {
        > .folder-tree-item-content {
            background: transparentize($cf-blue, 0.95);
            border: 1px solid transparentize($cf-blue, 0.6);
            color: $cf-blue;
        }
    }

    & > .folder-tree-item-content:hover {
        background: transparentize($cf-blue, 0.95);
    }

    &.open {
        overflow: hidden;

        > .folder-tree-item-content i {
            color: $cf-blue;
        }

        > .folder-tree-item-content {
            &:before {
                content: '';
                top: 32px;
                left: 14px;
                height: 9999px;
                width: 1px;
                margin-bottom: -5px;
                background: $gray-medium;
                position: absolute;
            }
        }
    }
}
