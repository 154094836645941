@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.recipient-list-item-name {
    @include OpenSans-Bold();
    color: $text-dark;
    display: inline-block;
    margin-right: $default-padding * 0.5;
}

.recipient-list-item-email {
    display: inline-block;
    font-size: 95%;
    word-break: break-all;
}

.recipient-list-item-type {
    @include OpenSans-SemiBold();
    right: $default-padding;

    display: flex;
    flex-direction: column;
    gap: 4px;

    .legend-label {
        margin: 0 !important;
    }
}

.recipient-list-item-access {
    position: relative;
    @include OpenSans-SemiBold();
}

.recipient-list-item-wrapper {
    display: flex;
    column-gap: $default-padding;
}

.recipient-list-item-main {
    flex: 1;
}

.recipient-list-item-behalf {
    font-weight: 600;
    color: $text-medium;
    font-size: $small-font-size;
    margin-top: $default-padding * 0.25;
}
