@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.api-key-management-form {
    max-width: 680px;

    .button {
        margin-bottom: 2px;
    }

    &-input {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: $default-margin;

        & > :first-child {
            flex: 1;
            margin-right: $default-margin * 0.5;
        }
    }
}
