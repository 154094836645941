/**********************************************************

    - inputs.scss (partial)
    Collection of Input Styles used in the application
    Any new input type to be implemented should inherit
    default values from this file.
    - Index - (Seach for 'n:' to navigate to section)

    1. - Input Base Styles
    2. - Buttons
    3. - Checkbox
    4. - Radio
    5. - Dropdown
**********************************************************/

/** 1: Input Base Styles
=========================================================*/

// Input placeholder text
::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $text-light;
}
::-moz-placeholder {
    /* Firefox 19+ */
    color: $text-light;
}
:-ms-input-placeholder {
    /* IE 10+ */
    color: $text-light;
}
:-moz-placeholder {
    /* Firefox 18- */
    color: $text-light;
}

/// Base Styling for Inputs
/// @require %input-validations
/// @group inputs
%input-base {
    @include transition(0.2s, 'border-color, background-color');
    -webkit-appearance: none; // Fix iOS Styling
    font-size: $default-font-size;
    padding: $input-padding;
    position: relative;
    border-radius: $default-radius;
    background: $color-white;
    color: $text-dark;
    box-sizing: border-box;
    border: $default-border;
    outline: none;
    height: auto;
    line-height: normal;

    &:focus {
        border-color: $color-blue-highlight;
        outline: none;
    }

    &:disabled {
        background: $gray-light;
        color: $text-medium;
        border-color: $gray-border;
    }
}

/// Adds error, success and warning states to an input.
/// It has to be used as an @extend inside an input element's style.
/// The basic button element already has this implemented.
/// @example
///     #input { @extend %input-validations; }
/// @group inputs
%input-validations {
    &.error {
        border-color: rgba(255, 0, 40, 0.4);
        background: rgba(255, 0, 40, 0.05);
        // box-shadow: 0 0 0 1px $color-error;
        &:focus {
            outline: none;
            border-color: rgba(255, 0, 40, 0.4);
            background: $color-white;
            position: relative;
        }
    }
    &.success {
        border-color: $color-success;
    }
    &.warning {
        border-color: $color-warning;
    }
}

%text-input {
    @extend %input-base;
    @extend %input-validations;
}

%select {
    @extend %input-base;
    @extend %input-validations;
    -webkit-appearance: none; // Override default style in Safari/iOS

    background-repeat: no-repeat;
    background-position: right;
    background-size: 18px;

    background-image: url(#{$images}/arrow-down.png);
    padding-right: $default-padding * 2 !important;

    // Fix Firefox Appearance
    // @see: https://gist.github.com/joaocunha/6273016/
    // (Removes arrow and accounts for white space)
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';

    // Fix IE 10+
    // Removes arrow
    &::-ms-expand {
        display: none;
    }
}

/** 2: Buttons
=========================================================*/

/** 1: Input Group
=========================================================*/

/// Used to Surround Checkboxes and Radio Inputs
/// Creates a box that contains the input element's options
/// @example
///     <div className="input-group">
///         <input type="checkbox" value="demo" name="example">
///         ...
///     </div>
/// @group inputs
%input-group {
    background: $color-white;
    border: $border-light;
    border-radius: $default-radius;
    display: inline-block;
    label {
        cursor: pointer;
        padding: math.div($default-padding, 1.2) $default-padding;
        border-bottom: $border-light;
        padding-right: $default-padding * 1.5;
        display: block;
        &:last-child {
            border-bottom: none;
        }
    }
}

/** 3: Radio Button & Checkbox
=========================================================*/

%toggle-style {
    display: inline-block;
    margin-left: 0;
    margin-right: $default-margin;
    width: 1em;
    height: 1em;
    margin-top: -0.65em;
    vertical-align: middle;
    position: relative;
    &:hover:after {
        background: $gray-lighter;
    }
    &:checked:hover:after {
        background: $color-blue-highlight;
    }
    &:checked:after {
        background: $color-blue-highlight;
    }
    &:checked:before {
        border-color: $color-blue-highlight;
    }
}

/// Radio Input
%radio-input {
    @extend %toggle-style;
    outline: none;
    position: relative;
    top: 3px;
    left: 2px;
    &:before {
        content: '';
        display: block;
        visibility: visible;
        border: 3px solid $gray-border;
        background: $color-white;
        position: relative;
        // top: -2px;
        // left: -2px;
        // width:100%;
        // height:100%;
        border-radius: 50%;
        transition: $default-transition;
    }
    &:after {
        content: '';
        display: block;
        visibility: visible;
        position: absolute;
        background: transparent;
        height: 100%;
        left: -0.3em;
        top: -0.3em;
        width: 100%;
        border-radius: 50%;
        transform: scale(0.72, 0.72);
        transition: $default-transition;
    }
}

%checkbox-input {
    @extend %toggle-style;
    outline: none;
    &:before {
        display: block;
        visibility: visible;
        content: '';
        width: 100%;
        border-radius: $default-radius;
        height: 100%;
        border: 3px solid $gray-border;
        background: $color-white;
        transition: all 0.3s ease;
    }
    &:after {
        display: block;
        font-family: FontAwesome;
        content: '\f00c';
        top: 0;
        position: absolute;
        margin: 3px;
        visibility: visible;
        top: 0;
        color: transparent;
        transition: all 0.3s ease;
    }
    &:hover:after {
        background: none;
        color: $gray-lighter;
    }
    &:checked:hover:after {
        background: none;
        color: $color-blue-highlight;
    }
    &:checked:after {
        background: none;
        color: $color-blue-highlight;
    }
}

// Search Bar
%search-bar {
    position: relative;
    &:before {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        position: absolute;
        padding: $default-padding;
        left: -2px;
        margin-top: -1px;
        content: '\f002';
        z-index: 2;
        opacity: 0.7;
        top: -10px; // fixes rendering problems in Firefox
    }
    input {
        position: relative;
        min-width: 250px;
        z-index: 1;
        padding-left: $default-padding * 2.4 !important;
    }
}

/** 3: Apply Input Styles
=========================================================*/

a.button {
    display: inline-block;
}

select {
    @extend %select;
}

label {
    display: inline-block;
    color: $text-dark;
    padding-bottom: $default-margin * 0.5;
}

// Text Style Inputs
textarea,
input[type='text'],
input[type='email'],
input[type='number'],
input[type='date'],
input[type='datetime'],
input[type='password'],
.text-input {
    @extend %text-input;
}

.input-group {
    @extend %input-group;
}

.file-input,
.form .file-input {
    display: inline-block;
    input.file {
        position: fixed;
        top: -10000px;
    }

    &-details {
        display: inline-block;
        vertical-align: middle;
    }
}

.search-bar {
    position: relative;
    @extend %search-bar;
}

.validation-message {
    margin: 0;
    padding: math.div($default-padding, 3);
    color: $text-medium;
    font-size: $small-font-size;
    background: $gray-medium;
    border-radius: $default-radius;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top-width: 0;
    position: relative;
}

.validation-badge {
    @include transition(0.3s);
    margin-left: $default-margin * 0.5;
    background: $gray-medium;
    font-size: $small-font-size;
    padding: $default-padding * 0.25 $default-padding * 0.5;

    &.warning {
        color: $text-dark;
        background: $color-yellow-highlight;
    }

    &.error {
        color: $color-white;
        background: $color-error;
    }
}

.custom-checkbox:not(#exclude-old-browsers) {
    @extend .prevent-selection;
    @include OpenSans-Regular();
    font-size: 10.8pt;
    color: #666;
    letter-spacing: 0.15px;
    display: block;
    margin-top: 0;
    margin-bottom: 0;

    display: inline-block;

    // This will keep the checkbox tab-accessible
    & > input[type='checkbox'] {
        margin: 0; // Prevent checkbox element from creating extra space between checkbox and label
        height: 1px;
        width: 1px;
        opacity: 0;
    }

    & > .check {
        @include transition(0.3s);
        position: relative;
        top: 2px;
        display: inline-block;
        width: 1.2em;
        height: 1.2em;
        vertical-align: top;
        border: $default-border;
        border-width: 1px;
        border-radius: 2px;
        background: $color-white;
        border-color: $text-light;
        opacity: 1;

        &:after {
            display: none;
        }

        & .fa-check {
            opacity: 0;
            @include transition(0.3s);
            position: absolute;
            font-size: 85%;
            top: 0.18em;
            left: 0.15em;
            color: $cf-blue;
        }
    }

    /* When the text is after the checkbox,
       don't give the checkbox a left margin
       (To left align properly with other elements) */
    & > .check + * {
        margin: 0 $default-margin * 0.5;
    }

    /* Apply the margins on the checkbox if
       the text is displayed before the checkbox */
    & > span + input + .check {
        margin: 0 $default-margin * 0.5;
    }

    /* Checked and focused */
    & > input[type='checkbox']:focus + .check {
        border-color: $cf-blue !important;
        opacity: 1;
    }

    /* Checked */
    & > input[type='checkbox']:checked + .check {
        opacity: 1;
        background: transparent;
        border-color: $text-light;
        & .fa-check {
            opacity: 1;
            color: $cf-blue;
        }
    }

    /* Hover & Checked */
    &:hover > input[type='checkbox']:checked + .check {
        border-color: $cf-blue;
    }

    /* Hover & Checked */
    &:hover > input[type='checkbox'] + .check {
        border-color: $cf-blue;
    }

    &.disabled {
        cursor: not-allowed;

        .check {
            background: $gray-medium !important;
        }
    }

    &.disabled:hover {
        .check {
            border-color: $text-light !important;
        }
    }
}
