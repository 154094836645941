.manage-user-credentials {
    .credentials-list {
        padding: 0;
        list-style: none;
        li {
            .fa.fa-circle {
                font-size: 60%;
                color: $color-blue-highlight;
                position: relative;
                top: -2px;
                margin-right: $default-margin * 0.5;
            }

            font-weight: bold;
            padding-bottom: math.div($default-padding, 1.5);
        }
    }
}
