@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.customer-feature-toggle-content {
    display: flex;
    flex-direction: row;
    align-items: center;

    h3,
    p {
        margin: 0;
    }

    .feature-icon {
        padding-right: $default-padding;
    }

    .feature-description {
        flex: 1;
    }

    .feature-switch-icon {
        font-size: 1.8em;
    }
}

.customer-feature-toggle-options {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: $default-margin;
    width: 100%;

    .customer-feature-toggle-option {
        display: inline-block;
        padding: $default-padding * 0.5 0;

        &-button {
            border-radius: $default-radius;
            background: transparentize($gray-medium, 0.95);
            border: 1px solid $gray-border;
            padding: $default-padding * 0.5 $default-padding;
            color: $gray-dark;
            @include OpenSans-SemiBold();

            &:hover {
                border: 1px solid transparentize($cf-blue, 0.2);
            }
        }

        &.enabled {
            .customer-feature-toggle-option-button {
                color: $cf-blue;
                padding: $default-padding * 0.5 $default-padding;
                background: transparentize($cf-blue, 0.95);
                border: 1px solid $cf-blue;
                @include OpenSans-SemiBold();
            }
        }
    }
}
