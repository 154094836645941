.login-widget-local {
    margin-top: $default-margin * 2;
    padding: $default-padding;
    background: $color-white;
    border-radius: $default-radius;

    .cta a {
        display: inline-block;
        margin: 0 $default-margin;
        margin-top: $default-margin * 0.5;
        text-transform: uppercase;
        font-weight: bold;

        &:hover {
            text-decoration: underline;
        }
    }

    .success {
        color: $color-blue-highlight;
    }

    .cancel {
        position: absolute;
        bottom: $default-padding;
        right: $default-padding;
    }
}
