@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.embed-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
    @media only screen and (min-width: 980px) {
        max-width: 1170px;
    }
}
