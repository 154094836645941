$sidebar-settings-width: 250px;

.settings-sidebar {
    border-right: 1px solid #ccc;
    width: $sidebar-settings-width;
    padding: $default-padding;
    box-sizing: border-box;
}

.settings-content {
    top: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    right: 0;
    padding: $default-padding;
    box-sizing: border-box;
}

.settings-sidebar ~ .settings-content {
    left: $sidebar-settings-width;
}
