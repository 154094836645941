.user-dropdown {
    .avatar {
        text-transform: uppercase;
        display: inline-block;
        color: $gray-darker;
        background: $gray-medium;
        font-weight: bold;
        margin: 0 $default-margin * 0.5;
        line-height: 36px;
        height: 36px;
        width: 36px;
        text-align: center;
        border-radius: 50%;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.025);

        span {
            font-weight: 600;
            font-size: 12px;
        }
    }

    .name {
        @include OpenSans-Regular();
        color: $text-dark;
    }

    .dropdown-content {
        ul {
            right: 1px;
            width: 100%;
            min-width: 200px;
            padding: $default-padding $default-padding * 1.5;
            padding-top: 0;

            .dropdown-element {
                padding-top: $default-padding;
            }
        }
    }
}
