.form-sidebar {
    input,
    .input-group,
    textarea {
        @extend %match-parent;
    }

    textarea {
        max-width: 100%;
        min-height: 160px;
        resize: none;
    }

    .ant-calendar-picker {
        @extend %match-parent;
    }

    .ant-input {
        @extend %text-input;
    }

    .field-label {
        color: $text-medium;
        font-weight: bold;
        font-size: 95%;
        margin-top: $default-margin;
        margin-bottom: $default-margin * 0.25;
    }

    .required {
        position: relative;
        bottom: 1px;
        font-size: 0.7em;
    }

    .optional-flag {
        display: none;
        color: $text-medium;
        font-size: $medium-font-size;
        position: relative;
    }

    .note {
        font-size: $small-font-size;
        opacity: 0.7;
    }
}

.form-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 55px;
    background: $color-white;
    border-top: 1px solid $gray-dark;
    transform: none;

    .buttons {
        position: absolute;
        top: 10px;
        right: 10px;
        bottom: 0;
        .button-wrapper {
            pointer-events: all;
            display: inline-block;
            width: 190px;
            margin-left: 10px;
        }
    }
    .progress {
        position: relative;
        margin: $default-margin;
        font-weight: bold;
        top: 5px;
        color: $text-light;
        li {
            display: inline-block;
            &.active {
                color: $text-dark;
            }
            &:after {
                content: '>';
                margin: 0 $default-margin;
            }
            &:last-child:after {
                content: '';
            }
        }
    }

    @media #{$breakpoint-medium} {
        position: relative;
        background: none;
        height: auto;
        border-top: none;
        bottom: auto;
        left: auto;
        width: 100%;
        .buttons {
            position: relative;
            top: auto;
            right: auto;
            bottom: auto;
            .button-wrapper {
                position: relative;
                display: block;
                width: 100%;
                margin: $default-margin 0;
            }
        }
        .progress {
            display: none;
        }
    }
}
