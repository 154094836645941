.trash-container {
    .fa-recycle {
        color: darken($color-success, 15%);
    }

    .text-center .header-text {
        color: darken($color-success, 15%);
    }

    .column-1 {
        padding-left: 4em;
    }

    .react-modal-body {
        padding: 2em;
    }

    .pr {
        padding-right: 1em;
        display: inline-block;
    }

    .selected {
        background: transparentize($color-yellow-highlight, 0.6);
    }

    a {
        color: $color-blue-highlight;
    }

    td {
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
    }

    // checkbox column
    .column-checkbox {
        max-width: 32px;
        > * {
            white-space: nowrap;
        }

        text-align: left;
        border-right: none;
        padding-right: 0;
        padding-left: $default-padding;
    }
}
