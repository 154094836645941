.fallback-message {
    * {
        -webkit-font-smoothing: antialiased;
    }

    position: relative;
    height: 100%;
    width: 100%;
    padding-bottom: $default-padding * 3;
    text-align: center;
    background: $color-blue-main;
    color: #fff;

    h1 {
        font-size: 18pt;
    }

    .browser-window {
        display: inline-block;
        border-radius: 5px;
        height: 420px;

        .border-container {
            border: 12px solid #3a5164;
            border-radius: 8px;
        }

        .browser-header {
            text-align: left;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            overflow: hidden;
            padding: $default-padding * 0.5;
            background: #ccc;

            .yellow {
                color: #ffbd2e;
            }

            .red {
                color: #ff6059;
            }

            .green {
                color: #29c941;
            }
        }

        .image {
            position: relative;
            width: 700px;
            height: 390px;
            overflow: hidden;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            img {
                width: 100%;
            }

            .banner-bg {
                background: $color-blue-highlight;
                width: 100%;
                height: 50px;
                opacity: 0.5;
                text-transform: uppercase;
                position: absolute;
                bottom: 100px;
            }
            .banner {
                width: 100%;
                text-transform: uppercase;
                position: absolute;
                bottom: 100px;
            }
        }
    }

    .alternatives {
        button {
            margin-bottom: 10px;
        }
        button.yellow {
            color: #665322;
            background: #ffbd2e;
        }
        button.orange {
            color: #fff;
            background: #d35527;
        }

        button.blue {
            color: #fff;
            background: $color-blue-highlight;
        }

        li {
            vertical-align: top;
            display: inline-block;
            margin: $default-margin * 1.5;
            margin-bottom: $default-margin * 0.5;

            a {
                color: $color-white;
                text-decoration: none;
            }
        }

        i {
            font-size: 48pt;
        }

        a {
            color: $color-blue-highlight;
            text-decoration: underline;
        }
    }
}
