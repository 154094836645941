@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.signature-settings {
    padding-top: 16px;

    .access-method-table,
    .insecure-signing-method-table {
        display: flex;
        flex-direction: column;

        max-width: 480px;

        .method {
            .method-setting {
                display: inline-flex;
                flex-direction: row;
                width: 100%;

                .custom-checkbox {
                    flex-basis: 100%;
                }

                .method-label {
                    display: inline-flex;
                    flex-direction: row;
                    @apply text-neutral-900;

                    .logo {
                        margin: auto 8px auto 10px;
                        width: 17px;
                        height: 17px;

                        &.logo-itsme\.be {
                            transform: scale(1.7);
                        }

                        &.logo-sms {
                            width: auto;
                            height: 17px;

                            transform: scale(1.1);

                            padding-left: 3px;
                            padding-right: 3px;
                        }

                        &.logo-bankid_se {
                            transform: scale(1.8);
                        }
                    }
                }

                .method-type {
                    color: #0062b3;
                    background: #e7f2fd;
                    padding: 4px 6px;
                    border-radius: 4px;
                    text-align: center;
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: 0;
                    margin: auto 0 auto 16px;

                    &.spacer {
                        opacity: 0;
                        color: transparent;
                        background: none;
                    }
                }

                .custom-checkbox {
                    border: none;
                    background: none;
                    padding: 0 0 0.5em 0;
                    margin-right: 0;
                }
            }

            .info {
                margin-top: 0;
                margin-left: 17px;
            }
        }

        .sub-method {
            padding-left: calc(28px + 0.5em);
            grid-template-columns: max-content;

            a {
                text-decoration: underline;
            }
        }
    }

    .insecure-signing-method-table {
        .method {
            .info {
                background: $gray-lighter;
                color: $text-dark;
            }
        }
    }
}
