/* Aditional classes for Fontawesome icons */

/*
    Animations
    DOCS: https://fontawesome.com/docs/web/style/animate
    Source: https://raw.githubusercontent.com/FortAwesome/Font-Awesome/6.x/css/all.css
*/

.fa-beat {
    -webkit-animation-name: fa-beat;
    animation-name: fa-beat;
    -webkit-animation-delay: var(--fa-animation-delay, 0s);
    animation-delay: var(--fa-animation-delay, 0s);
    -webkit-animation-direction: var(--fa-animation-direction, normal);
    animation-direction: var(--fa-animation-direction, normal);
    -webkit-animation-duration: var(--fa-animation-duration, 1s);
    animation-duration: var(--fa-animation-duration, 1s);
    -webkit-animation-iteration-count: var(
        --fa-animation-iteration-count,
        infinite
    );
    animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
    animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
    -webkit-animation-name: fa-shake;
    animation-name: fa-shake;
    -webkit-animation-delay: var(--fa-animation-delay, 0s);
    animation-delay: var(--fa-animation-delay, 0s);
    -webkit-animation-direction: var(--fa-animation-direction, normal);
    animation-direction: var(--fa-animation-direction, normal);
    -webkit-animation-duration: var(--fa-animation-duration, 1s);
    animation-duration: var(--fa-animation-duration, 1s);
    -webkit-animation-iteration-count: var(
        --fa-animation-iteration-count,
        infinite
    );
    animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, linear);
    animation-timing-function: var(--fa-animation-timing, linear);
}

@-webkit-keyframes fa-beat {
    0%,
    90% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    45% {
        -webkit-transform: scale(var(--fa-beat-scale, 1.25));
        transform: scale(var(--fa-beat-scale, 1.25));
    }
}

@keyframes fa-beat {
    0%,
    90% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    45% {
        -webkit-transform: scale(var(--fa-beat-scale, 1.25));
        transform: scale(var(--fa-beat-scale, 1.25));
    }
}

@-webkit-keyframes fa-shake {
    0% {
        -webkit-transform: rotate(-15deg);
        transform: rotate(-15deg);
    }
    4% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }
    8%,
    24% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg);
    }
    12%,
    28% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg);
    }
    16% {
        -webkit-transform: rotate(-22deg);
        transform: rotate(-22deg);
    }
    20% {
        -webkit-transform: rotate(22deg);
        transform: rotate(22deg);
    }
    32% {
        -webkit-transform: rotate(-12deg);
        transform: rotate(-12deg);
    }
    36% {
        -webkit-transform: rotate(12deg);
        transform: rotate(12deg);
    }
    40%,
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@keyframes fa-shake {
    0% {
        -webkit-transform: rotate(-15deg);
        transform: rotate(-15deg);
    }
    4% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }
    8%,
    24% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg);
    }
    12%,
    28% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg);
    }
    16% {
        -webkit-transform: rotate(-22deg);
        transform: rotate(-22deg);
    }
    20% {
        -webkit-transform: rotate(22deg);
        transform: rotate(22deg);
    }
    32% {
        -webkit-transform: rotate(-12deg);
        transform: rotate(-12deg);
    }
    36% {
        -webkit-transform: rotate(12deg);
        transform: rotate(12deg);
    }
    40%,
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
