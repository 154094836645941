/**********************************************************

	- main.scss

	Point of entry for the SASS compiler. This file must
	reference all other files required in the application

	Common Module-wide styles inherited from partials
	should be applied to elements in this file.

	Specific Component Styling should go into their
	respective file within ./components directory

**********************************************************/

// @import "./normalize";

/** 1: Imports
=========================================================*/

// Importing inside class creates a namespace for component
.penneo-auth {
    font-family: $lato;
    @import './authlayouts';
}

// Global components (components that are rendered outside of penneo-auth context)
// i.e. components that will be rendered in a modal window.
@import './auth-components';

@import './components/delete-user-prompt';

// @todo: export to component library
.message-banner {
    position: absolute;
    z-index: 999999;
    width: 100%;
    top: 0;

    &-bar {
        color: $color-white;
        background-color: $color-success;
        background-color: rgba($color-success, 0.8);
        padding: math.div($default-padding, 1.2) $default-padding * 2;
        text-align: center;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
        * {
            vertical-align: top;
        }

        a {
            color: $color-white;
            text-decoration: none;
            border-bottom: 1px solid $color-white;
            padding-bottom: 2px;
            @include transition(0.1s);
            &:hover {
                text-decoration: none;
                padding-bottom: 2px;
                color: $color-white;
                @include transition(0.1s);
                border-bottom: 2px solid $color-white;
            }
        }

        br {
            display: none;
        }

        span {
            position: relative;
            display: inline;
        }

        .close {
            position: absolute;
            cursor: pointer;
            top: $default-padding;
            right: $default-padding;
            color: #333;
            opacity: 0.5;
        }

        .ap {
            position: relative;
            top: 1px;
            margin-left: 1px;
            margin-right: 0px;
        }

        @media #{$breakpoint-small} {
            br {
                display: initial;
            }
            position: relative;
            padding: math.div($default-padding, 1.5) $default-padding;
        }

        &.collapsed {
            background: none;
            position: absolute;
            right: 0;
            top: 0;
            text-align: right;
            padding: $default-padding * 1.25;
        }
    }

    &-bell {
        color: $color-white;
        width: 100%;
        cursor: pointer;
        position: relative;
        opacity: 0.9;
        .dot {
            @include transition(0.2s);
            right: -4px;
            top: -4px;
            position: absolute;
            z-index: -1;
            content: '';
            display: block;
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background: $color-success;
        }

        &:hover {
            opacity: 1;
            & .dot {
                transform: scale(1.35, 1.35);
            }
        }
    }
}

.slideIn-appear {
    transform: translateY(-100px);
    opacity: 0.01;
    transition: all 0.3s ease-out;
}

.slideIn-appear.slideIn-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease-out;
}

.slideIn-enter {
    transform: translateY(-100px);
    opacity: 0.01;
    transition: all 0.3s ease-out;
}

.slideIn-enter.slideIn-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease-out;
}

.slideIn-leave {
    opacity: 1;
    display: block;
    transform: translateY(-50px);
    transition: all 0.3s ease-out;
}

.slideIn-leave.slideIn-leave-active {
    opacity: 0.01;
    transform: translateY(-100px);
    transition: all 0.3s ease-in;
}
