@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.email-edit,
.email-confirmation {
    &.changing-email {
        margin-bottom: $default-padding;
        display: flex;
        max-width: 480px;

        > input[type='text'] {
            width: auto;
            flex-grow: 1;
            margin: 0 $default-padding 0 0 !important;
        }
    }
}

.email-confirmation {
    flex-flow: column wrap;

    @media only screen and (min-width: 768px) {
        flex-flow: row wrap;
    }

    &-label {
        margin-top: $default-padding * 2;
        color: $gray-dark;
    }

    .email-codes {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        width: 100%;
        margin-bottom: $default-padding;

        @media only screen and (min-width: 768px) {
            width: 50%;
            margin-right: $default-padding;
            margin-bottom: 0;
        }

        > input[type='text'] {
            width: auto;
            max-width: calc(25% - #{$default-padding * 0.5});
            flex-grow: 1;
            margin-bottom: 0 !important;
        }
    }

    &-counter {
        font-weight: bold;
        color: $penneo-orange;
        margin: $default-padding * 1.5 0;
    }
}
