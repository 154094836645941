@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
// "Hack" to add more width to container when there are rounds
.casefile-process-v2.medium.has-rounds {
    .body {
        @apply lg:w-[900px];
    }
}

.casefile-info-section {
    @apply grid
            grid-cols-3
            gap-x-10
            gap-y-6
            pb-8
            border-solid
            border-0
            border-b-[1px]
            border-b-neutral-200;

    div {
        label {
            @apply font-semibold
                text-neutral-900;
        }

        i {
            @apply mr-1;
        }
    }
}

.casefile-flow-previewer {
    @apply pb-4
            mb-4
            border-solid
            border-0
            border-b-[1px]
            border-b-neutral-200;
}

.casefile-rounds {
    @apply relative
            px-6
            py-8
            my-4
            bg-neutral-100;

    // This fixes wrong display of datepicker elements
    .react-datepicker__input-container {
        position: relative;
    }
}

.casefile-round {
    &-separator {
        @apply p-6
                text-neutral-900;

        i {
            @apply mr-4;
        }
    }

    &-container {
        @apply bg-white
                rounded
                pb-4
                drop-shadow-sm;
    }

    &-heading {
        @apply flex
                items-center
                bg-primary-900
                px-5
                py-2
                text-sm
                font-semibold
                text-white
                rounded-t;

        &.error {
            @apply bg-danger-700;
        }
    }

    &-content {
        @apply mx-8
                my-4;
    }

    &-scheduling {
        @apply flex
                items-center;

        &-selector {
            @apply flex
                    items-center
                    font-semibold
                    text-neutral-900
                    px-4
                    py-3
                    bg-neutral-50;

            &:not(.editing) {
                @apply hover:bg-neutral-100
                            active:bg-primary-100;
            }
        }
    }

    &-stats {
        @apply flex
                items-center
                text-neutral-900
                p-2
                my-3;
    }

    &-counter {
        @apply mr-6;

        i {
            @apply mr-2;
        }
    }

    &-toggle-details {
        @apply inline-block
                ml-auto;
    }

    &-date {
        > span {
            @apply mx-3;

            &.highligthed {
                @apply text-secondary-600;
            }

            &.regular {
                @apply font-regular;
            }

            &.error {
                @apply text-neutral-400;
            }
        }

        &-picker {
            @apply ml-2;
        }

        &-action {
            @apply inline-block
                    ml-2;
        }

        &-apply {
            @apply ml-6;
        }
    }

    &-section {
        @apply pt-6
                border-solid
                border-0
                border-t-[1px]
                border-t-neutral-200;

        &-header {
            @apply flex
                    items-center
                    my-4;

            &-text {
                @apply font-semibold
                        text-neutral-500;
            }

            &-actions {
                @apply inline-flex
                        ml-auto;
            }
        }
    }

    &-list {
        @apply py-4
                grid
                grid-cols-1
                md:grid-cols-2
                lg:grid-cols-3
                gap-4;

        &-item {
            @apply px-8
                    relative;

            &-edit-icon {
                @apply absolute
                        right-4
                        top-0
                        cursor-pointer
                        text-xl
                        text-neutral-900;
            }
        }
    }

    &-documents {
        &-icon {
            @apply absolute
                    left-0
                    top-0
                    text-2xl
                    text-red-700;
        }
        &-file {
            @apply font-semibold
                    text-neutral-900;
        }
    }

    &-signer {
        span {
            @apply block;
        }

        &-name {
            @apply font-semibold
                    text-neutral-900;
        }

        &-email {
            @apply py-1;
        }

        &-role {
            @apply text-neutral-900;
        }
    }
}

.casefile-round-options-section {
    @apply mt-8;
}

.casefile-round-actions-section {
    @apply flex
            justify-end
            items-center
            md:w-[768px]
            lg:w-[900px]
            bg-white
            p-4
            mx-auto
            my-0;
}
