.batch-group-details {
    .workflow-list {
        padding: $default-padding $default-padding * 1.5;
    }
    .back-link {
        display: inline-block;
        padding-top: math.div($default-margin, 1.2);
    }
    .workflow-group-title {
        padding: $default-padding * 0.5 0;
        padding-top: $default-padding * 2;
        .title {
            margin: 0;
            margin-right: $default-margin * 0.5;
            display: inline-block;
            vertical-align: middle;
        }
        .timestamp {
            display: inline-block;
            margin-top: $default-margin * 0.25;
            vertical-align: middle;
        }
    }

    .form-group-pending-statistics {
        display: inline-block;
        vertical-align: top;
        background: #f7db6f;
        border-radius: $default-radius;
        color: $text-dark;
        text-transform: none;
        opacity: 0.9;
        padding: math.div($default-padding, 6) $default-padding * 0.5;
        margin-left: $default-margin * 0.5;
    }
}
