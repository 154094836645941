.dropdown {
    margin: 0;
    border-radius: $default-radius * 0.5;
    background: rgba(255, 255, 255, 0.1);
    position: relative;
    width: 100.5%;
    left: -1px;
    top: $default-margin * 0.5;
    cursor: pointer;
    @include transition(0.3s);

    &:hover {
        background: rgba(255, 255, 255, 0.2);
    }
    .placeholder {
        display: table;
        width: 100%;
    }
    .dropdown-toggle {
        position: relative;
        padding: $default-padding * 0.5 $default-padding * 1.5;
        display: table-cell;
        height: 3em;
        width: 100%;
        vertical-align: middle;
        @include hyphenate();
        &:after {
            @include bottom-triangle(10px, 6px, $color-white);
            right: 6px;
            top: 50%;
            left: auto;
        }
    }

    .dropdown-list {
        z-index: 100000;
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        margin: 0;
        height: 140px;
        transform: translate(-50%, -50%) scale(0, 0);
        opacity: 0;
        transition: all 0.3s ease;
        ul {
            padding: 0;
            margin: 0;
            overflow-y: scroll;
            transform: translateZ(0);
            -webkit-transform: translateZ(0);
            display: block;
            position: relative;
            height: 100%;
            width: 100%;
        }
        li {
            transition: all 0.3s ease;
            padding: $default-padding * 0.5 $default-padding;
            @include hyphenate();
            &.selected {
                background: rgba(0, 0, 0, 0.3);
            }

            &:hover {
                background: rgba(0, 0, 0, 0.2);
            }
        }
    }

    &.open {
        .dropdown-list {
            pointer-events: all;
            opacity: 1;
            background: $color-success;
            border-radius: $default-radius;
            width: 100%;
            color: $color-white;
            transform: translate(-50%, -80%) scale(1.1, 1.1);
        }
    }
}
