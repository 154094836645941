@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.image-picker {
    margin-bottom: $default-margin;
    width: auto;

    &-content {
        display: inline-block;
        width: auto;
    }

    .top-bar-preview {
        border: $default-border;
        border-radius: $default-radius;
        text-align: center;
        height: 55px;
        top: 50%;
        display: inline-block;
        box-shadow: inset -1px -1px 0 0 rgba(0, 0, 0, 0.1);

        .image-wrapper {
            display: inline-block;
            width: 220px;
            vertical-align: middle;
            height: 100%;
            padding: math.div($default-padding, 3);

            .image {
                background-size: contain;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                width: 100%;
                height: 100%;
            }
        }

        &-links {
            display: inline-block;
            width: 220px;
            height: 100%;

            font-weight: bold;
            font-size: 0.9em;
            padding-left: $default-padding * 2;

            .preview-link {
                cursor: pointer;
                vertical-align: middle;
                padding: $default-padding;
            }
        }
    }

    .image-picker-metadata {
        margin-bottom: $default-margin;
    }
}
