@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.document-preview {
    position: relative;
    height: 100%;
}

.document-preview-pdf-container {
    background: #525659;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .document-preview-pdf-canvas {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        text-align: center;
        &:not(.native) {
            padding: $default-padding * 2;
        }

        canvas {
            width: 100%;
        }
    }

    @media screen and (max-width: 1170px) {
        .document-preview-pdf-canvas.native {
            padding: 0 24px;
        }
    }
}

.document-preview-header {
    padding-bottom: $default-padding;
}

.document-preview-actions {
    .icon-small {
        font-size: 95% !important;
    }
}

.document-preview-title {
    @include OpenSans-Bold();
    color: $text-dark;
    font-size: 20pt;
    padding: 0;
    margin: 0;
}

.document-preview-pagination {
    text-align: center;
    padding-top: $default-padding;
    overflow: hidden;

    .current-document {
        width: auto;
        text-align: center;
        margin: 0px math.div($default-margin, 1.8);
    }

    button {
        display: inline-block;
        color: $cf-blue;
    }

    button {
        text-transform: inherit;
        background-color: transparent;
        border: none;

        &.prev {
            i {
                margin-right: $default-margin * 0.5;
            }
        }

        &.next {
            i {
                margin-left: $default-margin * 0.5;
            }
        }
    }
}

@keyframes placeholder-item-bg {
    0% {
        opacity: 0.05;
    }

    25% {
        opacity: 0.1;
    }

    50% {
        opacity: 0.05;
    }

    75% {
        opacity: 0.1;
    }

    100% {
        opacity: 0.05;
    }
}

@keyframes placeholder-item-fg {
    0% {
        transform: translateX(-1000%);
        opacity: 0.05;
    }

    50% {
        opacity: 0.1;
    }

    100% {
        transform: translateX(1000%);
        opacity: 0.05;
    }
}

.placeholder-item {
    position: inherit;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        animation: placeholder-item-bg 3s infinite;
        border-radius: $default-radius;
        background: #000;
        opacity: 0;
    }

    &:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 10%;
        background: #000;
        height: 100%;
        animation: placeholder-item-fg 1.5s infinite ease-in-out forwards;
        box-shadow: 0 0 60px 40px #000;
    }
}
