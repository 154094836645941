@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.signing-reject-modal {
    .optional {
        color: $gray-dark;
    }

    &-content {
        textarea {
            resize: none;
        }
    }

    &-footer {
        margin-top: $default-padding;
    }
}
