@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.center-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.toggle-error {
    padding: 6px 0;

    summary {
        font-weight: 600;
        cursor: pointer;
        user-select: none;
    }

    summary i {
        padding: 6px;
        transition: all 0.25s ease-out;
        transform: rotate(0);
    }

    &[open] {
        i {
            transform: rotate(180deg);
        }
    }
}
