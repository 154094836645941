.casefile-creator,
.casefile-summary {
    display: block;
    padding: $default-padding 0;
    position: relative;

    .casefile-creation-wrapper {
        display: block;
        position: relative;
    }

    .casefile-form-input {
        position: relative;
    }

    .horizontal-layout {
        position: relative;
        width: 100%;
        height: 100%;
        display: table;
    }

    .casefile-component-wrapper {
        vertical-align: top;
        height: 100%;
        display: table-cell;
        position: relative;
        padding: $default-padding;
        width: 53%;

        &:first-child {
            padding-right: 0;
        }

        @media #{$breakpoint-medium} {
            &:first-child {
                padding-right: $default-padding;
            }
            width: 100%;
            display: inline-block;
        }
    }

    .signer-component-wrapper {
        padding: $default-padding;
    }

    .casefile-row-form {
        display: block;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .casefile-creation-buttons {
        position: relative;
        text-align: right;
        button {
            display: inline-block;
        }
        padding-right: $default-padding;
    }

    .input-error {
        border: 1px solid $color-error;
    }

    .fieldset {
        padding: $default-padding;
        padding-top: 0;
        height: 100%;
        position: relative;
        border-radius: 4px;

        .legend {
            position: relative;
            top: -$default-padding;
            margin-bottom: -$default-padding;
            display: inline-block;
            background: $background-color;
            padding: 0 0.5em;
        }

        .summary-legend {
            position: relative;
            top: -0.7em;
            display: inline-block;
            background: $background-color;
            padding: 0 0.5em;
        }
    }

    .casefile-advanced-options {
        margin: 0;
    }

    .padding-left {
        padding-left: 7px;
    }
}

.casefile-information-table {
    width: 100%;
    .label {
        width: 160px; // current maximum width for labels, this avoids the table resizing on content change
        color: $gray-darker;
        font-weight: bold;
    }
    td {
        padding-bottom: $default-padding;
    }
    input,
    select {
        width: 100%;
    }
}

.casefile-recipient-form {
    .custom-checkbox .label {
        color: $gray-darker;
        font-weight: bold;
    }
}
