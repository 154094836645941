@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.modal-confirm {
    .messages {
        padding: $default-padding * 1.5 0;
    }

    .message {
        font-weight: bold;
        font-size: $title-size;
    }
}
