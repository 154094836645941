@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.label {
    @apply text-gray-900 text-sm font-semibold w-40;
}
.value {
    @apply text-gray-600 text-sm;
}
dl,
dt,
dd {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
}
