@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.kyc-pending-signature-container {
    border-left: 4px solid $penneo-yellow;
    padding-left: $default-padding;
}

.kyc-rejected-signature-container {
    border-left: 4px solid $color-error;
    padding-left: $default-padding;
}
