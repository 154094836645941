$table-border: 1px solid $gray-medium;
$table-text-color: #747474;

.table,
.griddle-table,
.data-table-container table {
    width: 100%;
    border: $table-border;
    border-radius: $default-radius;
    margin: $default-margin 0;

    // Fixes Webkit browser's bug with 100% width on table elements being rounded to the nearest pixel
    // creating a gap between the width of the table and the container.
    // @see: https://stackoverflow.com/a/31719802/781779
    width: calc(100% + 1px);

    tr,
    td,
    th {
        position: relative;
        color: $table-text-color;
        padding: 0;

        .align-left {
            text-align: left;
        }

        .align-right {
            text-align: right;
        }

        .align-center {
            text-align: center;
        }
    }

    // Sortable columns
    th.sortable {
        font-size: 0;
        > span {
            padding: 0 $default-padding * 1.5;
            font-size: $default-font-size;
            &:after {
                color: #999999;
                opacity: 0.5;
                @include far-icon('\f0dc');
                position: relative;
                left: 10px;
            }
        }

        &.sort-ascending > span:after {
            opacity: 1;
            color: $penneo-green;
            @include far-icon('\f0de');
        }

        &.sort-descending > span:after {
            opacity: 1;
            color: $penneo-green;
            @include far-icon('\f0dd');
        }
    }

    th.unsortable {
        user-select: none;
        pointer-events: none;
        cursor: not-allowed;
    }

    th {
        @include OpenSans-SemiBold();
        @include prevent-selection();
        cursor: pointer;
        white-space: nowrap;
        border-right: $table-border;
        border-color: $gray-light;
        background: $color-white;
        padding: math.div($default-padding, 1.5) $default-padding * 1.5;
        color: $text-dark;
        text-transform: uppercase;
        text-align: left;

        &:last-child {
            border-right: none;
        }

        .data-table-th-content {
            position: relative;
            display: block;

            &.sort-enabled {
                padding-right: $default-padding * 1.5;
            }

            .th-sort {
                position: absolute;
                right: 0;
                top: 0;
                color: $penneo-green;

                .disabled {
                    opacity: 0.5;
                    color: $gray-dark;
                }
            }
        }
    }

    td {
        border-right: $table-border;
        padding: math.div($default-padding, 1.2) $default-padding * 1.5;
        z-index: 1;

        &:last-child {
            border-right: none;
        }

        &.no-padding {
            padding: 0;
        }

        &.column-show-on-row-hover {
            opacity: 0;
        }
    }

    .td-padding {
        padding: math.div($default-padding, 1.2) $default-padding * 1.5;
    }

    tr:nth-child(odd) {
        background-color: #fafafa;
    }

    tr:nth-child(even) {
        background-color: $color-white;
    }

    td.column-no-padding {
        padding: 0;
    }

    td.no-border-right,
    th.no-border-right {
        border-right: none;
    }

    td.column-minimize,
    th.column-minimize {
        width: 1px;
        > *,
        .no-wrap {
            white-space: nowrap;
        }

        .wrap {
            white-space: normal;
        }
    }

    td.column-center {
        text-align: center;
    }

    tr:hover {
        cursor: pointer;
        position: relative;
        background-color: transparentize($color: $cf-blue, $amount: 0.95);

        td {
            color: $text-dark;
            border-right-color: transparentize($cf-blue, 0.5);
        }

        td:not(.footer-container):first-child:before {
            content: '';
            position: absolute;
            top: 0;
            left: -2px;
            bottom: 0;
            width: 4px;
            background: $cf-blue;
        }

        .table-row-link {
            color: $color-blue-highlight;
        }

        td.column-show-on-row-hover {
            z-index: 2;
            opacity: 1;
        }
    }

    // Checkboxes have spacing in other contexts.
    // In the table, they are misaligning the content in other columns
    .custom-checkbox {
        margin: 0 !important;
        padding: 0 !important;
    }

    // checkbox column
    .column-collapsed {
        width: 1px; // Make column as small as possible.
        > * {
            white-space: nowrap;
        }

        text-align: left;
        border-right: none;
        padding-right: 0;
        padding-left: $default-padding;
    }

    td.column-show-on-row-hover {
        opacity: 0;
    }
}

// Pagination specific to griddle
.griddle-body {
    width: 100%;
    overflow-x: auto;
}

.griddle-table {
    .footer-container {
        background: $color-white;
        border-top: $table-border;
        cursor: initial;
        &:hover {
            cursor: initial;
            background: $color-white;
        }
    }

    .griddle-footer {
        text-align: center;
        font-size: 0.95em;
    }

    .griddle-previous {
        display: inline-block;
        vertical-align: top;
        min-height: 1px;

        button {
            border: none;
            background: none;
            color: $color-blue-highlight;
            outline: 0;
            position: relative;
            background-color: transparent;
            padding: $button-size;
            white-space: nowrap;
            text-align: center;

            &:before {
                @include far-icon('\f060');
                margin-right: $default-margin * 0.5;
            }
        }
    }
    .griddle-next {
        display: inline-block;
        vertical-align: top;

        button {
            border: none;
            background: none;
            color: $color-blue-highlight;
            outline: 0;
            position: relative;
            background-color: transparent;
            padding: $button-size;
            white-space: nowrap;
            text-align: center;

            &:after {
                padding-left: $default-padding;
                @include far-icon('\f061');
            }
        }
    }
    .griddle-page {
        display: inline-block;
        vertical-align: top;
        margin: 0 $default-padding;
        select {
            width: auto;
            padding-right: 2em;
            margin-right: 1em;
        }
    }
}

// Griddle implementation with custom rows
.griddle-custom-rows {
    @apply border-none
            w-full;

    .griddle-footer {
        @apply px-6
                py-4;
    }
}
