@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.empty-table-body {
    width: 100%;
    padding: $default-padding * 3 0;
    text-align: center;
    color: $gray-dark;

    &-message {
        font-size: 1.1em;
    }

    .search-term {
        @include OpenSans-SemiBold();
        color: $penneo-blue;
    }
}

.sad-folder {
    position: relative;
    display: inline-block;
    opacity: 0.8;
    margin-bottom: $default-padding * 1.5;
    .fa-folder {
        font-size: 80px;
        color: $gray-dark;
    }

    &-eye-left {
        position: absolute;
        top: 46%;
        left: 32%;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: $text-dark;
    }

    &-eye-right {
        position: absolute;
        top: 46%;
        left: 59%;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: $text-dark;
    }

    &-mouth {
        position: absolute;
        bottom: 36%;
        left: 39%;
        width: 16px;
        height: 6px;
        border: solid 2px $text-dark;
        border-color: $text-dark transparent transparent transparent;
        border-radius: 50%/6px 6px 0 0;
    }

    &-shadow {
        position: absolute;
        height: 8px;
        border-radius: 40%;
        width: 120px;
        left: 50%;
        margin-left: -60px;
        bottom: -8px;
        background: $text-dark;
        opacity: 0.1;
    }
}

.sad-search {
    position: relative;
    display: inline-block;
    opacity: 0.8;
    margin-bottom: $default-padding * 1.5;

    .fa-search {
        font-size: 80px;
        color: $gray-dark;
    }

    &-eye-left {
        position: absolute;
        top: 36%;
        left: 26%;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: $text-dark;
    }

    &-eye-right {
        position: absolute;
        top: 36%;
        left: 53%;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: $text-dark;
    }

    &-mouth {
        position: absolute;
        bottom: 46%;
        left: 33%;
        width: 16px;
        height: 6px;
        border: solid 2px $text-dark;
        border-color: $text-dark transparent transparent transparent;
        border-radius: 50%/6px 6px 0 0;
    }

    &-shadow {
        position: absolute;
        height: 8px;
        border-radius: 40%;
        width: 100px;
        left: 50%;
        margin-left: -50px;
        bottom: -12px;
        background: $text-dark;
        opacity: 0.1;
    }
}
