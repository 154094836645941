@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
$header-color: #142d43;

.new-account-benefits {
    text-align: center;

    h3 {
        margin-top: $default-margin * 0.5;
        margin-bottom: $default-margin;
        color: $header-color;
        font-size: 11pt;
    }

    ul {
        padding: 0;
        padding-top: $default-padding * 1.5;
        padding-bottom: $default-padding * 1.5;

        li {
            @include OpenSans-SemiBold();
            text-align: center;
            display: inline-block;
            width: math.div(100%, 3);
            vertical-align: top;
            p {
                color: $header-color;
                margin-top: $default-margin * 0.5;
                margin-bottom: $default-margin;
                font-size: 90%;
            }
        }
    }

    .terms-conditions {
        @include OpenSans-Regular();
        color: $text-medium;
    }
}
