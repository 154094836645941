@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.clients-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    .recipient {
        margin: 0;
        padding-bottom: 0.5rem;
        margin-bottom: 1.5rem;

        border-bottom: 1px solid #ccc;

        &-container {
            margin: 0 1.5rem;
            overflow: hidden;
            height: 100%;
            width: 100%;
            max-width: 320px;
        }

        &-block {
            height: 100%;
        }
    }

    .clients-full {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .recipient-list {
        display: block;
        overflow-y: scroll;
        padding-bottom: 0.5rem;
        max-height: 100%;
    }

    .documents-container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    .document-input {
        width: auto !important;
    }

    .file-container {
        width: 100%;
        overflow-y: scroll;
    }

    .file-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .file-header .icon {
        margin-right: 0.5rem;
    }

    .file-header h4 {
        margin-right: 0.5rem;
    }

    .file-header select {
        max-width: 280px;
    }
}
