@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.casefile-status-banner {
    margin-top: $default-margin * 0.5;
    border-radius: $default-radius;
    display: inline-block;
    font-size: 82%;
    padding: $default-padding * 0.5 $default-padding;
    text-align: center;

    border: 1px solid transparentize($gray-dark, 0.6);
    background: transparentize($gray-dark, 0.6);
    color: darken($gray-dark, 30);

    &-name {
        @include OpenSans-Bold();
        border-right: 1px solid transparent;
        padding-right: math.div($default-padding, 1.5);
        margin-right: math.div($default-padding, 1.5);

        border-right-color: darken($gray-dark, 10);
    }

    &-details {
        @include OpenSans-SemiBold();
    }

    &.cyan {
        border: 1px solid transparentize(#5abede, 0.6);
        background: transparentize(#5abede, 0.6);
        color: darken(#5abede, 30);

        .casefile-status-banner-name {
            border-right-color: darken(#5abede, 10);
        }
    }

    &.yellow {
        background: $penneo-yellow;
        color: darken($penneo-yellow, 50);

        .casefile-status-banner-name {
            border-right-color: darken($penneo-yellow, 20);
        }
    }

    &.green {
        border: 1px solid transparentize($penneo-green, 0.6);
        color: darken($penneo-green, 20);
        background: transparentize($penneo-green, 0.7);

        .casefile-status-banner-name {
            border-right-color: darken($penneo-green, 2);
        }
    }

    &.red {
        background: $color-error;
        color: $color-white;

        .casefile-status-banner-name {
            border-right-color: darken($color-error, 12);
        }
    }
}
