.tab-holder {
    width: 100%;
    $border-color: $gray-medium;

    .tab-menu {
        width: inherit;

        ul {
            position: relative;
            display: block;
            margin: 0;

            &::before {
                content: '';
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                border-bottom: 1px solid $border-color;
                z-index: 1;
            }

            li {
                display: inline-block;
                color: $gray-dark;

                a {
                    &:first-child {
                        border-top-left-radius: $default-radius;
                    }

                    &:last-child {
                        border-top-right-radius: $default-radius;
                    }

                    display: inline-block;
                    padding: $default-padding;
                    position: relative;
                    border: 1px solid transparent;
                    border-bottom-color: $border-color;
                    z-index: 0;
                    color: $color-blue-highlight;
                }

                &.active a {
                    border-color: $border-color;
                    border-bottom-color: $color-white;
                    z-index: 2;
                    background-color: $color-white;
                    color: $gray-darker;
                    cursor: default;
                }
            }
        }
    }

    .tab-content {
        background-color: $color-white;
        border: 1px solid $border-color;
        border-top: none;
        border-bottom-left-radius: $default-radius;
        border-bottom-right-radius: $default-radius;
    }
}
