@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.folder-selector-input {
    @include input-base();
    width: 100%;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    position: relative;
    cursor: pointer;
    height: 38px;

    button {
        top: -(math.div($default-margin, 3.5));
        position: relative;
        margin-left: $default-margin * 2;
        padding: $default-padding * 0.25 $default-padding * 1.5 !important;
    }

    .folder-title {
        position: absolute;
        white-space: nowrap;
        color: #7b7b7b;
        left: 10px;
        top: 10px; // manual adjustment to make long folder titles appear in one line
    }
}
