@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.access-rights-container {
    position: relative;
    cursor: pointer;
    background: $color-white;
    padding: 0 $default-padding;
    border: $default-border;
    margin: 0 math.div($default-margin, 1.5);
    border-radius: $default-radius;
    color: $text-dark;

    .fa-chevron-down {
        position: relative;
        top: 2px;
        margin-left: $default-margin;
    }

    &:hover {
        border-color: $cf-blue;
    }

    &.readonly {
        cursor: not-allowed;
        &:hover {
            border: $default-border;
        }
    }
}

.access-rights {
    position: relative;
    width: 100%;
    height: 36px;
    line-height: 36px;
    white-space: nowrap;
    color: $gray-darker;
}

.action-drop-down {
    position: absolute;
    top: 105%;
    right: -1px;
    border-radius: $default-radius;
    z-index: 1;
    text-align: left;
    background: $color-white;
    white-space: initial;
    box-shadow: $card-shadow-hover;

    button {
        white-space: nowrap;
        width: 100%;
        text-align: left !important;
        border: none !important;
        background-color: $color-white !important;
        border-bottom: $default-border;
        padding: math.div($default-padding, 1.5) $default-padding;
        border-radius: 0px;
        text-transform: inherit;
        line-height: initial;

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background-color: $cf-blue !important;
            color: $color-white !important;
        }
    }
}
