@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.archive-container-modal h1 {
    margin-bottom: 0;
    margin-top: 0;
}

.archive-share {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.archive-share-invite-box {
    background: transparentize($cf-blue, 0.92);
    padding: $default-padding $default-padding * 1.5;

    border: 1px solid transparentize($cf-blue, 0.6);
    border-radius: $default-radius;

    label {
        margin-top: 0 !important;
        color: $cf-blue !important;
    }

    &-share-inputs {
        display: flex;
        flex-direction: row;
    }

    &-contact-input {
        flex-grow: 2;
    }

    &.green {
        background: transparentize($penneo-green, 0.92);
        border: 1px solid transparentize($penneo-green, 0.6);
        label {
            color: $penneo-green !important;
        }
    }

    &-message {
        color: $color-blue-main;
        margin-top: $default-margin * 0.5;
        font-size: 90%;
        @include OpenSans-SemiBold();
    }
}

.text-tab-headers {
    margin: 0;
    margin-bottom: $default-margin;

    span {
        cursor: pointer;
        color: $text-medium;
        border-bottom: 2px solid $text-light;
        padding-bottom: 3px;
        margin-right: $default-margin;

        &.active {
            color: $cf-blue;
            border-color: $cf-blue;
        }
    }
}

.archive-share-members {
    min-height: 0;
    flex: 1 1 auto;
    overflow: auto;
}

.archive-share-members-empty {
    text-align: center;
    padding-top: $default-padding * 4;
    h1 {
        color: $text-medium;
        @include OpenSans-SemiBold();
        font-size: 12pt;
    }

    i {
        font-size: 24pt;
        color: $text-light;
        padding-bottom: $default-padding * 0.5;
    }
}

.archive-share-members-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: $default-padding 0;
    border-bottom: $default-border;

    &-name {
        flex-grow: 2;
        @include OpenSans-Bold();
    }

    &-email {
        @include OpenSans-Regular();
        font-size: 90%;
    }

    &-access {
        padding: 0 $default-padding * 0.5;
    }

    &-remove {
        cursor: pointer;
        padding-right: $default-padding;
        font-size: 1.2em;

        &:hover {
            color: $color-error;
        }
    }
}
