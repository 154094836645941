@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.clients {
    &-main-container {
        position: relative;
        &.center {
            max-width: 768px;
            margin: 0 auto;
        }
    }

    /* Client list */
    &-list-empty {
        padding: $default-padding;
        text-align: center;
    }

    /* Clients creation form */
    &-editor {
        padding: $default-padding;

        &-form {
            padding: $default-padding 0;

            &-field {
                & + & {
                    @apply mt-4;
                }
            }

            &-footer {
                text-align: right;
                padding-top: $default-padding;
            }
        }
    }

    /* Clients edition form */
    &-view {
        &-header {
            border-bottom: $default-border;
            padding: $default-padding;

            &.editing {
                display: flex;
                align-items: flex-start;

                > button {
                    margin-top: 2px;
                }
            }

            > * {
                display: inline-block;
            }

            &-edit {
                position: relative;

                > * {
                    display: inline;
                }

                &-icon {
                    cursor: pointer;
                    margin-left: $default-padding * 0.5;

                    &.editing {
                        position: absolute;
                        right: $default-padding;
                        top: $default-padding * 0.5;
                    }
                }

                &-input {
                    > input {
                        padding-right: $default-padding * 3 !important;
                    }
                }

                .title {
                    @include OpenSans-SemiBold();
                    font-size: 10.5pt;
                    margin: 0;
                }
            }
        }

        /* Table that displays entities linked to current Client */
        .tabs-layout {
            .tab-layout-content {
                padding: 0;
            }
        }

        .data-table-container table {
            margin-top: 0;
        }
    }
}
