@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.contact-list-table.data-table-container {
    table td,
    table th {
        @include transition(0.5s, 'background-color', 'ease-in-out');
        border-right: none;
        background-color: white;
        border-bottom: $default-border;
        padding: $default-padding * 1.2 $default-padding;
    }

    // Disable hover decoration for rows
    table tr td:before {
        display: none;
    }

    table th {
        padding-top: 0;
    }

    table {
        border-top: none;
        margin-bottom: $default-margin * 2;
    }

    table tr.highlight-success td {
        background-color: transparentize(
            $color: $color-blue-highlight,
            $amount: 0.95
        );
    }

    table tr.highlight-error td {
        background-color: transparentize($color: $color-error, $amount: 0.8);
    }
}

.contact-list-inline-textinput {
    margin-top: -$default-padding * 0.25;
    margin-bottom: -$default-padding * 0.25;
    width: auto;
    width: 100%;
}
