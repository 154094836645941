$table-text-color: #747474;

.archive-container {
    position: relative;

    // checkbox column
    .column-checkbox {
        width: 1px; // Make column as small as possible.
        > * {
            white-space: nowrap;
        }

        text-align: left;
        border-right: none;
        padding-right: 0;
        padding-left: $default-padding;
    }

    // Make columns as small as possible
    .column-status,
    .column-type,
    .column-updated,
    .column-created {
        width: 1px;
        > *,
        .no-wrap {
            white-space: nowrap;
        }

        .wrap {
            white-space: normal;
        }
    }

    td.column-status {
        padding-top: 0;
        padding-bottom: 0;
    }

    .item-inline-action {
        display: none;
        font-size: 100%;
        color: $text-medium;

        &:hover {
            color: $cf-blue;
        }
    }

    .item-actions {
        float: right;
        .item-action-tooltip {
            pointer-events: none;
            margin: 0 0.8em;
            display: inline-block;
            width: 1em;
        }

        > .item-action-tooltip:last-child {
            margin-right: 0;
        }

        .item-action {
            opacity: 0.4;
            transition: none;

            i {
                color: $text-medium;

                font-size: 110%;
            }

            &:hover {
                i {
                    color: $cf-blue;
                }
            }

            pointer-events: none;
            text-align: center;

            &:last-child {
                margin-right: 0;
            }

            &.visible {
                opacity: 1;
                i {
                    color: $cf-blue;
                }
            }
        }
    }

    tr:hover .item-actions {
        pointer-events: all;
        .item-action-tooltip {
            pointer-events: all;
        }
        .item-action {
            pointer-events: all;
            opacity: 1;
        }
    }

    td.column-title {
        :not(a) > .column-title-text {
            color: $table-text-color;
        }

        .column-title-icon {
            padding-right: $default-padding * 0.5;
            color: $cf-blue;
        }
    }

    tr:hover td.column-title {
        color: $cf-blue;
        .column-title-text {
            color: $cf-blue;
        }

        .column-title-icon {
            color: $cf-blue;
        }

        .item-inline-action {
            display: inline;
        }
    }

    .breadcrumbs-holder {
        height: 30px;
    }

    .tab-header {
        .penneo-menu-tab-count {
            border: 1px solid $text-medium;
            border-radius: $default-radius;
            color: $text-medium;
            font-size: 85%;
            font-weight: bold;
            display: inline-block;
            margin-left: math.div($default-margin, 2.5);
            padding: 0 4px;
            position: relative;
            text-align: center;
            top: -1px;
        }

        .active > {
            .penneo-menu-tab-count {
                background: $cf-blue;
                border-color: $cf-blue;
                color: $color-white;
            }
        }
    }
}
