@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.customer-stats {
    position: relative;

    .customer-stats-left {
        display: inline-block;
        border-right: $default-border;

        .customer-stats-sl-balance-content {
            padding: $default-padding;

            .sl-donut-chart {
                display: inline-block;
                vertical-align: middle;
            }

            .sl-detailed-balance {
                display: inline-block;
                vertical-align: middle;
                padding: $default-padding * 2;

                .sl-purchased {
                    font-size: $subtitle-font-size;
                }

                .sl-donut-chart-legend {
                    text-align: left;

                    .preview {
                        display: inline-block;
                        vertical-align: middle;
                        height: 20px;
                        width: 20px;
                        border-radius: 50%;
                        margin-right: $default-margin;
                    }

                    .description {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    .sl-used {
                        padding-top: $default-padding;
                    }

                    .sl-available {
                        padding-top: $default-padding;
                    }
                }
            }
        }
    }

    .customer-stats-right {
        display: inline-block;
        margin-left: $default-margin;
        position: relative;
        top: -16px;

        .customer-stats-users-balance-content {
            padding: $default-padding;
            text-align: center;
            position: relative;
            top: 35px;

            .general-balance {
                font-size: $default-font-size * 2;
            }

            .detailed-balance {
                padding: $default-padding;

                .active {
                    margin-right: $default-margin;
                    i {
                        margin-right: $default-margin * 0.5;
                        color: $penneo-green;
                    }
                }

                .inactive {
                    margin-left: $default-margin;
                    i {
                        margin-right: $default-margin * 0.5;
                        color: $penneo-orange;
                    }
                }
            }
        }
    }
}
