.batch-group-downloads {
    margin: $default-margin;
    margin-right: 0;
    font-size: 0.9em;
    .button {
        margin-left: $default-margin;
        &:hover {
            color: inherit;
        }
    }
}
