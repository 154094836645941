@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.penneo-menu-tabs {
    padding: $default-padding;
    padding-top: 0;

    &.no-padding-left {
        padding-left: 0;
    }

    .penneo-menu-tab {
        cursor: pointer;
        display: inline-block;
        padding-bottom: 3px;
        padding-right: 3px;
        padding-left: 3px;
        @include OpenSans-SemiBold();
        margin: 0 $default-margin;
        border-bottom: 3px solid transparent;

        &:first-child {
            margin-left: 0;
        }

        &:hover {
            color: $cf-blue;
        }

        &.active {
            color: $color-blue-highlight;
            border-bottom: 3px solid $cf-blue;
        }
    }
}
