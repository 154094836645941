$input-container-width: 250px;

.batch-editor {
    .batch-header {
        @include white-container();
    }
    .batch-details {
        position: relative;
        .first-title {
            margin-top: 0;
        }
    }

    .summary-table {
        td {
            padding-bottom: $default-padding * 0.5;
            padding-right: $default-padding;
        }
        .bold {
            font-weight: 700;
            padding-right: $default-padding * 0.25;
        }
    }

    .batch-user-input {
        width: $input-container-width;
        position: relative;
        z-index: 2;
    }

    .batch-summary {
        z-index: 1;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 0;
        box-sizing: border-box;
        padding-left: $input-container-width;
        height: 100%;
        .batch-summary-wrapper {
            padding: 0 $default-padding;
        }

        .document-images-wrapper {
            padding-top: $default-padding;
            width: 100%;
            overflow-x: auto;
        }
        .document-images {
            white-space: nowrap;
            img {
                white-space: nowrap;
                margin-right: $default-margin;
                border: 1px solid $gray-border;
                height: 160px;

                &:last-child {
                    margin-right: 0;
                }
            }
            p {
                line-height: 160px;
                font-weight: 700;
                opacity: 0.7;
                text-align: center;
            }
        }
    }
}
