.click-dropdown-list {
    cursor: pointer;
    position: relative;
    width: 100%;

    > ul {
        margin: 0;
    }

    li {
        text-align: left;
        padding: math.div($default-padding, 3) $default-padding * 0.25;
        position: relative;
        @include transition(0.2s);

        &:before {
            @include transition(0.2s);
            opacity: 0;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            background: $color-blue-highlight;
            width: 2px;
            height: 100%;
        }

        &:hover {
            &:before {
                opacity: 1;
            }

            padding-left: $default-padding * 0.5;
            background: $gray-light;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .fa {
        width: 20px;
    }
}
