@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.contact-list-action-icon {
    padding: $default-padding * 0.5 math.div($default-padding, 1.5);

    &:hover {
        background: transparentize($color-blue-highlight, 0.9);
        border-radius: $default-radius;
    }
}

.contact-list-action-text:hover {
    text-decoration: underline;
}
