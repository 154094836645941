@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.penneo-message {
    @include OpenSans-Regular();
    @apply text-sm;
    padding: $default-padding;
    margin-top: $default-margin;
    border-radius: $default-radius * 2;
    display: flex;
    align-items: baseline;
    text-align: left;

    .penneo-message-icon {
        margin-right: $default-padding * 0.5;
    }

    &.info {
        background: transparentize($cf-blue, 0.9);
        color: $cf-blue;
    }

    &.success {
        background: transparentize($penneo-green, 0.9);
        color: $penneo-green;
    }

    &.error {
        background: transparentize($color-error, 0.9);
        color: $color-error;
    }

    &.warning {
        background: transparentize($penneo-orange, 0.9);
        color: $text-dark;

        .penneo-message-icon {
            color: $penneo-orange;
        }
    }
}

.penneo-message-link {
    @include OpenSans-SemiBold();
    text-decoration: underline;

    i {
        margin-left: 4px;
        font-size: 0.8em;
        position: relative;
        top: 0;
    }
}
