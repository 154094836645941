@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.signing-container {
    margin-top: 50px;
    position: relative;
    z-index: 1;
}

.signing-modal,
.signing-reject-modal {
    &.react-modal-content {
        @media only screen and (max-width: 768px) {
            @apply w-full
            w-[calc(100%-1rem)]
            min-w-[auto];
        }
    }
}
