.react-datepicker-wrapper {
    width: 100%;
}
.document-forms-viewer .react-datepicker-wrapper {
    position: relative;
    top: -0.9rem;
    left: -0.1rem;
}
.document-forms-viewer .react-datepicker-popper {
    margin-top: 15px;
}
