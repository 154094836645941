.rc-pagination {
    font-size: 12px;
    font-family: $lato;
    user-select: none;
    float: right;
}

.rc-pagination-total-text {
    float: left;
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
    cursor: default;
}

.rc-pagination:after {
    content: ' ';
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;
}

.rc-pagination-item {
    cursor: pointer;
    border-radius: $default-radius;
    min-width: 28px;
    height: 28px;
    line-height: 27px;
    text-align: center;
    list-style: none;
    float: left;
    border: 1px solid #d9d9d9;
    background-color: $color-white;
    margin-right: $default-margin * 0.25;
    a {
        text-decoration: none;
        color: #666;
    }
    &:hover {
        border-color: $color-blue-highlight;
        a {
            color: $color-blue-highlight;
        }
    }
}

.rc-pagination-item-active {
    background-color: $color-blue-highlight;
    border-color: $color-blue-highlight;
    a,
    &:hover a {
        color: $color-white;
    }
}

.rc-pagination-jump-prev:after,
.rc-pagination-jump-next:after {
    content: '•••';
    display: block;
    letter-spacing: 2px;
    color: #ccc;
    font-size: 12px;
    margin-top: 1px;
}

.rc-pagination-jump-prev:hover:after,
.rc-pagination-jump-next:hover:after {
    color: $color-blue-highlight;
}

.rc-pagination-jump-prev:hover:after {
    content: '«';
}

.rc-pagination-jump-next:hover:after {
    content: '»';
}

.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
    margin-right: $default-margin * 0.25;
}

.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
    cursor: pointer;
    color: #666;
    font-size: 10px;
    border-radius: $default-radius;
    list-style: none;
    min-width: 28px;
    height: 28px;
    line-height: 25px;
    float: left;
    text-align: center;
    &:hover {
        border-color: $color-blue-highlight;
        a {
            color: $color-blue-highlight;
        }
    }
}

.rc-pagination-prev a:after {
    content: '‹';
    display: block;
}

.rc-pagination-next a:after {
    content: '›';
    display: block;
}

.rc-pagination-prev,
.rc-pagination-next {
    border: 1px solid #d9d9d9;
    font-size: 18px;
}

.rc-pagination-prev a,
.rc-pagination-next a {
    color: #666;
}

.rc-pagination-prev a:after,
.rc-pagination-next a:after {
    margin-top: -1px;
}

.rc-pagination-disabled {
    a {
        color: #ccc;
    }

    &:hover {
        border-color: #ccc;
        a {
            color: #ccc;
        }
    }
}

.rc-pagination-disabled a:after {
    cursor: default;
}

.rc-pagination-options {
    float: left;
    margin-left: 15px;
}

.rc-pagination-options-size-changer {
    float: left;
    width: 80px;
}

.rc-pagination-options-quick-jumper {
    float: left;
    margin-left: 16px;
    height: 28px;
    line-height: 28px;
    input {
        margin: 0 8px;
        box-sizing: border-box;
        background-color: $color-white;
        border-radius: $default-radius;
        border: 1px solid #d9d9d9;
        outline: none;
        padding: 3px 12px;
        width: 50px;
        height: 28px;
        &:hover {
            border-color: $color-blue-highlight;
        }
    }
}

.rc-pagination-simple {
    .rc-pagination-prev,
    .rc-pagination-next {
        border: none;
        height: 24px;
        line-height: 24px;
        margin: 0;
        font-size: 18px;
    }
    .rc-pagination-simple-pager {
        float: left;
        margin-right: $default-margin * 0.25;
        .rc-pagination-slash {
            margin: 0 10px;
        }
        input {
            margin: 0 8px;
            box-sizing: border-box;
            background-color: $color-white;
            border-radius: $default-radius;
            border: 1px solid #d9d9d9;
            outline: none;
            padding: 5px 8px;
            width: 30px;
            min-height: 20px;
            &:hover {
                border-color: $color-blue-highlight;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .rc-pagination-item-after-jump-prev,
    .rc-pagination-item-before-jump-next {
        display: none;
    }
}
