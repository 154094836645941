.casefile-summary-buttons-left {
    // float: none;
    display: inline-block;
    width: 50%;
    padding-left: 1em;
    float: left;
}

.casefile-summary {
    .summary-legend {
        font-weight: bold;
    }
    .horizontal-layout {
        .casefile-summary-buttons-right {
            float: none;
            display: inline-block;
            width: 50%;
        }

        a {
            display: inline-block;
        }
    }

    input,
    select,
    textarea {
        width: 100%;
    }

    .message-form-input,
    .casefile-document-options {
        label {
            margin-top: $default-padding * 0.5;
        }

        .first {
            margin-top: 0;
        }
    }
}
