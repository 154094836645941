.tab-form-data {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    overflow: hidden;
    .tab-form-data-content {
        padding: $default-padding * 2;
        padding-right: 0;
        overflow: hidden;
        height: 100%;
        position: relative;
        box-sizing: border-box;
    }
    .toggle-options {
        position: absolute;
        top: $default-margin * 2;
        right: $default-margin;
    }
    .details-content-title {
    }
    .workflow-field-list {
        padding-right: $default-padding * 2;
        overflow-y: scroll;
        height: 100%;
    }
    // height: 100%;
    // position: relative;
}
