@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.document-upload-form {
    margin-top: 1em;
}

.document-upload-form .form-attachment .title {
    font-weight: bold;
    margin-bottom: 0.5em;
}

.document-upload-form .form-attachment .upload-area {
    border: 1px solid #dedede;
    cursor: pointer;
    font-size: 92%;
    padding: 1em 1em;
    border-color: #3f99f1;
    max-width: 768px;
    color: #ffffff;
    background-color: #3f99f1;
    border-radius: 3px;
    text-align: center;
}

.document-upload-form .form-attachment .upload-area .file {
    display: none;
}

.document-upload-form .required {
    margin-left: 0.5em;
    position: relative;
    bottom: 1px;
    font-size: 0.7em;
}

.recipients-title {
    margin-bottom: 0.5em;
}

.form-attachment .file-list ul {
    max-width: 768px;
    border: 1px solid #dedede;
    border-top: none;
    margin-top: -1px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 0;
}

.form-attachment .file-list ul li {
    font-size: 95%;
    width: 100%;
    color: #888888;
    display: flex;
    justify-content: space-between;
    padding: 0.8em;
}

.registered-letter-recipient-modal .delete-icon {
    position: relative;
    top: 1px;
}

.recipient-ssn-validation-error {
    min-height: 2.6rem;
}

.reg-letter-recipient-form .validation-errors {
    background: transparentize($penneo-orange, 0.9);
    border-radius: $default-radius;
    border: 1px solid $penneo-orange;
    padding: 8px;
    margin-top: $default-margin;
    color: $text-dark;

    i {
        color: $penneo-orange;
        margin-right: $default-margin * 0.5;
    }
}
