@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.login-language-selector {
    text-align: right;
    position: relative;
    font-size: $small-font-size;
    top: 12px;
    z-index: 10;

    @media #{$breakpoint-small} {
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;
    }

    @media #{$breakpoint-xsmall} {
        right: $default-padding * 0.5;
    }

    &-wrapper {
        display: inline-block;
    }

    .dropdown-button {
        position: relative;
        cursor: pointer;
        padding: $default-padding * 0.25 $default-padding * 0.5;
        display: inline-block;
        border: 1px solid $color-white;
        color: $color-white;
    }

    .language-dropdown {
        .dropdown-content {
            display: none;
        }

        &.open {
            .dropdown-content {
                display: block;
            }
        }
    }

    .dropdown-content {
        position: absolute;
        top: 100%;
        margin-top: 1px;
        left: -1px;
        right: -1px;
        text-align: center;
        font-size: $default-font-size;
        background: $color-white;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
        li:first-child a {
            border-top: none;
        }
        a {
            border-top: $default-border;
            display: inline-block;
            width: 100%;
            height: 100%;
            padding: $default-padding * 0.5 $default-padding;
        }
        .dropdown-list {
            margin: 0;
        }
    }

    .language-selector-mobile {
        position: relative;
        &:before {
            right: 7px;
            top: 7px;
            position: absolute;
            color: $color-white;
            @include fa();
            content: '';
        }

        & select {
            text-align: right;
            position: relative;
            font-size: $small-font-size;
            z-index: 10;
            background: transparent;
            color: $color-white;
            border: 1px solid $color-white;
            padding: math.div($default-padding, 3) $default-padding;
            padding-right: $default-padding * 2;
            border-radius: 0;
        }
    }
}

.language-dropdown-login {
    position: absolute;
    top: $default-padding * 1.7;
    right: $default-padding * 1.7;

    .dropdown-content {
        display: none;
        position: absolute;
        z-index: 9999;
        width: 125px;
        border: $default-border;
        box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
        padding: 0 $default-padding * 0.5;
        background: #fff;
        border-radius: $default-radius;
        top: 90%;
        left: -47px;
        margin-top: 10px;
        &:before {
            content: '';
            position: absolute;
            display: block;
            background: #fff;
            border: $default-border;
            border-color: $text-medium;
            height: 12px;
            width: 12px;
            top: -6px;
            left: 50%;
            margin-left: -6px;
            transform: rotate(45deg);
        }
        &:after {
            content: '';
            position: absolute;
            display: block;
            background: #fff;
            height: 10px;
            width: 20px;
            top: 0px;
            left: 50%;
            margin-left: -10px;
        }
        .dropdown-element {
            text-align: left;
            margin: math.div($default-margin, 3);
            .dropdown-img {
                margin-right: $default-margin * 0.5;
            }
        }
    }

    &.open .dropdown-content {
        display: block;
    }

    &.open:after {
        content: '';
        position: absolute;
        display: block;
        background: transparent;
        height: 15px;
        width: 100px;
        top: 14px;
        left: -50px;
        margin-left: 8px;
    }
}
