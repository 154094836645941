@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.new-completed-recently-widget {
    .widget-entry {
        &:not(:hover) {
            color: $gray-darker;
        }

        &-title {
            display: flex;
            margin: 0;
        }
    }
}
