.process-footer {
    @extend %match-parent;
    @extend %widget-container;
    margin-top: $default-margin * 2;
    background: $color-white;
    padding: $default-padding;
    // Every Item put inside the Process Footer will have an horizontal Margin
    // Excepting the first and last item
    // * selector is usually bad practice, but within this context it will
    // not iterate over many items, excepting the ones that are explicitly put
    // within the footer.
    > * {
        margin: 0 $default-margin * 0.5; // horizontal
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
