.openid-login {
    li {
        width: 50%;
        display: inline-block;

        &:first-child {
            padding-right: math.div($default-padding, 3);
        }

        &:last-child {
            padding-left: math.div($default-padding, 3);
        }

        button {
            color: $text-medium;
            background: $gray-light;
            border: $default-border;
            width: 100%;

            &:hover {
                border: $default-border-width solid $color-blue-highlight;
            }
        }

        @media #{$breakpoint-small} {
            &:first-child,
            &:last-child {
                padding: 0;
            }

            &:first-child {
                padding-bottom: $default-padding * 1;
            }

            width: 100%;
            // margin-top: $default-margin;
        }
    }
}
