@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.kyc-recipient-list {
    @apply mt-2;
}

.kyc-recipient-list-container {
    @apply m-0
            md:grid
            md:gap-4
            md:grid-cols-2
            lg:grid-cols-3;

    li {
        @apply md:block
                md:mb-0;

        .penneo-card {
            &,
            &-container {
                @apply h-full;
            }
        }
    }
}

.kyc-recipient-list-item {
    @apply block;
    line-break: anywhere;

    & + & {
        @apply mt-4
                md:mt-0;
    }

    &-name {
        @apply m-0;
    }
}
