@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.top-actions {
    display: flex;
    justify-content: space-between;
    height: 50px;
    padding: $default-padding;
    padding-bottom: 0;
}

.top-actions .items-selected-label {
    @include OpenSans-SemiBold();
    color: $cf-blue;
    padding-right: $default-padding;
}

.top-actions-search-icon {
    position: absolute;
    top: 35%;
    font-size: 90%;
    color: $text-medium;
    left: math.div($default-padding, 1.2);
    z-index: 1;
}

.archive-search-bar-container {
    .archive-search-bar {
        display: inline-block;
        position: relative;
    }

    .archive-search-bar-label {
        font-weight: 700;
        padding-left: $default-padding;
    }

    input.archive-search-bar-input {
        @include transition(0.2s);
        padding-left: $default-padding * 2.3;
        width: 160px;
    }

    .archive-search-bar-icon {
        @include center-vertical();
        position: absolute;
        left: math.div($default-padding, 1.2);
        z-index: 2;
    }

    .archive-search-bar-clear-button {
        cursor: pointer;
        @include center-vertical();
        @include OpenSans-SemiBold();

        position: absolute;
        right: math.div($default-padding, 1.2);
        z-index: 2;
        color: $penneo-blue;
        &-label {
            position: relative;
            top: -1px;
        }
    }

    &.active {
        input.archive-search-bar-input {
            width: 240px;
            padding-right: $default-padding * 4;
            background: transparentize($cf-blue, 0.95);
            border-color: $cf-blue;
        }

        .archive-search-bar-icon {
            color: $cf-blue;
        }
    }
}
