.penneo-dashboard-navigation {
    * {
        -webkit-font-smoothing: antialiased;
    }

    background: #142743;

    .send-button {
        padding: $default-padding * 1.2 $default-padding;
        padding-bottom: $default-padding * 0.25;
    }
}

.penneo-navigation-group {
    padding: $default-padding * 0.5 $default-padding;
    position: relative;

    .title {
        @include transition(0.3s);
        text-transform: uppercase;
        font-weight: bold;
        color: $color-white;
        font-size: 0.95em;
        margin: 0;
        opacity: 0.5;

        &:hover {
            opacity: 0.7;
        }
    }

    &.active {
        .title {
            opacity: 1;
        }
    }

    &-highlight {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 3px;
        height: 100%;
        background: $color-success;
    }

    &-items {
        margin-top: $default-margin;
        font-weight: normal;
        text-transform: capitalize;

        a {
            @include transition(0.3s);
            display: inline-block;
            width: 100%;
            margin: $default-margin * 0.125 0;
            padding: math.div($default-padding, 2.5);
            color: $color-white;
            i {
                position: relative;
                margin-right: $default-margin * 0.5;

                &.small-margin {
                    margin-right: $default-margin * 0.25;
                }

                &.new-icon:after {
                    @extend .fa;
                    content: '\f067';
                    position: absolute;
                    font-size: 10.5px;
                    left: 9px;
                    top: -7px;
                }
            }

            &.active {
                background: #304056;
                border-radius: $default-radius;
            }

            &:hover {
                background: #1c2e4a;
                color: $color-white;

                &.active {
                    background: #304056;
                }
            }

            &.external {
                .fa-external-link {
                    @include transition(0.3s);
                    opacity: 0.3;
                }

                &:hover {
                    .fa-external-link {
                        opacity: 0.8;
                    }
                }
            }
        }

        .menu-beta-tag {
            font-size: $small-font-size;
            background: #f7db6f;
            border-radius: $default-radius;
            color: $text-dark;
            font-weight: bold;
            text-transform: none;
            padding: math.div($default-padding, 6) $default-padding * 0.5;
        }
    }
}

.penneo-navigation-footer {
    padding: $default-margin;
    position: absolute;
    bottom: $default-padding;
    width: 100%;
    text-align: center;
}
