@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.casefile-status-badge {
    @include OpenSans-SemiBold();
    margin-top: $default-margin * 0.5;
    border-radius: $default-radius;
    display: inline-block;
    font-size: 85%;
    padding: $default-padding * 0.125 $default-padding * 0.5;
    text-align: center;
    border: 1px solid transparentize($gray-dark, 0.6);
    background: transparentize($gray-dark, 0.6);
    color: darken($gray-dark, 30);
    max-width: 100%;

    &-name {
        @include OpenSans-Bold();
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
    }

    &-explanation {
        @include OpenSans-SemiBold();
    }

    &.cyan {
        border: 1px solid transparentize(#5abede, 0.6);
        background: transparentize(#5abede, 0.6);
        color: darken(#5abede, 30);
    }

    &.yellow {
        background: $penneo-yellow;
        color: darken($penneo-yellow, 50);
    }

    &.green {
        border: 1px solid transparentize($penneo-green, 0.6);
        color: darken($penneo-green, 20);
        background: transparentize($penneo-green, 0.7);
    }

    &.red {
        background: $color-error;
        color: $color-white;
    }

    .tooltip-text {
        min-width: 180px !important;
    }
}
