@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.signing-done {
    padding: $default-padding * 2 $default-padding;
    max-width: 1170px;
    min-height: calc(100vh - 100px);
    margin: 50px auto 0;
    background: $color-white;
}

.signing-content-done {
    max-width: 550px;
    margin: 0 auto;

    h1 {
        position: relative;
        margin-bottom: $default-padding * 1.5;
        padding: $default-padding * 2 $default-padding $default-padding
            $default-padding * 5;
        &.signed {
            color: $penneo-green;
        }
        &.rejected,
        &.deleted,
        &.expired,
        &.error {
            color: $penneo-red;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
        }

        span {
            display: block;
            font-weight: normal;
        }
    }

    a {
        color: $cf-blue;
        &:hover {
            text-decoration: underline;
        }
    }

    ul {
        margin: $default-padding * 2.5 $default-padding * 2 0;

        li {
            color: $cf-blue;
            list-style: disc;
            list-style-position: inside;
            margin-bottom: $default-padding * 0.5;
        }
    }
}
