.button-spinner {
    display: inline-block;
    position: relative;
    width: 1em;
    min-width: 1em;
    height: 1em;
    border-radius: 100%;
    overflow: hidden;

    border: 2px solid #fff;
    border-left-color: rgba(0, 0, 0, 0);

    animation: spin 1.5s infinite linear;

    &.blue {
        border-color: 2px solid rgba(#f3f4f6, 0.2);
        border-left-color: 2px solid #f3f4f6;
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
