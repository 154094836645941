@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.dashboard-widget {
    color: $gray-darker;
    padding: $default-padding * 0.5;
    * {
        -webkit-font-smoothing: antialiased;
    }

    ul {
        margin-top: 0;
    }

    &-container {
        position: relative;
        border-radius: $default-radius * 2.75;
        background: $color-white;
        height: 100%;
    }

    &-content {
        position: relative;
        width: 100%;
        padding: $default-padding * 1.75;
        &:not(:first-child) {
            padding-top: 0;
            &::before {
                display: block;
                content: '';
                padding-bottom: $default-padding * 2;
                border-top: 1px solid $gray-border;
                width: 100%;
            }
        }

        /* streamline links' hover */
        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &-title {
        @include OpenSans-Bold();
        padding-bottom: $default-padding * 1.5;
        color: $text-dark;
        font-size: $subtitle-font-size;
        margin: 0;
    }

    &.is-disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.dashboard-widget-content {
    .widget-empty {
        text-align: center;
        width: 100%;
        padding: $default-padding * 2;
        p:first-of-type {
            @include OpenSans-Regular;
            font-size: 12pt;
            padding: 0 $default-padding;
            margin: 0;
            padding-bottom: 10px;

            i {
                padding: 0;
                margin: 0;
                padding-bottom: 20px;
                font-size: 36pt;
                opacity: 0.3;
                display: block;
            }
        }

        .bold-link {
            line-height: 1.8;
        }
    }

    .widget-entry {
        font-size: $small-font-size;
        display: flex;
        justify-content: space-between;
        padding: $default-padding 0;

        &:first-child {
            padding-top: 0;
        }

        &-title,
        &-metadata {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-details,
        &-action {
            margin-left: 2em;
        }
    }

    .bold-link {
        font-size: $small-font-size;
        font-weight: 600;
    }

    .dashboard-widget-link {
        &:not(:empty) {
            display: flex;
            justify-content: flex-end;
            margin-top: $default-padding * 2;
        }

        > a {
            font-weight: 600;
        }
    }
}
