.credential-management-sidebar {
    ul {
        padding: 0;
        margin: 0;
    }
    // Main Level Items
    ul li a {
        font-size: 1em;
        color: #333;
        margin: 0;
        padding: 0;
        text-decoration: none;
    }

    ul li {
        margin-top: $default-margin;
        margin-bottom: $default-margin;
    }

    ul li:first-child {
        margin-top: 0;
    }

    ul ul h3 {
        font-size: 0.8em;
        margin-top: 1.5em;
        padding: 0;
        text-transform: uppercase;
        opacity: 0.3;
        text-decoration: none;
    }
}
