.casefile-success {
    margin-top: $default-margin * 2;
    text-align: center;

    .success-heading {
        color: $color-success;
        border-bottom: $default-border;
        padding-bottom: $default-padding * 0.5;
    }

    .fa-check-circle {
        font-size: 6em;
        color: $color-success;
    }

    .success-text {
        @include white-container();
        padding: $default-padding * 1.5 $default-padding * 3;
        padding-top: 0;
        display: inline-block;
        text-align: center;
        color: $text-medium;
    }

    .footer-links {
        a {
            text-decoration: none;
            margin: 0 $default-margin * 0.5;
        }
    }
}
