@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.redirect-page {
    padding: $default-padding * 2 $default-padding;
    height: 100%;
    text-align: center;

    .bar {
        position: absolute;
        left: -1px;
        right: -1px;
        top: -1px;
        height: 6px;
        border-radius: $default-radius $default-radius 0 0;
        overflow: hidden;
        &.success {
            background: $color-success;
        }
        &.info {
            background: $color-info;
        }
        &.warning {
            background: $color-warning;
        }
        &.error {
            background: $color-error;
        }
    }
}

.redirect-container {
    position: relative;
    box-sizing: border-box;
    background: #fafafa;
    border-radius: $default-radius;
    border: $default-border;
    padding: $default-padding * 2;
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
    text-align: center;
    .title {
        color: $text-dark;
        padding: 0;
        margin: 0 auto;
        position: relative;
    }
    .subtitle {
        color: $text-dark;
        padding: $default-padding 0;
        margin: 0 auto;
    }

    .footer {
        margin-top: $default-margin;
    }
}

.document-stack-icon {
    position: relative;
    margin: $default-margin auto;
    height: 90px;
    width: 70px;
    .document-image {
        position: absolute;
        left: 33%;
        top: 20%;
        opacity: 0.2;
        font-size: 2em;
        z-index: 100;
    }
    .lines {
        position: absolute;
        bottom: 20px;
        height: 3px;
        width: 70%;
        left: 50%;
        opacity: 0.2;
        margin-left: -35%;
        background: #000;
        z-index: 10;
        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 8px;
            height: 3px;
            width: 100%;
            left: 0;
            background: #000;
            z-index: 10;
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 8px;
            height: 3px;
            width: 100%;
            left: 0;
            background: #000;
            z-index: 10;
        }
    }
    .page {
        position: absolute;
        height: 100%;
        width: 100%;
        background: $color-white;
        border: 1px solid $gray-medium;
        z-index: 3;
        &.page-2 {
            z-index: 2;
            transform: rotate(-7deg);
        }
        &.page-3 {
            z-index: 1;
            top: -3px;
            transform: rotate(7deg);
        }
    }
}
