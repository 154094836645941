.csv-download-button {
    display: inline-block;
}

// @todo: move to inputs.scss in application-loader
.button-dropdown {
    display: inline-block;
    position: relative;
    > button.active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background: darken($color-blue-highlight, 10);
        color: $color-white;
        z-index: 10;
        position: relative;
        &:after {
            content: '';
            background: #fff;
            position: absolute;
            bottom: -5px;
            right: $default-padding * 1.15;
            transform: rotate(45deg);
            height: 8px;
            width: 8px;
        }
    }
    &-content {
        z-index: 9;
        position: absolute;
        top: 100%;
        left: 0;
        background: #fff;
        padding: $default-padding;
        box-shadow: 0 2px 6px 3px rgba(0, 0, 0, 0.2);

        &.right {
            left: auto;
            right: 0;
        }
    }
}

.csv-delimiter-picker {
    @extend .prevent-selection;
    width: 180px;
    .delimiter-list {
        position: relative;
        width: 100%;
        background: transparentize($gray-light, 0.5);
        border: $default-border;
        display: table;
        border-radius: $default-radius;
        li {
            cursor: pointer;
            display: table-cell;
            text-align: center;
            padding: math.div($default-padding, 1.5) $default-padding;
            position: relative;
            border-right: $default-border;

            &:last-child {
                border-right: none;
            }

            &.active > span {
                display: relative;
                &:after {
                    content: '';
                    display: block;
                    background: $color-blue-highlight;
                    position: absolute;
                    bottom: 0;
                    left: -1px;
                    right: -1px;
                    height: 2px;
                }
            }
        }
    }
}

.csv-info-link {
    text-align: center;
    padding-top: $default-padding * 0.5;
    font-size: $small-font-size;
}

.csv-info-modal {
    text-align: left;

    p {
        margin-top: 0;
    }
}
