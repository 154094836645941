@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.move {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $color-white;
    z-index: 9999;

    .container {
        height: 100%;
        position: relative;
        max-width: 600px;
        padding-top: 150px;
        margin: auto;
    }

    .move-header {
        .title-holder {
            overflow: hidden;

            .title {
                width: 75%;
                float: left;
                font-size: $title-font-size * 1.5;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            button {
                width: 25%;
                float: right;
            }
        }

        input {
            width: 100%;
        }
    }

    .move-tree-holder {
        width: 100%;
        margin-top: $default-margin;
        position: absolute;
        top: 250px;
        bottom: 0;
        overflow: auto;

        button:hover {
            background-color: transparent;
        }
    }

    .close {
        width: 80px;
        height: 80px;
        position: absolute;
        top: $default-margin * 2;
        right: $default-margin * 2;
        border-radius: 50%;
        background-color: $gray-light;

        i {
            font-size: 30px;
            margin: 0;
        }
    }
}
